import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
import { EmployeeFragmentDoc } from '../../../employees/gql/fragments/employee.graphql';
import { UserFragmentDoc } from '../../../users/gql/fragments/user.graphql';
export type CompanyDetailedFragment = { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean, licensedBy: any, tzIdentifier: string, farms: { __typename?: 'CompanyFarmConnection', nodes: Array<{ __typename: 'Farm', id: string, name: string, number: string }> }, employees: { __typename?: 'EmployeeTypeConnection', nodes: Array<{ __typename: 'Employee', id: string, comment: string, number: string, firstName: string, middleName: string, lastName: string, company: { __typename: 'Company', id: string } }> }, users: { __typename?: 'CompanyUserConnection', edges: Array<{ __typename?: 'CompanyUserEdge', node: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, farmsInCompany: Array<{ __typename: 'Farm', id: string, name: string, number: string }> }> } };

export const CompanyDetailedFragmentDoc = gql`
    fragment CompanyDetailed on Company {
  __typename
  id
  name
  isLockedForWrite
  licensedBy
  tzIdentifier
  farms {
    nodes {
      ...Farm
    }
  }
  employees {
    nodes {
      ...Employee
    }
  }
  users {
    edges {
      node {
        ...User
      }
      farmsInCompany {
        ...Farm
      }
    }
  }
}
    ${FarmFragmentDoc}
${EmployeeFragmentDoc}
${UserFragmentDoc}`;
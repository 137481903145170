import React from 'react';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Input } from '~/shared/components/Input';

import { EditUserEventFormType } from '../../hooks/useUserEventForm';

interface Props
  extends Pick<UseModalStepFormInterface<EditUserEventFormType>, 'formProps'> {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditUserEventForm: React.FC<Props> = ({
  className,
  formProps,
}) => {
  return (
    <Form
      {...{
        className,
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'name',
          label: 'Название события',
          placeholder: 'Например, Травма',
          isRequired: true,
        }}
      />
    </Form>
  );
};

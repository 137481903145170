import React from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { Callout } from '~/services/notifications';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { formatBull, useBullSelect } from '~/entities/bulls';
import { BullFragment } from '~/entities/bulls/gql/fragments/bull.graphql';
import {
  BullsDetailedDocument,
  BullsDetailedQueryVariables,
} from '~/entities/bulls/gql/queries/bullsDetailed.graphql';
import { useBullsForDeletionQuery } from '~/entities/bulls/gql/queries/bullsForDeletion.graphql';

import { useDeleteBullMutation } from '../../gql/mutations/deleteBull.graphql';

export interface DeleteBullModalProps
  extends InjectedModalProps<DeleteBullModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Bull to delete
   */
  bull: BullFragment;
  /**
   * Variables, used in current bulls query for cache updating after deletion
   */
  queryVariables: BullsDetailedQueryVariables;
}

const FORM_ID = 'DeleteBullForm';

export const DeleteBullModal: React.FC<DeleteBullModalProps> = ({
  className,
  bull,
  queryVariables,

  close,
}) => {
  const [deleteBull] = useDeleteBullMutation();

  const { data, loading: isLoading } = useBullsForDeletionQuery({
    variables: {
      ids: [bull.id],
    },
  });
  const shouldBeMigratedOnDelete =
    data?.bulls.edges.at(0)?.node.shouldBeMigratedOnDelete;

  const schema = yup.object({
    migrateToBullID: yup.string().when({
      is: () => shouldBeMigratedOnDelete,
      then: stringSchema => stringSchema.required(),
      otherwise: stringSchema => stringSchema.optional(),
    }),
  });

  type DeleteBullFormType = InferValidatedSchema<typeof schema>;

  const formContext = useForm<DeleteBullFormType>({
    schema,
    defaultValues: schema.getDefault(),
  });

  const handleSubmit = (form: DeleteBullFormType) => {
    deleteBull({
      variables: {
        id: bull.id,
        ...form,
      },
      optimisticResponse: { deleteBull: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery({
          typeName: 'Bull',
          query: BullsDetailedDocument,
          variables: queryVariables,
          queryName: 'bulls',
        })(bull.id),
        makeDeleteQueriesByNameWithoutVariables('bulls', queryVariables),
      ]),
      refetchQueries: ['bulls', 'bullsDetailed'],
    });
    close();
  };

  const { renderSelectElement: renderBullsSelectElement } = useBullSelect({
    queryOptions: {
      variables: {
        excludeIDs: [bull.id],
      },
    },
    selectProps: {
      className: 'mt-24',
      name: 'migrateToBullID',
      label: 'Номер быка на замену',
    },
  });

  return (
    <Modal
      {...{
        className,
        title: 'Удаление быка',
        isLoading,
        submitButtonProps: {
          form: FORM_ID,
          children: 'Подтвердить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {shouldBeMigratedOnDelete && (
          <Callout className="mb-24" title="Необходима замена быка" />
        )}
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить быка{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatBull(bull, { prefix: '' })}
            </Typography>
            ?
          </Typography>

          {shouldBeMigratedOnDelete && (
            <Typography tag="p" variant={TypographyVariants.bodySmall}>
              Этот бык имеет добавленные осеменения. Чтобы его удалить, выберите
              быка на замену.
            </Typography>
          )}
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>

        {shouldBeMigratedOnDelete && renderBullsSelectElement()}
      </Form>
    </Modal>
  );
};

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { MappingType } from '../../types';

type MappingItemConfig = { color: ColorVariants; title: string };

/**
 * Badge config based on current MappingType for MappingItem card
 */
export const BADGE_MAPPING_TYPE_CONFIGS: Record<
  MappingType,
  {
    withValue: MappingItemConfig;
    withoutValue?: MappingItemConfig;
  }
> = {
  [MappingType.equally]: {
    withValue: {
      title: 'Выбрано соответствие',
      color: ColorVariants.info,
    },
    withoutValue: {
      title: 'Выберите соответствие',
      color: ColorVariants.warning,
    },
  },
  [MappingType.create]: {
    withValue: {
      title: 'Будет создано',
      color: ColorVariants.success,
    },
  },
  [MappingType.ignore]: {
    withValue: {
      title: 'Не будет добавлено',
      color: ColorVariants.neutral,
    },
  },
};

import React from 'react';

import clsx from 'clsx';

import { Skeleton, useSkeletonContext } from '~/shared/components/Skeleton';

import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

/**
 * Available dot sizes
 */
export enum ColoredDotSizes {
  small10 = 'small10',
  medium12 = 'medium12',
  large14 = 'large14',
}

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Color of the dot
   */
  color: string;
  /**
   * Border color of the dot
   */
  borderColor?: string;
  /**
   * Size of the dot to render
   */
  size?: ColoredDotSizes;
}

const SKELETON_DOT_SIZE_PX = 12;

export const ColoredDot: React.FC<Props> = ({
  className,
  color,
  borderColor,
  size = ColoredDotSizes.large14,
}) => {
  const { renderWithSkeleton } = useSkeletonContext();

  return renderWithSkeleton(
    <Skeleton.Block
      {...{
        className,
        size: SKELETON_DOT_SIZE_PX,
        isRound: true,
      }}
    />,
    <div
      className={clsx(styles.root, styles[size], className)}
      style={{
        background: color,
        border: borderColor
          ? `${TOKENS.borderWidth1} solid ${borderColor}`
          : undefined,
      }}
    />
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportFragmentDoc } from '../fragments/customReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomReportsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type CustomReportsQuery = { __typename?: 'Query', customReports: { __typename?: 'CustomReportConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CustomReportEdge', cursor: string, node: { __typename: 'CustomReport', id: string, name: string, blueprint: { __typename: 'Blueprint', id: string, name: string, description: string, inputs: Array<{ __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> }> } } }> } };


export const CustomReportsDocument = gql`
    query customReports($first: Int, $after: String, $ids: [ID!]) {
  customReports(first: $first, after: $after, ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CustomReport
      }
    }
  }
}
    ${CustomReportFragmentDoc}`;

/**
 * __useCustomReportsQuery__
 *
 * To run a query within a React component, call `useCustomReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCustomReportsQuery(baseOptions?: Apollo.QueryHookOptions<CustomReportsQuery, CustomReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomReportsQuery, CustomReportsQueryVariables>(CustomReportsDocument, options);
      }
export function useCustomReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomReportsQuery, CustomReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomReportsQuery, CustomReportsQueryVariables>(CustomReportsDocument, options);
        }
export function useCustomReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomReportsQuery, CustomReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomReportsQuery, CustomReportsQueryVariables>(CustomReportsDocument, options);
        }
export type CustomReportsQueryHookResult = ReturnType<typeof useCustomReportsQuery>;
export type CustomReportsLazyQueryHookResult = ReturnType<typeof useCustomReportsLazyQuery>;
export type CustomReportsSuspenseQueryHookResult = ReturnType<typeof useCustomReportsSuspenseQuery>;
export type CustomReportsQueryResult = Apollo.QueryResult<CustomReportsQuery, CustomReportsQueryVariables>;
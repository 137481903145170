import { Chart, ChartOptions, ScaleOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import TOKENS from '~/styles/__generated__/tokens.json';

import { getChartOptions, getScaleOptions } from './helpers';

Chart.register(ChartDataLabels);

export const BARS_BORDER_RADIUS_PX = NUMBER_TOKENS.borderRadius4;

// Colors for datasets, that are assigned in rotation
export const CHART_DATASET_COLORS = [
  TOKENS.colorStatus05Soft,
  TOKENS.colorInfoSoft,
  TOKENS.colorSuccessSoft,
  TOKENS.colorAccentSoft,
  TOKENS.colorStatus04Soft,
  TOKENS.colorStatus01Soft,
  TOKENS.colorStatus06Soft,
  TOKENS.colorErrorSoft,
  TOKENS.colorStatus02Soft,
  TOKENS.colorStatus03Soft,

  TOKENS.colorStatus05Muted,
  TOKENS.colorInfoMuted,
  TOKENS.colorSuccessMuted,
  TOKENS.colorAccentMuted,
  TOKENS.colorStatus04Muted,
  TOKENS.colorStatus01Muted,
  TOKENS.colorStatus06Muted,
  TOKENS.colorErrorMuted,
  TOKENS.colorStatus02Muted,
  TOKENS.colorStatus03Muted,

  TOKENS.colorStatus05Default,
  TOKENS.colorInfoDefault,
  TOKENS.colorSuccessDefault,
  TOKENS.colorAccentDefault,
  TOKENS.colorStatus04Default,
  TOKENS.colorStatus01Default,
  TOKENS.colorStatus06Default,
  TOKENS.colorErrorDefault,
  TOKENS.colorStatus02Default,
  TOKENS.colorStatus03Default,
];

/**
 * Colors for missing data in datasets, that are assigned in rotation
 */
export const CHART_DATASET_SKIPPED_COLORS = [
  TOKENS.colorStatus05_100,
  TOKENS.colorInfo100,
  TOKENS.colorSuccess100,
  TOKENS.colorAccent100,
  TOKENS.colorStatus04_100,
  TOKENS.colorStatus01_100,
  TOKENS.colorStatus06_100,
  TOKENS.colorError100,
  TOKENS.colorStatus02_100,
  TOKENS.colorStatus03_100,
];

// We have 3 colors group: soft, muted and default. Default color is used for hover in all groups
// TODO think of some clever way to manage different color modes, like soft, muted etc.
const CHART_UNIQ_COLORS_COUNT = 10;
export const CHART_DATASET_HOVER_COLORS = CHART_DATASET_COLORS.slice(
  -CHART_UNIQ_COLORS_COUNT
);

/**
 * Default options to enable zoom on a chart
 */
export const DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS = {
  wheel: {
    enabled: true,
    speed: 0.01,
  },
  pinch: {
    enabled: true,
  },
  mode: 'xy',
} as const;

/**
 * Default options to enable pan on a chart
 */
export const DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS = {
  enabled: true,
  mode: 'xy',
} as const;

/**
 * Options, applied to each chart
 */
export const GLOBAL_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest',
  },
  layout: {
    autoPadding: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      position: 'cursor',
    },
    datalabels: {
      display: false,
    },
  },
} satisfies ChartOptions;

/**
 * Options, applied to each scale
 */
export const GLOBAL_SCALE_OPTIONS = {
  ticks: {
    color: TOKENS.colorFgMuted,
    count: 5,
    precision: 0,
  },
  grid: {
    display: false,
    color: TOKENS.colorBorderMuted,
    tickColor: 'transparent',
  },
  border: {
    display: false,
    color: TOKENS.colorBorderMuted,
  },
  title: {
    color: TOKENS.colorFgMuted,
  },
} satisfies ScaleOptions;

/**
 * Default options for a scale on a bar chart
 */
export const LINEAR_Y_SCALE_OPTIONS = {
  stacked: false,
  grid: {
    display: true,
  },
  ticks: {
    callback: value => formatInt(value),
  },
} satisfies ScaleOptions;

/**
 * Default options for a scale on a percent bar chart
 */
export const PERCENT_LINEAR_Y_SCALE_OPTIONS = getScaleOptions(
  LINEAR_Y_SCALE_OPTIONS,
  {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 20,
      callback: value => formatWithPercent(value),
    },
  }
) satisfies ScaleOptions;

/**
 * Options for a chart with bars
 */
export const BARS_CHART_OPTIONS = {
  scales: {
    x: getScaleOptions(),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with bars and percentage values
 */
export const PERCENT_BARS_CHART_OPTIONS = getChartOptions(BARS_CHART_OPTIONS, {
  plugins: {
    tooltip: {
      callbacks: {
        label: context => formatWithPercent(context.parsed.y),
      },
    },
  },
  scales: {
    y: PERCENT_LINEAR_Y_SCALE_OPTIONS,
  },
} satisfies ChartOptions);

/**
 * Options for a chart with dots
 */
export const SCATTER_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  scales: {
    x: getScaleOptions({
      grid: {
        display: true,
      },
    }),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with lines
 */
export const LINE_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  scales: {
    x: getScaleOptions({
      grid: {
        display: true,
      },
    }),
    y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS),
  },
} satisfies ChartOptions;

/**
 * Options for a chart with pie
 */
export const PIE_CHART_OPTIONS = {
  interaction: {
    mode: 'point',
  },
  layout: {
    padding: NUMBER_TOKENS.spacing40,
  },
  plugins: {
    datalabels: {
      display: context => {
        const value = context.dataset.data[context.dataIndex] ?? 0;
        return Number.isNaN(value) ? false : 'auto';
      },
      color: TOKENS.colorFgSoft,
      align: 'end',
      anchor: 'end',
      offset: NUMBER_TOKENS.spacing12,
    },
  },
} satisfies ChartOptions;

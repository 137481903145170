import React from 'react';

import { ErrorPage } from '~/apps/chicherin/pages/ErrorPage';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

interface Props {
  /**
   * Moscow time when the system will start operating 'HH:mm'
   */
  startTime?: string;
}

export const TechnicalMaintenancePage: React.FC<Props> = ({ startTime }) => {
  return (
    <ErrorPage
      {...{
        title: 'Система недоступна',
        errorCode: 503,
        description: (
          <>
            <Typography variant={TypographyVariants.bodyMedium}>
              Мы проводим технические работы — обновляем систему, чтобы она
              работала быстрее и лучше. Извините за неудобства.
            </Typography>
            {!!startTime && (
              <div>
                <Typography tag="div" variant={TypographyVariants.bodyMedium}>
                  {`Система начнёт работать примерно в ${startTime} по МСК.`}
                </Typography>
                <Typography tag="div" variant={TypographyVariants.bodyMedium}>
                  Страница перезагрузится автоматически.
                </Typography>
              </div>
            )}
          </>
        ),
      }}
    />
  );
};

import React from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { COW_EVENT_CATEGORY_CONFIGS } from '~/entities/cowEvents';

import {
  COW_CHART_LINE_DATA_SOURCE_NAMES,
  COW_CHART_LINE_VALUE_TYPE_CONFIGS,
} from '../../constants';
import { CowChartLineDataSources } from '../../types';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const CowChartLegend: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Typography variant={TypographyVariants.heading5}>
        Кривые на графике:
      </Typography>
      <div className="flex gap-16 mt-4 mb-8">
        <div className={styles.cowChartLinesColumn}>
          {Object.values(COW_CHART_LINE_VALUE_TYPE_CONFIGS).map(config => (
            <Typography
              {...{
                key: config.name,
                variant: TypographyVariants.descriptionLarge,
                style: {
                  color: config.color,
                },
              }}
            >
              {config.name}
            </Typography>
          ))}
        </div>
        <div className={styles.cowChartLinesColumn}>
          {Object.entries(COW_CHART_LINE_DATA_SOURCE_NAMES).map(
            ([dataSourceType, dataSourceName]) => (
              <div
                {...{
                  key: dataSourceType,
                  className: styles.itemWithIcon,
                }}
              >
                <div
                  className={styles[dataSourceType as CowChartLineDataSources]}
                />
                <Typography variant={TypographyVariants.descriptionLarge}>
                  {dataSourceName}
                </Typography>
              </div>
            )
          )}
        </div>
      </div>

      <Typography variant={TypographyVariants.heading5}>
        События в жизни животного:
      </Typography>
      <div className={styles.cowEvents}>
        {Object.values(COW_EVENT_CATEGORY_CONFIGS).map(config => (
          <div
            {...{
              key: config.name,
              className: styles.itemWithIcon,
            }}
          >
            <div
              {...{
                className: styles.circle,
                style: {
                  backgroundColor: config.color,
                },
              }}
            />
            <Typography variant={TypographyVariants.descriptionLarge}>
              {config.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useMemo, useRef, useState } from 'react';

import { FloatingPortal } from '@floating-ui/react';
import {
  animated,
  config,
  Controller,
  TransitionFn,
  useTransition,
  UseTransitionProps,
} from '@react-spring/web';
import R from 'ramda';
import { tap } from 'rxjs';
import { match } from 'ts-pattern';

import { useIsPrefersReducedMotion } from '~/shared/hooks/useIsPrefersReducedMotion';
import { useObservable } from '~/shared/hooks/useObservable';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

import { useNotificationsContext } from '../../context';
import {
  NotificationConfig,
  NotificationKinds,
  NotificationProps,
} from '../../types';
import { Alert } from '../Alert';
import { Toast } from '../Toast';
import styles from './index.module.scss';

const TOAST_TIMEOUT_MS = 3000;
const ALERT_TIMEOUT_MS = 6000;

export const NotificationsContainer: React.FC = () => {
  const isPrefersReducedMotion = useIsPrefersReducedMotion();

  const { notificationsSubject$ } = useNotificationsContext();

  const alertsContainerRef = useRef<HTMLDivElement>(null);
  const toastsContainerRef = useRef<HTMLDivElement>(null);

  const heightsMap = useMemo(() => new Map<string, number>(), []);
  const cancelMap = useMemo(() => new Map<string, any>(), []);
  const controllerMap = useMemo(() => new Map<string, Controller>(), []);

  const [notifications, setNotifications] = useState<NotificationConfig[]>([]);
  const alertNotifications = notifications.filter(
    n => n.kind === NotificationKinds.alert
  );

  const toastNotifications = notifications.filter(
    n => n.kind === NotificationKinds.toast
  );

  const [shouldRenderAlerts, setShouldRenderAlerts] = useState(false);
  const [shouldRenderToasts, setShouldRenderToasts] = useState(false);

  const addNotification = (notification: NotificationConfig) => {
    if (notification.kind === NotificationKinds.alert) {
      setShouldRenderAlerts(true);
    }
    if (notification.kind === NotificationKinds.toast) {
      setShouldRenderToasts(true);
    }

    setNotifications(prev => [...prev, notification]);
  };

  const removeNotification = (id: string) => {
    setNotifications(prev =>
      prev.filter(notification => notification.id !== id)
    );
    controllerMap.delete(id);
  };

  useObservable(notificationsSubject$.pipe(tap(addNotification)), undefined);

  const makeTransitionsConfig = (
    animationAxes: 'translateX' | 'translateY',
    timeout: number,
    containerRef: React.RefObject<HTMLDivElement>
  ): UseTransitionProps<NotificationConfig> => ({
    keys: notification => notification.id,
    from: {
      opacity: 0,
      [animationAxes]: '100%',
      life: '100%',
      paddingTop: NUMBER_TOKENS.spacing16,
    },
    enter: notification => async (animate, cancel) => {
      cancelMap.set(notification.id, cancel);

      await animate({ height: heightsMap.get(notification.id) });

      if (
        containerRef.current &&
        containerRef.current.scrollHeight > window.innerHeight
      ) {
        containerRef.current.scroll({
          top: containerRef.current?.scrollHeight,
          behavior: 'smooth',
        });
      }

      await animate({ opacity: 1, [animationAxes]: '0%' });
      await animate({ life: '0%' });
    },
    onStart: (result, controller, notification) => {
      // For some reason notification can be undefined, when refreshing page
      if (notification) {
        controllerMap.set(notification.id, controller);
      }
    },
    leave: () => async animate => {
      await animate({
        opacity: 0,
        height: 0,
        paddingTop: 0,
      });
    },
    onDestroyed: () => {
      if (!alertNotifications.length) {
        setShouldRenderAlerts(false);
      }
      if (!toastNotifications.length) {
        setShouldRenderToasts(false);
      }
    },
    onRest: (result, controller, notification) => {
      removeNotification(notification.id);
    },
    config: (notification, index, phase) => key => {
      // Use the custom config instead of the `immediate` prop,
      // because the value of the `life` animation is used as the duration of notification visibility.
      if (isPrefersReducedMotion && key !== 'life') {
        return { duration: 0 };
      }
      if (key === 'height' && phase === 'enter') {
        return { duration: 0 };
      }

      return phase === 'enter' && key === 'life'
        ? {
            duration: timeout,
          }
        : config.stiff;
    },
  });

  const alertTransitions = useTransition(
    alertNotifications,
    makeTransitionsConfig('translateX', ALERT_TIMEOUT_MS, alertsContainerRef)
  );
  const toastTransitions = useTransition(
    notifications.filter(n => n.kind === NotificationKinds.toast),
    makeTransitionsConfig('translateY', TOAST_TIMEOUT_MS, toastsContainerRef)
  );

  const renderTransitions = (transitions: TransitionFn<NotificationConfig>) =>
    transitions(({ life, ...notificationStyle }, notification) => {
      const props: NotificationProps = {
        ...notification.props,
        className: 'pointer-events-all',
        onClose: () => {
          if (cancelMap.has(notification.id) && life.get() !== '0%') {
            controllerMap.get(notification.id)?.resume();
            cancelMap.get(notification.id)();
          }
        },
      };
      return (
        <animated.div
          {...{
            ref: node => {
              if (node) {
                heightsMap.set(notification.id, node.offsetHeight);
              }
            },
            key: notification.id,
            style: notificationStyle,
            onMouseEnter: () => {
              controllerMap.get(notification.id)?.pause('life');
            },
            onMouseLeave: () => {
              controllerMap.get(notification.id)?.resume('life');
            },
          }}
        >
          {match(notification.kind)
            .with(NotificationKinds.alert, () => <Alert {...props} />)
            .with(NotificationKinds.toast, () => <Toast {...props} />)
            .otherwise(R.always(null))}
        </animated.div>
      );
    });

  return (
    <FloatingPortal>
      {shouldRenderToasts && (
        <div ref={toastsContainerRef} className={styles.toastsContainer}>
          {renderTransitions(toastTransitions)}
        </div>
      )}
      {shouldRenderAlerts && (
        <div ref={alertsContainerRef} className={styles.alertsContainer}>
          {renderTransitions(alertTransitions)}
        </div>
      )}
    </FloatingPortal>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintShortFragmentDoc } from '../fragments/blueprintShort.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlueprintsShortQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  canBeUsedInMonitor?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  canBeUsedInCustomReport?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type BlueprintsShortQuery = { __typename?: 'Query', blueprints: { __typename?: 'BlueprintConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'BlueprintEdge', cursor: string, node: { __typename: 'Blueprint', id: string, name: string, description: string } }> } };


export const BlueprintsShortDocument = gql`
    query blueprintsShort($after: String, $first: Int, $canBeUsedInMonitor: Boolean, $canBeUsedInCustomReport: Boolean, $search: String, $ids: [ID!]) {
  blueprints(
    after: $after
    first: $first
    canBeUsedInMonitor: $canBeUsedInMonitor
    canBeUsedInCustomReport: $canBeUsedInCustomReport
    search: $search
    ids: $ids
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...BlueprintShort
      }
    }
  }
}
    ${BlueprintShortFragmentDoc}`;

/**
 * __useBlueprintsShortQuery__
 *
 * To run a query within a React component, call `useBlueprintsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintsShortQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      canBeUsedInMonitor: // value for 'canBeUsedInMonitor'
 *      canBeUsedInCustomReport: // value for 'canBeUsedInCustomReport'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBlueprintsShortQuery(baseOptions?: Apollo.QueryHookOptions<BlueprintsShortQuery, BlueprintsShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlueprintsShortQuery, BlueprintsShortQueryVariables>(BlueprintsShortDocument, options);
      }
export function useBlueprintsShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlueprintsShortQuery, BlueprintsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlueprintsShortQuery, BlueprintsShortQueryVariables>(BlueprintsShortDocument, options);
        }
export function useBlueprintsShortSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BlueprintsShortQuery, BlueprintsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlueprintsShortQuery, BlueprintsShortQueryVariables>(BlueprintsShortDocument, options);
        }
export type BlueprintsShortQueryHookResult = ReturnType<typeof useBlueprintsShortQuery>;
export type BlueprintsShortLazyQueryHookResult = ReturnType<typeof useBlueprintsShortLazyQuery>;
export type BlueprintsShortSuspenseQueryHookResult = ReturnType<typeof useBlueprintsShortSuspenseQuery>;
export type BlueprintsShortQueryResult = Apollo.QueryResult<BlueprintsShortQuery, BlueprintsShortQueryVariables>;
import React, { isValidElement, ReactNode } from 'react';

import clsx from 'clsx';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Items to render in card in row (as flex items)
   */
  contentItems: ReactNode[];
  /**
   * contentItemsClassName to pass className for contentItems wrapper
   */
  contentItemsClassName?: string;
  /**
   * Handler to delete current item
   */
  onDelete?: () => void;
  /**
   * Number index
   */
  index: number;
}

export const MoveCowCard: React.FC<Props> = ({
  className,
  index,
  contentItems,
  contentItemsClassName,
  onDelete: handleDelete,
}) => {
  return (
    <div className={clsx(styles.root, panelStyles.panel, className)}>
      <div className={clsx('flex items-center gap-16', contentItemsClassName)}>
        <div>{index}</div>
        {contentItems.map(item =>
          isValidElement(item) ? (
            item
          ) : (
            <Typography variant={TypographyVariants.bodySmall}>
              {item}
            </Typography>
          )
        )}
      </div>
      {handleDelete && (
        <FunctionButton
          {...{
            onPress: handleDelete,
            iconVariant: IconVariants.trash,
            className: styles.hoveredDeleteIcon,
          }}
        />
      )}
    </div>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorIntervalDetailedFragmentDoc } from '../fragments/milkingParlorIntervalDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMilkingParlorIntervalMutationVariables = Types.Exact<{
  input: Types.CreateMilkingParlorIntervalInput;
}>;


export type CreateMilkingParlorIntervalMutation = { __typename?: 'Mutation', createMilkingParlorInterval: { __typename: 'MilkingParlorInterval', id: string, intervalStart: any, intervalEnd: any, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', milkingNumber?: number | null, node: { __typename: 'PenGroup', id: string, name: string, identifier: any } }> } } };


export const CreateMilkingParlorIntervalDocument = gql`
    mutation createMilkingParlorInterval($input: CreateMilkingParlorIntervalInput!) {
  createMilkingParlorInterval(input: $input) {
    ...MilkingParlorIntervalDetailed
  }
}
    ${MilkingParlorIntervalDetailedFragmentDoc}`;
export type CreateMilkingParlorIntervalMutationFn = Apollo.MutationFunction<CreateMilkingParlorIntervalMutation, CreateMilkingParlorIntervalMutationVariables>;

/**
 * __useCreateMilkingParlorIntervalMutation__
 *
 * To run a mutation, you first call `useCreateMilkingParlorIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilkingParlorIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilkingParlorIntervalMutation, { data, loading, error }] = useCreateMilkingParlorIntervalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMilkingParlorIntervalMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilkingParlorIntervalMutation, CreateMilkingParlorIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilkingParlorIntervalMutation, CreateMilkingParlorIntervalMutationVariables>(CreateMilkingParlorIntervalDocument, options);
      }
export type CreateMilkingParlorIntervalMutationHookResult = ReturnType<typeof useCreateMilkingParlorIntervalMutation>;
export type CreateMilkingParlorIntervalMutationResult = Apollo.MutationResult<CreateMilkingParlorIntervalMutation>;
export type CreateMilkingParlorIntervalMutationOptions = Apollo.BaseMutationOptions<CreateMilkingParlorIntervalMutation, CreateMilkingParlorIntervalMutationVariables>;
import { getNumberPartFromGlobalId } from '~/services/gql';

import { HALF_SPACE, MDASH, SPACED_INTERPUNCT } from '~/shared/constants';
import { formatDate } from '~/shared/helpers/date';
import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';

import { formatBull } from '~/entities/bulls';
import { formatCow } from '~/entities/cows';

import { INSEMINATION_RESULTS_DICT } from './constants';
import { InseminationFragment } from './gql/fragments/insemination.graphql';
import { InseminationShortFragment } from './gql/fragments/inseminationShort.graphql';
import { InseminationFormatOptions } from './types';

/**
 * Returns insemination identifier formatted for rendering
 */
export const formatInsemination = (
  insemination?: InseminationShortFragment | null,
  { withMainInfo = true, withBull = true }: InseminationFormatOptions = {}
) => {
  if (!insemination) return MDASH;

  const id = getNumberPartFromGlobalId(insemination.id);
  const cowIdentifier = formatCow(insemination.cow, { withName: false });
  const formattedDate = formatDate(insemination.happenedAt);
  const bullNumber = formatBull(insemination.bull);

  return [
    withMainInfo && `№${HALF_SPACE}${id}`,
    withMainInfo && cowIdentifier,
    withMainInfo && formattedDate,
    withBull && bullNumber,
  ]
    .filter(Boolean)
    .join(SPACED_INTERPUNCT);
};

/**
 * Returns a string with the default insemination info representation
 */
export const getInseminationInfo = (insemination: InseminationFragment) => {
  const bullNumber = formatBull(insemination.bull, {
    prefix: '',
  });
  const employeeName = formatFullNameWithInitials(insemination.employee);
  const schemeName = insemination.scheme?.name;
  const result = INSEMINATION_RESULTS_DICT[insemination.result];

  return [bullNumber, employeeName, schemeName, result]
    .filter(Boolean)
    .join(SPACED_INTERPUNCT);
};

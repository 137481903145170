import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { TokenResponse } from '@graphql-types';
import { BehaviorSubject } from 'rxjs';

import { AppLocalStorageKeys } from '~/shared/constants';
import { useObservable } from '~/shared/hooks/useObservable';

import {
  RefreshAuthTokenDocument,
  RefreshAuthTokenMutation,
  RefreshAuthTokenMutationVariables,
} from '../gql/mutations/refreshAuthToken.graphql';

const isAuthenticatedStore$ = new BehaviorSubject(
  !!localStorage.getItem(AppLocalStorageKeys.accessToken)
);

// Cause we need to provide the auth interface to apollo client,
// we can't use useMutation hooks here, they will be called without Apollo Context.
// Instead, we provide a client, when it is created
let clientRef: ApolloClient<NormalizedCacheObject>;

let refreshRef: Promise<void> | undefined;

/**
 * Hook for using authentication api and tokens logic
 */
export const useAuth = () => {
  const isAuthenticated = useObservable(isAuthenticatedStore$);

  const provideApolloClient = (client: ApolloClient<NormalizedCacheObject>) => {
    clientRef = client;
  };

  const getAccessToken = () =>
    localStorage.getItem(AppLocalStorageKeys.accessToken);

  const getRefreshToken = () =>
    localStorage.getItem(AppLocalStorageKeys.refreshToken);

  const login = (tokens: TokenResponse) => {
    localStorage.setItem(AppLocalStorageKeys.accessToken, tokens.accessToken);
    localStorage.setItem(AppLocalStorageKeys.refreshToken, tokens.refreshToken);

    isAuthenticatedStore$.next(true);
  };

  const logout = () => {
    localStorage.clear();

    isAuthenticatedStore$.next(false);
  };

  const refreshToken = () => {
    if (!clientRef) {
      return Promise.reject(
        new Error('Apollo client is not provided for auth service')
      );
    }

    if (refreshRef) {
      return refreshRef;
    }

    refreshRef = clientRef
      .mutate<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>({
        mutation: RefreshAuthTokenDocument,
        variables: {
          token: getRefreshToken() ?? '',
        },
        context: {
          headers: {
            authorization: '',
          },
        },
      })
      .then(({ errors, data }) => {
        if (!data || errors?.length) {
          throw errors;
        }

        login(data.refreshAuthToken);
      })
      .catch(() => {
        logout();
      })
      .finally(() => {
        refreshRef = undefined;
      });

    return refreshRef;
  };

  return {
    isAuthenticated,

    provideApolloClient,

    getAccessToken,
    getRefreshToken,
    login,
    logout,
    refreshToken,
  };
};

import React, { ReactNode, useState } from 'react';

import { Callout } from '~/services/notifications';

import { ButtonThemes, ButtonVariants } from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import { InjectedModalProps, ModalComponentProps } from '../../types';
import { Modal } from '../Modal';

export interface ConfirmModalProps
  extends InjectedModalProps<ConfirmModalProps>,
    Pick<
      ModalComponentProps,
      'onCancel' | 'submitButtonProps' | 'cancelButtonProps'
    > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title of confirm modal
   */
  title: ReactNode;
  /**
   * Message of confirm modal
   */
  message?: ReactNode;
  /**
   * Message, displayed in the callout
   */
  calloutMessage?: ReactNode;
  /**
   * Called when submit button pressed
   */
  onSubmit?: () => Promise<any> | void;
  /**
   * If true, buttons are shown in reversed order by default and save button has delete text
   */
  isDelete?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  className,
  title = 'Вы уверены?',
  message,
  calloutMessage,
  close,
  onSubmit,
  isDelete = false,
  ...modalProps
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      {...{
        className,
        title,
        ...mergeProps(
          {
            submitButtonProps: {
              isLoading,
              variant: ButtonVariants.primary,
              theme: isDelete ? ButtonThemes.destructive : ButtonThemes.accent,
              children: isDelete ? 'Подтвердить' : 'Сохранить',
            },
            cancelButtonProps: {
              variant: ButtonVariants.secondary,
            },
          } satisfies ModalComponentProps,
          modalProps
        ),
        onSubmit: async () => {
          const maybePromise = onSubmit?.();

          const isPromise = maybePromise instanceof Promise;

          try {
            if (isPromise) setIsLoading(true);

            await maybePromise;
            close();
          } catch {
            if (isPromise) setIsLoading(false);
          }
        },
      }}
    >
      {!!calloutMessage && <Callout className="mb-24" title={calloutMessage} />}
      {!!message && (
        <Typography {...{ variant: TypographyVariants.bodySmall }}>
          {message}
        </Typography>
      )}
    </Modal>
  );
};

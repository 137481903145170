import React from 'react';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Badge } from '~/shared/components/Badge';
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { EventAsyncSelect } from '~/entities/events';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CowsCopyKeyFragment } from '../../../../gql/fragments/CowsCopyKey.graphql';
import { UserEventMappingsFormType } from '../../hooks/useUserEventMappingsForm';
import stepsStyles from '../../steps.module.scss';
import { MappingType } from '../../types';
import { MappingItem } from '../MappingItem';

interface Props
  extends Pick<
    UseModalStepFormInterface<UserEventMappingsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * userEvents from CowsCopyKeyFragment
   */
  userEvents: CowsCopyKeyFragment['userEvents'];
}

export const UserEventMappingsForm: React.FC<Props> = ({
  className,
  formProps,
  userEvents,
}) => {
  const { formContext } = formProps;
  const userEventMappings = formContext.watch('userEventMappings');

  const hasUserEvents = !!userEvents.length;

  return (
    <Form
      {...{
        className,
        ...formProps,
      }}
    >
      <Typography
        {...{
          tag: 'div',
          variant: TypographyVariants.bodyMediumStrong,
          className: 'mb-16',
        }}
      >
        Переданные пользовательские события
      </Typography>
      {hasUserEvents && (
        <>
          <Typography
            {...{
              tag: 'div',
              className: stepsStyles.descriptionBlock,
              variant: TypographyVariants.bodySmall,
            }}
          >
            Проверьте список переданных пользовательских событий животных на
            соответствие названиям событий, используемых на вашей ферме
          </Typography>
          <div className="flex gap-8 mb-12">
            <Typography variant={TypographyVariants.bodySmallStrong}>
              Список событий
            </Typography>
            <Badge isPill>{userEvents?.length ?? 0}</Badge>
            <Button
              {...{
                variant: ButtonVariants.ghost,
                theme: ButtonThemes.neutral,
                iconVariant: IconVariants.block,
                size: ButtonSizes.small24,
                onPress: () => {
                  formContext.setValue(
                    'userEventMappings',
                    userEventMappings.map(item => ({
                      ...item,
                      mappingType: MappingType.ignore,
                    }))
                  );
                },
              }}
            >
              Не добавлять
            </Button>
          </div>
          <div className="flex flex-col gap-12">
            {userEvents?.map(({ name }, index) => {
              const userEventMapping = userEventMappings[index];
              const userEventItemNamePrefix =
                `userEventMappings.${index}` as const;

              return (
                <MappingItem
                  {...{
                    key: userEventMapping.originID,
                    index: index + 1,
                    title: name,
                    targetValue: userEventMapping,
                    mappingTypeNamePrefix: userEventItemNamePrefix,
                  }}
                >
                  <EventAsyncSelect
                    {...{
                      name: `${userEventItemNamePrefix}.targetID`,
                      placeholder: 'Выберите событие',
                      label: null,
                      queryOptions: {
                        variables: {
                          kinds: [EventKindEnum.User],
                        },
                      },
                    }}
                  />
                </MappingItem>
              );
            })}
          </div>
        </>
      )}
      {!hasUserEvents && (
        <div className={clsx(panelStyles.largePanel, 'p-24')}>
          <DataBlockedMessage
            {...{
              className: 'p-24',
              message:
                'Информация о пользовательских событиях не была передана',
            }}
          />
        </div>
      )}
    </Form>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatTimeRange } from '~/shared/helpers/date';

import { MilkingParlorIntervalFragment } from '../../../../gql/fragments/milkingParlorInterval.graphql';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Interval to render
   */
  interval: MilkingParlorIntervalFragment | SkeletonPlaceholder;
}

const SKELETON_HEIGHT_PX = 48;

export const MilkingParlorInterval: React.FC<Props> = ({
  className,
  interval,
}) => {
  const { renderWithSkeleton } = useSkeletonContext();

  return renderWithSkeleton(
    <Skeleton.Block height={SKELETON_HEIGHT_PX} />,

    <Link
      {...{
        to: MASLOV_ROUTES_TREE.User.entities.milkingParlorIntervalUrl.replace(
          ':intervalId',
          !isSkeletonPlaceholder(interval) ? interval.id : ''
        ),
        className: clsx(styles.root, className),
      }}
    >
      <Typography variant={TypographyVariants.bodySmallStrong}>
        {formatTimeRange(interval.intervalStart, interval.intervalEnd)}
      </Typography>
      <Icon
        {...{
          className: 'text-accent',
          variant: IconVariants.chevronDown,
          rotate: RotateVariants.left,
        }}
      />
    </Link>
  );
};

import { useFragment } from '@apollo/client';

import { DocumentNode } from 'graphql';
import R from 'ramda';

import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import {
  AnyFragment,
  DefaultIdInput,
  IdsQueryVariables,
  UsePaginatedQuery,
} from '../../types';
import { getFragmentDocumentName } from './getFragmentDocumentName';

interface MakeUseFragmentFromCacheOrQueryProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends IdsQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
> {
  /**
   * Type name of the fragment
   */
  typeName: string;
  /**
   * Fragment definition doc
   */
  fragment: DocumentNode;
  /**
   * Fragment name to find in the cache (default to typeName)
   */
  fragmentName?: string;
  /**
   * Generated api hook for requesting paginated data
   */
  useQuery: UsePaginatedQuery<QueryData, QueryVariables>;
  /**
   * Getter for actual fragment from the query result
   */
  getItemFromQueryData: (data: QueryData) => Fragment | undefined;
}

/**
 * Fabric to make a hook that retrieves a fragment from the cache or,
 * if it is not in the cache, queries an entity from the backend.
 */
export const makeUseFragmentFromCacheOrQuery = <
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends IdsQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
>({
  typeName,
  fragment,
  fragmentName = getFragmentDocumentName(fragment) || typeName,
  useQuery,
  getItemFromQueryData,
}: MakeUseFragmentFromCacheOrQueryProps<
  Fragment,
  QueryData,
  QueryVariables,
  IdInput
>) => {
  return (id?: string | null) => {
    const { complete, data: fragmentData } = useFragment<Fragment>({
      fragment,
      fragmentName,
      from: {
        __typename: typeName,
        id,
      },
    });

    const cacheFragment =
      complete && !R.isEmpty(fragmentData) ? fragmentData : undefined;

    // Try to get the fragment from backend if there is no fragment in cache.
    const {
      data: queryData,
      loading: isLoading,
      refetch,
    } = useQuery({
      variables: {
        // For now we don't support id inputs other than strings here
        ids: normalizeToArrayOrUndefined(id) ?? [],
      } as QueryVariables,
      skip: !!cacheFragment || !id,
    });

    const queryFragment = queryData
      ? getItemFromQueryData(queryData) ?? null
      : null;

    return { fragment: queryFragment ?? cacheFragment, refetch, isLoading };
  };
};

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { AnalyticsArea } from '~/~legacy/services/AnalyticsService';

import styles from './index.module.scss';

interface Props {
  area: AnalyticsArea;
  className?: string;
}

export const MetrickBackLink: React.FC<Props> = props => {
  const { area, className } = props;

  const textMap: Record<AnalyticsArea, { text: string; url: string }> =
    useMemo(() => {
      return {
        [AnalyticsArea.herd]: {
          text: 'Назад к Cтатусу стада',
          url: MASLOV_ROUTES_TREE.User.analytics.herdUrl,
        },
        [AnalyticsArea.reproduction]: {
          text: 'Назад к Воспроизводству',
          url: MASLOV_ROUTES_TREE.User.analytics.reproductionBackUrl,
        },
      };
    }, []);

  const { text, url } = textMap[area];

  return (
    <NavLink className={clsx(styles.root, className)} to={url}>
      {' '}
      ← {text}
    </NavLink>
  );
};

import React from 'react';
import { Route } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { ApplicationShell } from '../../components/ApplicationShell';

const BlueprintsListPage = React.lazy(() =>
  import('~/pages/integrator/blueprintsList').then(component => ({
    default: component.BlueprintsListPage,
  }))
);

const BlueprintDetailsPage = React.lazy(() =>
  import('~/pages/integrator/blueprintDetail').then(component => ({
    default: component.BlueprintDetailsPage,
  }))
);

const BlueprintEditPage = React.lazy(() =>
  import('~/pages/integrator/blueprintEdit').then(component => ({
    default: component.BlueprintEditPage,
  }))
);

const MasterBlueprintEditPage = React.lazy(() =>
  import('~/pages/integrator/masterBlueprintEdit').then(component => ({
    default: component.MasterBlueprintEditPage,
  }))
);

export function getIntegratorRoutes() {
  return [
    <Route
      key={MASLOV_ROUTES_TREE.Integrator.root}
      path={MASLOV_ROUTES_TREE.Integrator.root}
      element={<ApplicationShell />}
    >
      <Route
        path={MASLOV_ROUTES_TREE.Integrator.blueprints}
        element={<BlueprintsListPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.Integrator.masterBlueprintEdit}
        element={<MasterBlueprintEditPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.Integrator.blueprintEdit}
        element={<BlueprintEditPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.Integrator.blueprintDetails}
        element={<BlueprintDetailsPage />}
      />
    </Route>,
  ];
}

import React from 'react';

import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Input } from '~/shared/components/Input';

import formStyles from '~/styles/modules/form.module.scss';

import { EditInseminationSchemeFormType } from '../../hooks/useInseminationSchemeForm';

interface Props
  extends Pick<
    UseModalStepFormInterface<EditInseminationSchemeFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditInseminationSchemeForm: React.FC<Props> = ({
  className,
  formProps,
}) => {
  return (
    <Form
      {...{
        className: clsx(formStyles.singleColumnForm, className),
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'name',
          label: 'Название схемы осеменения',
          placeholder: 'Например, Охота',
          isRequired: true,
        }}
      />
      <Input
        {...{
          name: 'code',
          label: 'Код',
          placeholder: 'Введите код',
        }}
      />
      <Input
        {...{
          name: 'description',
          label: 'Описание',
          placeholder: 'Дополнительная информация о схеме',
        }}
      />
    </Form>
  );
};

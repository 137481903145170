import React, { ReactElement } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { Badge } from '~/shared/components/Badge';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';

import panelStyles from '~/styles/modules/panel.module.scss';

import { ConformityMarkItem, MappingType } from '../../types';
import { BADGE_MAPPING_TYPE_CONFIGS } from './constants';
import styles from './index.module.scss';

export interface MappingItemProps extends React.PropsWithChildren {
  /**
   * Number of items (starts with 1)
   */
  index: number;
  /**
   * title of item
   */
  title: string;
  /**
   * Option to enable ignore option (default - true)
   */
  hasIgnoreOption?: boolean;
  /**
   * Current targetValue of the item
   */
  targetValue: {
    targetID: string | null;
    mappingType: MappingType;
  };
  /**
   * Prefix name for mappingType segmented tab.
   */
  mappingTypeNamePrefix: `${
    | 'diseaseMappings'
    | 'inseminationBullMappings'
    | 'userEventMappings'}.${number}`;
}

export const MappingItem: React.FC<MappingItemProps> = ({
  index,

  title,
  targetValue,

  hasIgnoreOption = true,
  mappingTypeNamePrefix,

  children,
}) => {
  const isEquallyMappingType = targetValue.mappingType === MappingType.equally;
  const isIgnoreMappingType = targetValue.mappingType === MappingType.ignore;

  const badgeConfig = R.path(
    [
      targetValue.mappingType,
      isEquallyMappingType && !targetValue.targetID
        ? 'withoutValue'
        : 'withValue',
    ],
    BADGE_MAPPING_TYPE_CONFIGS
  );

  const conformityMarkItems: ConformityMarkItem[] = [
    { id: MappingType.equally, name: IconVariants.equally },
    { id: MappingType.create, name: IconVariants.plus },
    ...wrapConditionalArrayElement(
      hasIgnoreOption && { id: MappingType.ignore, name: IconVariants.block }
    ),
  ];

  return (
    <div className={clsx(styles.root, panelStyles.panel)}>
      <div>{index}</div>
      <div className={styles.baseBlock}>
        <Typography
          className={clsx(isIgnoreMappingType && styles.ignoreTitle)}
          variant={TypographyVariants.bodySmall}
        >
          {title}
        </Typography>
        <Badge color={badgeConfig?.color}>{badgeConfig?.title}</Badge>
      </div>

      <RadioGroup
        {...{
          variant: RadioGroupVariants.segmented,
          items: conformityMarkItems,
          className: styles.segmentedTab,
          value: targetValue.mappingType,
          name: `${mappingTypeNamePrefix}.mappingType`,
          renderItem: (conformityMarkItem?: ConformityMarkItem) => {
            if (!conformityMarkItem) return null;

            return (
              <Icon
                {...{
                  className: 'block text-soft',
                  variant: conformityMarkItem.name,
                }}
              />
            );
          },
        }}
      />
      {isEquallyMappingType && (
        <div className={styles.mappingSelect}>
          <Icon
            className="text-soft items-start"
            variant={IconVariants.arrowDownRightTurn}
          />
          {React.isValidElement(children) &&
            React.cloneElement(children as ReactElement, {
              className: 'flex-1',
            })}
        </div>
      )}
    </div>
  );
};

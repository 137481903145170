import React, { ReactNode, useRef } from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { useControllableState } from '~/shared/hooks/useControllableState';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import { SizeVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

export interface TooltipProps extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * className applied to the tooltip content
   */
  contentClassName?: string;
  /**
   * Tooltip icon size
   */
  size?: SizeVariants;
  /**
   * If passed, overrides default max width
   */
  maxWidth?: number | string;

  /**
   * Content of the tooltip
   */
  content?: ReactNode;

  /**
   * Preferred tooltip placement
   */
  placement?: Placement;

  /**
   * Is tooltip opened
   */
  isOpen?: boolean;
  /**
   * Default isOpen value
   */
  defaultIsOpen?: boolean;
  /**
   * Called, when isOpen state is changed infernally by user interactions
   */
  onIsOpenChange?: (newIsOpen: boolean) => void;

  /**
   * If true, tooltip is disabled
   */
  isDisabled?: boolean;
}

const DEFAULT_OFFSET_PX = NUMBER_TOKENS.spacing8;

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  contentClassName,

  size = SizeVariants.size16,
  maxWidth,

  content,

  placement = 'top',

  isOpen: isOpenProp,
  onIsOpenChange,
  defaultIsOpen = false,

  isDisabled,

  children = (
    <Icon
      {...{
        className: clsx(className, styles.tooltipIcon),
        variant: IconVariants.helpCircleFilled,
        size,
      }}
    />
  ),
}) => {
  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    onIsOpenChange,
    defaultIsOpen
  );

  const arrowRef = useRef(null);
  const { context: floatingContext, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    strategy: 'fixed',
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(DEFAULT_OFFSET_PX),
      flip({
        crossAxis: placement.includes('-'),
        padding: DEFAULT_OFFSET_PX,
      }),
      shift({ padding: DEFAULT_OFFSET_PX }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const hover = useHover(floatingContext, { enabled: !isDisabled });
  const dismiss = useDismiss(floatingContext);
  const role = useRole(floatingContext, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  const referenceWithFloatingPropsElement = React.isValidElement(children)
    ? React.cloneElement(
        children,
        getReferenceProps({
          ref: mergeRefs(
            (children as any).ref,
            floatingContext.refs.setReference
          ),
          ...mergeProps(
            !isDisabled && { style: { cursor: 'pointer' } },
            children.props
          ),
        })
      )
    : null;

  return (
    <>
      {referenceWithFloatingPropsElement}
      {isOpen && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              className: clsx(styles.root, contentClassName),
              ref: floatingContext.refs.setFloating,
              style: { ...floatingStyles, maxWidth },
            })}
          >
            <FloatingArrow
              {...{
                className: styles.arrow,
                ref: arrowRef,
                context: floatingContext,
                width: 12,
                height: 6,
                tipRadius: 1,
              }}
            />
            <Typography variant={TypographyVariants.descriptionLarge}>
              {content}
            </Typography>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

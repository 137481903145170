import React from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';
import * as yup from 'yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { makeDeleteQuery } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';

import { SelectVariants } from '~/shared/components/Select';
import { TimeInput } from '~/shared/components/TimeInput';

import { readPenGroupFragment, usePenGroupSelect } from '~/entities/penGroups';

import formStyles from '~/styles/modules/form.module.scss';

import { MilkingParlorIntervalDetailedFragment } from '../../gql/fragments/milkingParlorIntervalDetailed.graphql';
import {
  CreateMilkingParlorIntervalMutation,
  useCreateMilkingParlorIntervalMutation,
} from '../../gql/mutations/createMilkingParlorInterval.graphql';
import { useUpdateMilkingParlorIntervalMutation } from '../../gql/mutations/updateMilkingParlorInterval.graphql';
import {
  updateMilkingParlorFragment,
  updateMilkingParlorIntervalWithMilkingParlorFragment,
} from '../../helpers';

export interface EditMilkingParlorIntervalModalProps
  extends InjectedModalProps<EditMilkingParlorIntervalModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking parlor for which we edit an interval
   */
  milkingParlorID: string;
  /**
   * Editing milking interval, if not passed, a new one is created
   */
  milkingParlorInterval?: MilkingParlorIntervalDetailedFragment;
}

const FORM_ID = 'EditMilkingParlorIntervalForm';

const SCHEMA = yup.object({
  penGroupIDs: yup.array(yup.string().required()).default([]), // [ID!]
  intervalStart: yup.string().required(), // Time!
  intervalEnd: yup.string().required(), // Time!
});

type EditMilkingParlorIntervalFormType = InferValidatedSchema<typeof SCHEMA>;

export const EditMilkingParlorIntervalModal: React.FC<
  EditMilkingParlorIntervalModalProps
> = ({ className, milkingParlorID, milkingParlorInterval, close }) => {
  const isEditing = !!milkingParlorInterval;

  const client = useApolloClient();

  const formContext = useForm<EditMilkingParlorIntervalFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['intervalStart', 'intervalEnd'],
        milkingParlorInterval ?? ({} as MilkingParlorIntervalDetailedFragment)
      ),
      penGroupIDs:
        milkingParlorInterval?.penGroups.edges.map(e => e.node.id) ?? [],
    },
  });

  const { renderSelectElement: renderPenGroupIdSelectElement } =
    usePenGroupSelect({
      selectProps: {
        label: 'Доильные группы',
        name: 'penGroupIDs',
        isMulti: true,
        variant: SelectVariants.withItemsList,
      },
    });

  const [
    createMilkingParlorInterval,
    { loading: isCreateMilkingParlorIntervalLoading },
  ] = useCreateMilkingParlorIntervalMutation();

  const [
    updateMilkingParlorInterval,
    { loading: isUpdateMilkingParlorIntervalLoading },
  ] = useUpdateMilkingParlorIntervalMutation();

  const handleSubmit = async (form: EditMilkingParlorIntervalFormType) => {
    if (isEditing) {
      await updateMilkingParlorInterval({
        variables: {
          id: milkingParlorInterval.id,
          input: form,
        },
        update: R.juxt([
          updateMilkingParlorIntervalWithMilkingParlorFragment(
            milkingParlorInterval.id,
            draft => {
              draft.intervalStart = form.intervalStart;
              draft.intervalEnd = form.intervalEnd;
              draft.penGroups = {
                edges: form.penGroupIDs
                  .map(penGroupId => readPenGroupFragment(client, penGroupId))
                  .filter(Boolean)
                  .map(penGroup => ({
                    milkingNumber: null,
                    node: penGroup,
                  })),
              };
            }
          ),
          makeDeleteQuery('milkingParlors'),
        ]),
      });
    } else {
      await createMilkingParlorInterval({
        variables: {
          input: { ...form, milkingParlorID },
        },
        update:
          updateMilkingParlorFragment<CreateMilkingParlorIntervalMutation>(
            milkingParlorID,
            (draft, { mutationData }) => {
              draft.intervals.push(mutationData.createMilkingParlorInterval);
            }
          ),
      });
    }
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing
          ? 'Редактирование времени доения'
          : 'Добавление времени доения',
        submitButtonProps: {
          form: FORM_ID,
          isLoading:
            isCreateMilkingParlorIntervalLoading ||
            isUpdateMilkingParlorIntervalLoading,
          children: isEditing ? 'Сохранить' : 'Добавить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          className: 'grid gap-16',
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <div className={formStyles.twoColumnForm}>
          <TimeInput
            {...{
              name: 'intervalStart',
              label: 'Время начала',
            }}
          />
          <TimeInput
            {...{
              name: 'intervalEnd',
              label: 'Время окончания',
            }}
          />
        </div>
        {renderPenGroupIdSelectElement()}
      </Form>
    </Modal>
  );
};

import React, { useMemo, useRef, useState } from 'react';
import { Chart as ChartVendor } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import {
  Chart,
  ChartType,
  DefaultDataPoint,
  Plugin,
  PluginChartOptions,
  PluginOptionsByType,
  Tooltip as ChartTooltip,
  TooltipOptions,
  TooltipPositionerFunction,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import clsx from 'clsx';

import { useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';
import { useToggle } from '~/shared/hooks/useToggle';

import { ChartLegend } from '../ChartLegend';
import { ROTATED_AXIS_LABELS_PLUGIN } from './rotatedAxisLabelsPlugin';
import { ReactChartProps, TooltipData } from './types';

Chart.register(zoomPlugin);

Chart.defaults.font.size = 12;
Chart.defaults.font.lineHeight = '16px';

ChartTooltip.positioners.cursor = function (elements, eventPosition) {
  const averageResult = ChartTooltip.positioners.average.call(
    this,
    elements,
    eventPosition
  );

  return {
    x: averageResult ? averageResult.x : 0,
    y: eventPosition.y,
  };
} satisfies TooltipPositionerFunction<ChartType>;

const DEFAULT_TOOLTIP_DATA = { dataPoints: [], left: -1, top: -1 };

export const ReactChart = <
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
>({
  chartClassName,

  labels,
  datasetConfigs,

  legendClassName,
  legendTitle,
  legendConfigs = datasetConfigs,

  renderTooltip,
  onLegendItemToggle,

  skeleton,
  isSkeletonWithHeight = true,

  height,
  options,

  plugins = [ROTATED_AXIS_LABELS_PLUGIN as Plugin<TType>],

  ...chartProps
}: ReactChartProps<TType, TData, TLabel>) => {
  const [isTooltipOpen, toggleTooltip] = useToggle();
  const [tooltipData, setTooltipData] =
    useState<TooltipData<TType>>(DEFAULT_TOOLTIP_DATA);

  const { renderWithSkeleton } = useSkeletonContext();

  const chartRef = useRef<ChartJSOrUndefined<TType, TData, TLabel>>();

  // Special state for panning a chart, cause we should display grabbing cursor on pan
  const [isPanInProgress, setPanInProgress] = useState(false);

  // We can only mutate initial options object cause zoom plugin looses its state,
  // if we provide new options reference
  const chartOptions = useMemo(() => {
    const chartPanOptions = (options as PluginChartOptions<TType>).plugins?.zoom
      ?.pan;
    if (chartPanOptions) {
      // eslint-disable-next-line no-param-reassign -- see useMemo comment
      chartPanOptions.onPanComplete = () => setPanInProgress(false);
    }

    if (!renderTooltip) {
      return options;
    }

    const pluginOptions = (options as PluginChartOptions<TType>)
      .plugins as PluginOptionsByType<TType>;
    // eslint-disable-next-line no-param-reassign -- see useMemo comment
    (options as PluginChartOptions<TType>).plugins =
      pluginOptions ?? ({} as PluginOptionsByType<TType>);
    const tooltipOptions = pluginOptions.tooltip as TooltipOptions<TType>;
    pluginOptions.tooltip = tooltipOptions ?? ({} as TooltipOptions<TType>);

    tooltipOptions.enabled = false;
    tooltipOptions.external = ({ tooltip }) => {
      if (!tooltip.opacity) {
        toggleTooltip(false);
        return;
      }

      const newData = {
        dataPoints: tooltip.dataPoints,
        left: tooltip.caretX,
        top: tooltip.caretY,
      };

      toggleTooltip(true);

      if (
        tooltipData.left !== newData.left ||
        tooltipData.top !== newData.top
      ) {
        setTooltipData(newData);
      }
    };

    return options;
  }, [options, isTooltipOpen, tooltipData]);

  return (
    <>
      <ChartLegend
        {...{
          className: legendClassName,
          title: legendTitle,
          items: legendConfigs,
          onItemToggle: (datasetIndex, isSelected) => {
            if (!chartRef.current) return;

            chartRef.current.setDatasetVisibility(datasetIndex, isSelected);

            onLegendItemToggle?.(datasetIndex, isSelected, chartRef.current);

            chartRef.current?.update();
          },
        }}
      />
      {renderWithSkeleton(
        <div style={{ height: isSkeletonWithHeight ? height : undefined }}>
          {skeleton}
        </div>,
        <div
          className={clsx(
            'position-relative full-width',
            chartClassName,
            isPanInProgress ? 'cursor-grabbing' : 'cursor-default'
          )}
          style={{ height }}
        >
          <ChartVendor
            {...{
              ref: chartRef,
              options: chartOptions,
              ...chartProps,
              // Set isPanInProgress immediately for grabbing cursor,
              // so user can see, that chart is movable
              onMouseDown: () => setPanInProgress(true),
              onMouseUp: () => setPanInProgress(false),
              data: {
                labels,
                datasets: datasetConfigs,
              },
              plugins,
            }}
          />
          <Tooltip
            {...{
              key: `${tooltipData.left}__${tooltipData.top}`,
              contentClassName: 'pointer-events-none',
              isDisabled: !isTooltipOpen,
              isOpen: isTooltipOpen,
              content:
                isTooltipOpen && tooltipData.dataPoints?.length
                  ? renderTooltip?.(tooltipData.dataPoints)
                  : null,
            }}
          >
            <div
              {...{
                className: 'position-absolute',
                style: {
                  left: tooltipData.left,
                  top: tooltipData.top,
                },
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export * from './types';

import { ClipboardEventHandler } from 'react';

import { VitalityFilter } from '@graphql-types';
import R from 'ramda';

import {
  CowsQuery,
  CowsQueryVariables,
  useCowsLazyQuery,
} from '../gql/queries/cows.graphql';

type UseHandlePasteCowIdentifiersProps = {
  cowsQueryVariables?: Partial<CowsQueryVariables>;
  onCowsLoaded?: (data: CowsQuery) => void;
};

/**
 * hook to create onPaste cow identifiers handlers
 */
export const useHandlePasteCowIdentifiers = ({
  onCowsLoaded,
  cowsQueryVariables = {},
}: UseHandlePasteCowIdentifiersProps = {}) => {
  const [loadCows, queryResult] = useCowsLazyQuery();

  const handlePasteCowIdentifiers: ClipboardEventHandler = async e => {
    const identifiers = e.clipboardData
      .getData('text/plain')
      .replaceAll('\r', '')
      .split('\n')
      .map(Number)
      .filter(R.complement(Number.isNaN));

    if (!identifiers.length) return;

    e.preventDefault();

    const { data } = await loadCows({
      variables: {
        identifiers,
        vitalityFilter: VitalityFilter.Alive,
        ...(cowsQueryVariables ?? {}),
      },
    });

    if (data) {
      onCowsLoaded?.(data);
    }
  };
  return { handlePasteCowIdentifiers, queryResult };
};

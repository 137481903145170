import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../../entities/farms/gql/fragments/farm.graphql';
export type FarmWithMilkingParlorSettingsFragment = { __typename: 'Farm', id: string, name: string, number: string, milkingParlorSettings?: { __typename?: 'MilkingParlorFarmSetting', firstMilkingPerDayStartOn: any } | null };

export const FarmWithMilkingParlorSettingsFragmentDoc = gql`
    fragment FarmWithMilkingParlorSettings on Farm {
  ...Farm
  milkingParlorSettings {
    firstMilkingPerDayStartOn
  }
}
    ${FarmFragmentDoc}`;
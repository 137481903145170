import React, { useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import { MErrorMessage } from '~/shared/components/MErrorMessage';
import { MLoader } from '~/shared/components/MLoader';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';

export interface ApiFeedbackProps {
  errors: string[];
  loading: boolean;
}

export function useApiFeedback(
  errors: (string | ApolloError | undefined)[] | ApolloError | undefined,
  loading?: boolean,
  errorClass?: string
) {
  const loader = useMemo(() => {
    return loading ? <MLoader /> : null;
  }, [loading]);

  const errorMessage = useMemo(() => {
    const errorsArray = normalizeValuesToArray(errors);
    return errorsArray
      ?.map(item => {
        let errorString = item;
        if (typeof errorString !== 'string') {
          errorString = errorString?.message;
        }
        if (!errorString) return null;

        return (
          <MErrorMessage className={errorClass} key={errorString}>
            {errorString}
          </MErrorMessage>
        );
      })
      .filter(Boolean);
  }, [errors]);

  return {
    loader,
    errorMessage,
  };
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { VitalityFilter } from '@graphql-types';
import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { ReactComponent as BarSVG } from '~/~legacy/icons/bar-graph.svg';
import { ReactComponent as CalendarSVG } from '~/~legacy/icons/calendar.svg';
import { ReactComponent as ClipboardSVG } from '~/~legacy/icons/clipboard.svg';
import { ReactComponent as DataSVG } from '~/~legacy/icons/data-storage.svg';
import { ReactComponent as ExclamationSVG } from '~/~legacy/icons/exclamation-mark.svg';
import { ReactComponent as HerdSVG } from '~/~legacy/icons/herd.svg';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { useArkaNavigation } from '~/services/navigation';

import { usePrevious } from '~/shared/hooks/usePrevious';

import { CompanyLoadingBlocker } from '~/entities/companies';
import { getCowPageUrl, useCowSelect } from '~/entities/cows';

import { Header, HeaderLink } from '../Header';
import { HeaderCompanySelector } from '../HeaderCompanySelector';
import styles from './index.module.scss';

// TODO remove scroll container after layout rework, cause the main scroll should be on body
const SCROLL_CONTAINER_ID = 'MainScrollContainer';

export const ApplicationShell: React.FC = () => {
  // Preload some translations to avoid glitch in modals
  useTranslation([MaslovNamespaces.enums, MaslovNamespaces.validation]);

  const {
    navigate,
    selectedCompanyId,
    selectedCompany,
    getPathRelativeToCompany,
  } = useArkaNavigation();

  const apolloClient = useApolloClient();
  const prevCompanyId = usePrevious(selectedCompanyId);
  // Clear cache on selectedCompanyId change,
  // cause all queries should be requested with the new x-active-company header
  useEffect(() => {
    if (prevCompanyId && prevCompanyId !== selectedCompanyId) {
      apolloClient.resetStore();
    }
  }, [selectedCompanyId]);

  const companySelector = <HeaderCompanySelector />;

  const navLinks: HeaderLink[] = [
    {
      key: getPathRelativeToCompany(
        MASLOV_ROUTES_TREE.User.productionCalendarUrl
      ),
      value: (
        <>
          <CalendarSVG />
          Календарь
        </>
      ),
    },
    {
      key: getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.incidentsUrl),
      value: (
        <>
          <ExclamationSVG />
          Команды
        </>
      ),
    },
    {
      key: getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.analyticsUrl),
      value: (
        <>
          <BarSVG />
          Аналитика
        </>
      ),
    },
    {
      key: getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.herdUrl),
      value: (
        <>
          <HerdSVG />
          Стадо
        </>
      ),
    },
    {
      key: getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.entitiesUrl),
      value: (
        <>
          <DataSVG />
          Управление
        </>
      ),
    },
    {
      key: getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.events.eventsUrl),
      value: (
        <>
          <ClipboardSVG />
          События
        </>
      ),
    },
  ];

  const { cowId } = useParams();

  const [cowSearch, setCowSearch] = useState('');
  // Clear select search based on switch url
  const { pathname } = useLocation();
  useEffect(() => {
    setCowSearch('');
  }, [pathname]);

  // We're using an old react-router api, that doesn't support ScrollRestoration component,
  // so we should emulate scroll to top at new page opening.
  // TODO change to scroll restoration logic after router rework
  useLayoutEffect(() => {
    document.getElementById(SCROLL_CONTAINER_ID)?.scrollTo(0, 0);
  }, [pathname]);

  const { renderSelectElement: renderCowSelectElement } = useCowSelect({
    queryOptions: {
      variables: {
        vitalityFilter: VitalityFilter.All,
      },
    },
    selectProps: {
      name: 'cow',
      className: styles.cowSelect,
      rawValue: cowId,
      search: cowSearch,
      onSearchChange: setCowSearch,
      onValueChange: newValue => {
        if (!newValue) return;
        navigate(getPathRelativeToCompany(getCowPageUrl(newValue.id || '')));
      },
    },
  });

  const header = selectedCompany?.isLockedForWrite ? (
    <Header links={[]} companySelector={companySelector} quickSearch={<></>} />
  ) : (
    <Header
      links={navLinks}
      companySelector={companySelector}
      quickSearch={renderCowSelectElement({ key: cowId })}
    />
  );

  const content = selectedCompany?.isLockedForWrite ? (
    <CompanyLoadingBlocker />
  ) : (
    <Outlet />
  );

  return (
    <>
      {header}
      <div
        id={SCROLL_CONTAINER_ID}
        className={clsx(
          // TODO This class is used in MOverlay component, remove it, when we completely switch to new popovers
          'm-application-shell-container',
          styles.rootWrapper
        )}
      >
        <main className={styles.root}>{content}</main>
      </div>
    </>
  );
};

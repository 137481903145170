import React, { useState } from 'react';

import { tap } from 'rxjs';

import { NetworkError, networkErrorsSubject$ } from '~/services/gql';

import { useObservable } from '~/shared/hooks/useObservable';

import { TechnicalMaintenancePage } from '../../pages';
import { ErrorBoundary } from '../ErrorBoundary';

export const ErrorBoundaryWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [showError, setShowError] = useState(false);

  const processNetworkError = (networkError: NetworkError) => {
    if (networkError?.statusCode === 503) {
      setShowError(true);
    }
  };

  useObservable(
    networkErrorsSubject$.pipe(tap(processNetworkError)),
    undefined
  );

  if (showError) {
    return <TechnicalMaintenancePage />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { NavLink } from 'react-router-dom';

import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  TimeUnit,
  Tooltip,
} from 'chart.js';
import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { ReactComponent as ExpandSVG } from '~/~legacy/icons/expand.svg';
import { HerdStateMetricChart } from '~/~legacy/services/AnalyticsService';

import { MLinkButton } from '~/shared/components/MLinkButton';
import { MPanel } from '~/shared/components/MPanel';
import {
  AnalyticalMetricEnum,
  AnalyticsGroupingPeriodEnum,
} from '~/shared/graphql';

import TOKENS from '~/styles/__generated__/tokens.json';

import { METRIC_CONFIGS } from '../../constants';
import { getTargetsData } from '../../helpers';
import { ExpandedChart } from '../../types';
import { getChartDashboardOptions } from './helpers';
import styles from './index.module.scss';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(BarElement);

function useTimeUnitMap() {
  const timeUnitMap = useMemo(() => {
    const map: Record<AnalyticsGroupingPeriodEnum, TimeUnit> = {
      [AnalyticsGroupingPeriodEnum.Day]: 'day',
      [AnalyticsGroupingPeriodEnum.Week]: 'week',
      [AnalyticsGroupingPeriodEnum.Month]: 'month',
      [AnalyticsGroupingPeriodEnum.Year]: 'year',
    };
    return map;
  }, []);
  return timeUnitMap;
}

interface Props {
  chart: HerdStateMetricChart;
  groupingPeriod: AnalyticsGroupingPeriodEnum;
  metric: AnalyticalMetricEnum;
  selectedFarmId?: string;
  hideHeader?: boolean;
  className?: string;

  expanded?: boolean;
  expand?: (chartInfo: ExpandedChart) => void;
}

export const ChartDashboardCard: React.FC<Props> = ({
  chart,
  groupingPeriod,
  metric,
  hideHeader,
  className,
  selectedFarmId,
  expand,
  expanded,
}) => {
  const timeUnitMap = useTimeUnitMap();
  const timeUnit = timeUnitMap[groupingPeriod];

  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState<ChartOptions>(
    getChartDashboardOptions(timeUnit)
  );
  const [chartData, setChartData] = useState<ChartData>({
    datasets: [],
  });
  const { name } = METRIC_CONFIGS[metric];
  const farmFilter = selectedFarmId ? `?farmId=${selectedFarmId}` : '';
  const url =
    MASLOV_ROUTES_TREE.User.analytics.metricUrl.replace(':metric', metric) +
    farmFilter;

  useEffect(() => {
    setChartOptions(getChartDashboardOptions(timeUnit));
  }, [groupingPeriod]);

  useEffect(() => {
    const barstData = chart.bars.map(item => {
      return {
        x: item.date,
        y: item.value,
      };
    });

    const barsStart =
      barstData.length > 0 ? new Date(barstData[0].x) : new Date();
    const barsEnd =
      barstData.length > 0
        ? new Date(barstData[barstData.length - 1].x)
        : new Date();

    const targetsData = getTargetsData(barsStart, barsEnd, chart.targets);

    const newChartData: ChartData = {
      datasets: [
        {
          type: 'line',

          data: targetsData as any,
          borderColor: TOKENS.colorWarning300,
        },
        {
          type: 'bar',
          data: barstData,
          backgroundColor: TOKENS.colorSuccessDefault,
          borderRadius: 4,
        },
      ],
    };

    setChartData(newChartData);
  }, [chart, setChartData]);

  const chartComponent = useMemo(() => {
    return (
      <ReactChart
        className={styles.chart}
        options={chartOptions}
        type="line"
        datasetIdKey="first"
        ref={chartRef}
        data={chartData}
      />
    );
  }, [chartData, chartOptions]);

  useEffect(() => {
    if (expand && expanded) {
      expand({
        node: chartComponent,
        title: name,
      });
    }
  }, [chartComponent, expand]);

  const expandCallback = () => {
    if (expand)
      expand({
        node: chartComponent,
        title: name,
      });
  };

  const exapndLink = expand ? (
    <MLinkButton onClick={expandCallback} className="text-muted ml-16">
      Развернуть{' '}
      <ExpandSVG className="ml-8" style={{ verticalAlign: 'middle' }} />
    </MLinkButton>
  ) : null;

  const chartHeader = hideHeader ? (
    <div className="text-right">{exapndLink}</div>
  ) : (
    <div>
      <p>{name}</p>
      <div>
        <NavLink to={url} className="text-muted">
          Подробнее
        </NavLink>
        {exapndLink}
      </div>
    </div>
  );

  return (
    <MPanel className={clsx(styles.root, className)}>
      {chartHeader}
      {chartComponent}
    </MPanel>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';

import { Checkbox } from '~/shared/components/Checkbox';
import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Label } from '~/shared/components/Label';
import { Loader } from '~/shared/components/Loader';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorCssVarName } from '~/shared/helpers/color';
import {
  defaultGetItemDescription,
  defaultGetItemText,
  defaultGetItemValue,
} from '~/shared/helpers/itemProps';
import { mergeHandlers, mergeRefs } from '~/shared/helpers/mergeProps';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';
import { joinJsxArray } from '~/shared/helpers/render';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { useElementSize } from '~/shared/hooks/useElementSize';
import { usePrevious } from '~/shared/hooks/usePrevious';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

import { AsyncList } from '../AsyncList';
import { Icon, IconVariants, RotateVariants } from '../Icon';
import { Popover } from '../Popover';
import styles from './index.module.scss';
import {
  RenderSelect,
  SelectItem,
  SelectProps,
  SelectThemes,
  SelectVariants,
} from './types';

const SelectInner = <I extends SelectItem>(
  {
    name,
    className,
    isDisabled,
    isRequired,
    placeholder = 'Выберите значение',

    items,
    inputRef,
    shouldOpenOnArrows = true,

    theme = SelectThemes.dark,
    variant = SelectVariants.full,
    isClearable = false,
    listActionLabel,
    onListActionPress,
    isOptimistic = false,
    loadingMessage,
    popoverWidth,

    // Can't use default here but undefined is falsy anyways - https://github.com/microsoft/TypeScript/issues/50139
    isMulti,
    hasError,

    rawValue,
    value: valueProp,
    onValueChange,
    defaultValue,

    getItemValue = defaultGetItemValue,
    getItemText = defaultGetItemText,
    renderItemText,
    getItemDescription = defaultGetItemDescription,
    getItemColorVariant,
    noItemsMessage = 'Нет элементов',
    noItemsFoundMessage = 'Нет совпадений',

    search: searchProp,
    defaultSearch = '',
    onSearchChange,
    isItemMatchingSearch = (item, search) => {
      let itemText = getItemText(item).toLowerCase();

      const description = getItemDescription(item)?.toLowerCase();

      if (description) {
        itemText = `${itemText} ${description}`;
      }
      return itemText.includes(search.toLowerCase());
    },

    onPaste,
    onKeyDown,

    label,
    labelProps,
    feedback,
    feedbackProps,

    asyncProps,
  }: SelectProps<I>,
  ref: React.Ref<HTMLInputElement>
) => {
  // Search items
  const [search, setSearch] = useControllableState(
    searchProp,
    onSearchChange,
    defaultSearch
  );

  const filteredItems = useMemo(
    () => items.filter(item => isItemMatchingSearch(item, search)),
    [items, search]
  );

  // Normalize values and prepare state
  let value = valueProp;
  if (rawValue !== undefined) {
    value = isMulti
      ? items.filter(i => rawValue.includes(getItemValue(i)))
      : items.find(i => getItemValue(i) === rawValue);
  }
  const valueAsArray = normalizeValuesToArray(value);
  const defaultValueAsArray = normalizeValuesToArray(defaultValue) ?? [];

  const [selectedItemsState, setSelectedItems] = useControllableState<I[]>(
    valueAsArray,
    newValues => {
      if (isMulti) {
        onValueChange?.(newValues);
      } else {
        onValueChange?.(newValues[0]);
      }
    },
    defaultValueAsArray
  );

  // Special handling of null item value
  let selectedItems = selectedItemsState;
  const itemWithUndefinedValue = filteredItems.find(
    item => getItemValue(item) === null
  );
  if (!selectedItemsState.length && itemWithUndefinedValue) {
    selectedItems = [itemWithUndefinedValue];
  }

  // Handle selection behavior
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({
    selectedItems,
    onSelectedItemsChange: ({ selectedItems: newSelectedItems }) => {
      setSelectedItems(newSelectedItems ?? []);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // We don't need focus on active items
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: state.activeIndex,
          };

        default:
          return changes;
      }
    },
  });

  const internalInputRef = useRef<HTMLInputElement>(null);

  // Handle combobox behavior
  const {
    isOpen,
    openMenu,
    closeMenu,
    highlightedIndex,
    setHighlightedIndex,
    selectItem,
    getItemProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
  } = useCombobox<I | null>({
    id: name,
    itemToString: getItemText,
    items: filteredItems,
    inputValue: search,
    onStateChange: async ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setSearch(inputValue ?? '');

          break;

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          // We need to restore focus on input to remain accessible, it's lost cause of popup
          setTimeout(() => {
            internalInputRef.current?.focus();
          }, 0);

          if (selectedItem) {
            if (!isMulti) {
              setSearch('');
            }
            if (selectedItems.includes(selectedItem)) {
              if (isMulti) {
                removeSelectedItem(selectedItem);
              } else if (isClearable) {
                setSelectedItems([]);
              }
            } else if (isMulti) {
              addSelectedItem(selectedItem);
            } else {
              setSelectedItems([selectedItem]);
            }
          }
          selectItem(null);

          break;
        }

        default:
          break;
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // override default behavior to satisfy popup
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: !isDisabled && !loadingMessage && changes.isOpen,
          };

        case useCombobox.stateChangeTypes.InputBlur:
          return state;

        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown: {
          if (state.isOpen) {
            return changes;
          }
          return {
            ...changes,
            isOpen: shouldOpenOnArrows && changes.isOpen,
          };
        }

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // Don't close multiple select to continue selection
            isOpen: isMulti,
          };

        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            isOpen: !state.isOpen && !isDisabled && !loadingMessage,
            selectedItem: undefined,
          };

        default:
          return changes;
      }
    },
  });

  const [controlSizeRef, { width: controlWidth }] = useElementSize();

  const selectedItemRef = useRef<HTMLLIElement>(null);

  // Scroll selected item into view
  useEffect(() => {
    if (!isOpen) return undefined;

    // Timeout is needed, so fixed element can be calculated correctly
    const timeout = setTimeout(() => {
      if (!selectedItemRef.current) {
        return;
      }
      const scrollOptions = {
        block: 'center',
        scrollMode: 'if-needed',
      } as const;
      if ('scrollIntoViewIfNeeded' in selectedItemRef.current) {
        (selectedItemRef.current as any).scrollIntoViewIfNeeded(scrollOptions);
      } else {
        selectedItemRef.current?.scrollIntoView(scrollOptions);
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [isOpen]);

  const isSearchActive = !!search;

  const isCompact = variant === SelectVariants.compact;

  const hasSelectedItems = !!selectedItems.length;

  const selectedItemElement = (
    <span className={clsx('ellipsis', isOptimistic && 'text-muted')}>
      {!!loadingMessage && (
        <Typography
          variant={TypographyVariants.bodyMedium}
          className="text-soft"
        >
          {loadingMessage}
        </Typography>
      )}
      {(isCompact || !isSearchActive) &&
        joinJsxArray(
          selectedItems.map((selectedItem, index) => {
            return (
              <Typography
                {...{
                  variant: TypographyVariants.bodySmall,
                  key: `${getItemValue(selectedItem)}_${index}`,
                  ...getSelectedItemProps?.({
                    selectedItem,
                    index,
                  }),
                  tabIndex: -1,
                }}
              >
                {getItemText(selectedItem)}
              </Typography>
            );
          })
        )}
    </span>
  );

  // Blur is complicated, cause we use portal for items, we can't use default event,
  // so we handle it twice: with click and with keyboard
  const handleBlur = () => {
    closeMenu();
    setSearch('');
  };

  // Special case to not show focus state after optimistic update
  const [wasUpdated, setWasUpdated] = useState(false);

  // Set updated state after optimistic update, so we don't show focus state
  const prevIsOptimistic = usePrevious(isOptimistic);
  useEffect(() => {
    if (prevIsOptimistic === true && !isOptimistic) {
      setWasUpdated(true);
    }
  }, [prevIsOptimistic, isOptimistic]);

  const inputProps = getInputProps({
    className: isCompact ? styles.compactInput : styles.input,
    autoComplete: 'off',
    disabled: isDisabled,
    'aria-autocomplete': 'none',
    placeholder: hasSelectedItems || loadingMessage ? undefined : placeholder,
    ...getDropdownProps({
      ref: mergeRefs(ref, inputRef, internalInputRef),
      preventKeyAction: isOpen,
      onPaste: onPaste
        ? mergeHandlers(() => {
            closeMenu();
          }, onPaste)
        : undefined,
      onKeyDown: e => onKeyDown?.(e, { isOpen, openMenu, closeMenu }),
    }),
    onFocus: () => {
      // Remove updated state on next focus
      setWasUpdated(false);
    },
    onKeyDown: e => {
      onKeyDown?.(e, { isOpen, openMenu, closeMenu });

      if (loadingMessage) {
        e.preventDefault();
      }
      // if Tab is pressed blur the dropdown field
      if (e.key === 'Tab') {
        handleBlur();
      }
    },
  });

  const popoverId = `${inputProps.id}-popover-trigger`;

  const selectedItemColor = hasSelectedItems
    ? getItemColorVariant?.(selectedItems[0])
    : undefined;

  const shouldShowLoaderIcon = asyncProps?.isLoading && !filteredItems.length;

  const dropdownWidthPx =
    (popoverWidth || controlWidth) - NUMBER_TOKENS.borderWidth1 * 2;

  return (
    <div
      {...{
        className: clsx(styles.root, className),
        'data-popover-trigger': popoverId,
      }}
    >
      {!!label && theme !== SelectThemes.basic && (
        <Label
          {...{
            isRequired,
            ...getLabelProps(labelProps),
          }}
        >
          {label}
        </Label>
      )}
      <Typography
        {...{
          variant: TypographyVariants.bodySmall,
          tag: 'div',
          ref: controlSizeRef,
          className: clsx(styles[theme], styles[variant], {
            [styles.disabled]: isDisabled,
            [styles.error]: hasError,
            [styles.open]: isOpen,
            [styles.optimistic]: isOptimistic,
            [styles.wasUpdated]: wasUpdated,
          }),
          style: selectedItemColor
            ? ({
                '--select-optimistic-color': getColorCssVarName(
                  selectedItemColor,
                  ColorShades.opaqueContainerSoft
                ),
                '--select-default-color': getColorCssVarName(
                  selectedItemColor,
                  ColorShades.opaqueContainerDefault
                ),
                '--select-hover-color': getColorCssVarName(
                  selectedItemColor,
                  ColorShades.opaqueContainerHover
                ),
                '--select-active-color': getColorCssVarName(
                  selectedItemColor,
                  ColorShades.opaqueContainerActive
                ),
              } as React.CSSProperties)
            : {},
        }}
      >
        <input {...inputProps} />
        <div className={styles.content}>
          {selectedItemElement}
          <div className={styles.buttons}>
            {isClearable && hasSelectedItems && !isOpen && (
              <Icon
                {...{
                  variant: IconVariants.xClose,
                  onPress: () => {
                    setSearch('');
                    setSelectedItems([]);
                    selectItem(null);
                  },
                }}
              />
            )}
            {!loadingMessage && !shouldShowLoaderIcon && (
              <Icon
                {...{
                  // In compact mode toggle button is messing with correct blurring of the select after click
                  ...(isCompact ? {} : getToggleButtonProps()),
                  disabled: isDisabled,
                  variant: IconVariants.chevronDown,
                  rotate: isOpen ? RotateVariants.down : RotateVariants.up,
                }}
              />
            )}
            {(!!loadingMessage || shouldShowLoaderIcon) && <Loader />}
          </div>
        </div>
        <Popover
          {...{
            popoverId,
            isOpen,
            placement: 'bottom-start',
            onIsOpenChange: newIsOpen => {
              if (!newIsOpen) {
                handleBlur();
              }
            },
            renderContent: () => (
              <div
                {...{
                  className: styles.listWrapper,
                  // used for modal clickOutside behavior
                  'data-floating-select': true,
                }}
              >
                <AsyncList<I, false>
                  {...{
                    wrapperTag: 'ul',
                    withInnerRootRef: true,
                    className: clsx(
                      styles.list,
                      isCompact && styles.compactList,
                      isMulti && styles.multi
                    ),
                    style: {
                      width: dropdownWidthPx,
                    },
                    items: filteredItems,
                    noItemsMessage: (
                      <Typography
                        variant={TypographyVariants.bodySmall}
                        tag="li"
                        className={clsx('text-muted', styles.listItem)}
                      >
                        {isSearchActive ? noItemsFoundMessage : noItemsMessage}
                      </Typography>
                    ),
                    renderLoader: sentryRef => (
                      <li ref={sentryRef} className={styles.listItem}>
                        <Loader className={styles.loader} />
                      </li>
                    ),
                    renderItem: (item, index) => {
                      const itemValue = getItemValue(item);
                      const key = `${itemValue}_${index}`;

                      const isSelected = selectedItems.includes(item);
                      const isHighlighted = highlightedIndex === index;

                      const itemDescription = getItemDescription(item);

                      const renderItemContent = renderItemText ?? getItemText;

                      const itemColor = getItemColorVariant?.(item);

                      return (
                        <li
                          key={key}
                          {...getItemProps({
                            item,
                            ref:
                              isSelected && !isMulti
                                ? selectedItemRef
                                : undefined,
                            className: clsx(styles.interactiveListItem, {
                              [styles.selected]: isSelected,
                              [styles.highlighted]: isHighlighted,
                            }),
                          })}
                        >
                          {isMulti && (
                            <Checkbox
                              {...{
                                name: `${name}_checkbox`,
                                value: isSelected,
                                // Here select is used just for render, all logic is handled by the item itself
                                className: 'pointer-events-none',
                                tabIndex: -1,
                                withFormContext: false,
                              }}
                            />
                          )}

                          <Typography
                            {...{
                              variant: TypographyVariants.bodySmall,
                              tag: 'div',
                              className: isCompact
                                ? styles.listItemContentWrapper
                                : undefined,
                              style: {
                                background: itemColor
                                  ? getColorCssVarName(
                                      itemColor,
                                      ColorShades.opaqueContainerDefault
                                    )
                                  : undefined,
                              },
                            }}
                          >
                            {renderItemContent(item)}
                          </Typography>
                          {!!itemDescription && (
                            <div className="mt-4 text-muted">
                              {itemDescription}
                            </div>
                          )}
                        </li>
                      );
                    },
                    ...asyncProps,
                  }}
                />
                {!!listActionLabel && (
                  <div
                    {...{
                      className: styles.additionalListItem,
                      // Prevents downshift behavior which closes the list before click event is fired
                      onMouseDown: e => e.preventDefault(),
                      onMouseOver: () => setHighlightedIndex(-1),
                      onClick: () => {
                        onListActionPress?.();
                        closeMenu();
                      },
                    }}
                  >
                    {listActionLabel}
                  </div>
                )}
              </div>
            ),
          }}
        >
          <ul className={styles.listReference} {...getMenuProps()} />
        </Popover>
      </Typography>
      {variant === SelectVariants.withItemsList && hasSelectedItems && (
        <div className="grid gap-8 mt-4">
          {selectedItems.map((selectedItem, index) => {
            return (
              <div
                {...{
                  key: `${getItemValue(selectedItem)}_${index}`,
                  className: styles.selectedItem,
                }}
              >
                <Typography variant={TypographyVariants.bodySmall}>
                  {getItemText(selectedItem)}
                </Typography>

                <Icon
                  {...{
                    variant: IconVariants.xClose,
                    className: 'text-accent',
                    onPress: () => removeSelectedItem(selectedItem),
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      {!!feedback && <FieldFeedback content={feedback} {...feedbackProps} />}
    </div>
  );
};

export const Select = withOptionalFormController<
  SelectProps<any>,
  any,
  string | string[]
>({
  defaultValue: ({ isMulti }: SelectProps<any>) => (isMulti ? [] : ''),
  convertValueFromComponentToForm: (
    value,
    { isMulti, getItemValue = defaultGetItemValue }
  ) => {
    if (isMulti) {
      return value.map(getItemValue);
    }
    return value ? getItemValue(value) : undefined;
  },
  convertValueFromFormToComponent: (
    value,
    { items, getItemValue = defaultGetItemValue }
  ) => {
    const getValueForItem = (val: string) =>
      items.find(item => getItemValue(item) === val);

    if (Array.isArray(value)) {
      return value.map(getValueForItem).filter(Boolean);
    }
    return getValueForItem(value);
  },
})(
  React.forwardRef<HTMLInputElement, SelectProps<any>>(SelectInner)
) as RenderSelect;

export * from './helpers';
export * from './types';

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
export type CustomReportChartMultipleValuesFragment = { __typename: 'CustomReportChartMultipleValues', xAxisLabels: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: any | null } | null>, yAxisMultiValueDatasets: Array<Array<Array<{ __typename: 'CustomReportChartValue', value?: number | null }>>> };

export const CustomReportChartMultipleValuesFragmentDoc = gql`
    fragment CustomReportChartMultipleValues on CustomReportChartMultipleValues {
  __typename
  xAxisLabels {
    ...CustomReportBlueprintSourceFieldValue
  }
  yAxisMultiValueDatasets {
    __typename
    value
  }
}
    ${CustomReportBlueprintSourceFieldValueFragmentDoc}`;
import React, { useEffect, useState } from 'react';

import R from 'ramda';

import { mergeProps } from '~/shared/helpers/mergeProps';
import { ValueOf } from '~/shared/types/utility';

import { Modal } from '../../components';
import { ModalComponentProps } from '../../types';
import {
  InjectedStepperModalProps,
  StepperModalWrapperProps,
  WithoutInjectedStepperModalProps,
  WithStepperModalProps,
} from './types';

/**
 * Hoc to create a modal with steps
 */
export const withStepperModal =
  <
    Props extends InjectedStepperModalProps<
      Props,
      StepperModalState,
      ZeroStepWrapperProps
    >,
    ModalSteps extends string,
    ModalFormTypes extends Record<ModalSteps, any>,
    ModalSubmitTypes extends Record<ModalSteps, any>,
    StepperModalState,
    ZeroStepWrapperProps extends Record<string, any> = object,
  >({
    stepsDict,
    stepHooks,
    getDefaultStepperState,
    onNextStepSubmit,
  }: WithStepperModalProps<
    Props,
    ModalSteps,
    ModalFormTypes,
    ModalSubmitTypes,
    StepperModalState,
    ZeroStepWrapperProps
  >) =>
  (Component: React.ComponentType<Props>) => {
    const stepsList = Object.values<ModalSteps>(stepsDict);
    const stepsCount = stepsList.length;

    // Create a wrapper modal for rendering each step,
    // we're using stepNumber as a key for it to allow executing conditional form hooks inside
    const StepperModalWrapperComponent = (
      stepperModalWrapperProps: StepperModalWrapperProps<
        Props,
        StepperModalState,
        ZeroStepWrapperProps
      >
    ) => {
      const {
        modalProps: globalModalProps,

        stepNumber,
        goToPrevStep,

        children = R.identity,
      } = stepperModalWrapperProps;

      // This is conditional hook, so we're adding stepNumber to WrappedComponent key
      const useCurrentStep = stepHooks[stepsList[stepNumber - 1]];

      const {
        formId = '',
        formContext,
        isLoading = false,
        stepElement = null,
        modalProps: stepModalProps,
      } = useCurrentStep({
        ...stepperModalWrapperProps,
        handleNextStepSubmit: form => {
          onNextStepSubmit?.({
            submittedStep: stepsList[stepNumber],
            ...stepperModalWrapperProps,
            form: form as ValueOf<ModalSubmitTypes>,
          });
        },
      }) ?? {};

      return (
        <Modal
          {...mergeProps(
            {
              stepsCount,
              stepNumber,
              submitButtonProps: {
                form: formId,
                isLoading,
              },
              cancelButtonProps: {
                onPress: goToPrevStep,
              },
              isRequireExplicitClosing:
                formContext?.formState.isDirty || stepNumber > 1,
            } satisfies ModalComponentProps,
            globalModalProps,
            stepModalProps
          )}
        >
          {children(stepElement)}
        </Modal>
      );
    };

    // Create result component
    const WrappedComponent: React.FC<
      WithoutInjectedStepperModalProps<Props>
    > = props => {
      const { stepNumber } = props;

      const isFirstStep = stepNumber === 1;
      const isLastStep = stepNumber === stepsCount;

      const [stepperModalState, setStepperModalState] = useState(
        getDefaultStepperState(props)
      );

      // We're using conditional hooks rendering hack for more convenient code reuse
      // so we use key to recreate modal with different forms inside
      // and we should only play open animation, when we actually opened the modal
      const [isFirstRender, setFirstRender] = useState(true);
      useEffect(() => {
        setFirstRender(false);
      }, []);

      const stepperModalWrapperProps = {
        key: stepNumber,

        stepperModalState,
        setStepperModalState,
        modalProps: {
          withOpenAnimation: isFirstRender,
        },
        ...props,
        isFirstStep,
        isLastStep,
        stepsCount,
      };

      return (
        <Component
          {...{
            ...(props as Props),
            ...stepperModalWrapperProps,
            StepperModalWrapperComponent,
            stepperModalWrapperProps,
          }}
        />
      );
    };

    WrappedComponent.displayName = `withStepperModal(${
      Component.displayName || Component.name || ''
    })`;

    return WrappedComponent;
  };

export * from './types';

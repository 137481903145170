import R from 'ramda';

import { makeUseAsyncSelect } from '~/services/gql';

import { formatFullName } from '~/shared/helpers/nameFormat';

import { EmployeeFragmentDoc } from '../gql/fragments/employee.graphql';
import { useEmployeesQuery } from '../gql/queries/employees.graphql';

/**
 * Select for an employee
 */
export const useEmployeeSelect = makeUseAsyncSelect({
  typeName: 'Employee',
  fragment: EmployeeFragmentDoc,
  useQuery: useEmployeesQuery,
  getItemsFromQueryData: data => data.employees.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.employees.pageInfo,

  selectProps: {
    placeholder: 'Введите имя',
    getItemValue: item => item.id,
    getItemText: item => formatFullName(item),
  },
});

import React from 'react';

import { formatPhoneNumber } from '~/shared/helpers/phone';

import { Typography, TypographyVariants } from '../../components/Typography';

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Phone number string
   */
  phone?: string;
  /**
   * Typography variant, used to render link
   */
  typographyVariant?: TypographyVariants;
}

export const PhoneLink: React.FC<Props> = ({
  className,
  phone,

  typographyVariant = TypographyVariants.bodySmall,

  ...linkProps
}) => {
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <Typography
      {...{
        tag: 'a',
        variant: typographyVariant,
        className,
        href: `tel:${formattedPhone}`,
        ...linkProps,
      }}
    >
      {formattedPhone}
    </Typography>
  );
};

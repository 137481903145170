import React, { useState } from 'react';

import clsx from 'clsx';

import { useMyUser } from '~/services/auth';
import { useArkaNavigation } from '~/services/navigation';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Menu } from '~/shared/components/Menu';
import { Popover } from '~/shared/components/Popover';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

export const HeaderCompanySelector: React.FC = () => {
  const { navigate, selectedCompanyId, selectedCompany, selectCompany } =
    useArkaNavigation();
  const { myUser } = useMyUser();

  const hasSeveralCompanies = myUser.companies.edges.length > 1;

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const myUserCompanyItems = myUser.companies.edges.map(
    ({ node: company }) => ({
      key: company.id,
      content: company.name,
      hasCheck: selectedCompanyId === company.id,
    })
  );

  return (
    <Popover
      {...{
        placement: 'bottom-start',
        shouldCloseOnContentClick: true,
        withListNavigation: true,
        listNavigationProps: {
          activeIndex,
          onNavigate: setActiveIndex,
        },
        renderContent: ({ listRef, getItemProps }) => (
          <Menu<(typeof myUserCompanyItems)[number]>
            {...{
              className: styles.menu,
              items: myUserCompanyItems,
              onItemPress: item => {
                if (item.key) {
                  selectCompany(item.key);
                  setIsOpen(false);

                  // TODO remove after we completely switch to Apollo, now old http service can't refresh queries
                  navigate(0);
                }
              },
              activeIndex,
              getItemProps: (index, userProps) => ({
                ref: node => {
                  listRef.current[index] = node;
                },
                ...getItemProps(userProps),
              }),
              withDelimiters: true,
            }}
          />
        ),
        isDisabled: !hasSeveralCompanies,
        isOpen,
        onIsOpenChange: setIsOpen,
      }}
    >
      <Typography
        {...{
          variant: TypographyVariants.bodySmall,
          className: clsx(
            styles.root,
            !isOpen && 'text-soft',
            hasSeveralCompanies && 'cursor-pointer'
          ),
          tag: 'div',
        }}
      >
        <span>{selectedCompany?.name}</span>
        {hasSeveralCompanies && (
          <Icon
            {...{
              className: clsx('ml-4', styles.icon),
              variant: IconVariants.chevronDown,
              rotate: isOpen ? RotateVariants.down : RotateVariants.up,
              size: SizeVariants.size16,
            }}
          />
        )}
      </Typography>
    </Popover>
  );
};

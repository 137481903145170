import React, { ReactNode, useMemo } from 'react';

import { useDropDown } from '~/~legacy/hooks/useDropDown';
import { ListItem } from '~/~legacy/types/keyValue';

import { MControlProps } from '~/shared/types/controls';

import { MPanel } from '../MPanel';

import './MDropDown.scss';

export interface MDropDownProps<T> extends MControlProps {
  items: ListItem<T>[];

  selectedValue?: T;
  isOpened?: boolean;

  noBorder?: boolean;
  noReset?: boolean;

  onChange: (value: any) => void;

  getSelectedContend?: (item: T) => ReactNode;
}

export const MDropDown = React.memo(
  React.forwardRef<any, MDropDownProps<any>>((props, ref) => {
    const {
      items,
      isDisabled,
      selectedValue,
      isOpened,
      className,
      placeholder,
      onChange,
      noBorder,
      onBlur,
      onFocus,
      noReset,
      onPaste,
      onKeyDown,
    } = props;

    const {
      opened,
      toggle,
      overlay,
      IconSVG,
      elementRef,
      keyDown,
      selectedElementRef,
      clearIcon,
      internalSelected,
    } = useDropDown({
      isOpened,
      isDisabled,
      onFocus,
      onBlur,
      ref,
      onChange,
      listItems: items,
      selectedValue,
      noReset,
      onKeyDown,
    });

    const itemsToRender = useMemo(() => {
      return items.map((item, i) => {
        const selected =
          item.value === selectedValue || item.value === internalSelected;

        return (
          <li
            ref={selected ? selectedElementRef : undefined}
            // eslint-disable-next-line react/no-array-index-key
            key={item.value + item.content + i}
            onClick={() => {
              onChange(item.value);
              toggle();
            }}
            className={`m-dropdown-list-item ${selected ? 'selected' : ''}`}
          >
            {item.content}
          </li>
        );
      });
    }, [items, selectedValue, onChange, internalSelected]);

    const emptyElement = <>&nbsp;</>;
    const selectedItem = items.find(item => item.value === selectedValue);
    const selectedElement =
      selectedItem?.content || placeholder || emptyElement;

    return (
      <>
        {overlay}
        <div
          tabIndex={0}
          onPaste={onPaste}
          className={`m-dropdown ${isDisabled ? 'disabled' : ''} m-control ${
            className || ''
          } ${noBorder ? 'no-border' : ''}`}
          onKeyDown={keyDown}
          ref={elementRef}
        >
          <div className={`m-dropdown-content  `} onClick={toggle}>
            <div>{selectedElement}</div>
            <div className="m-dropdown-content-toggle">
              {clearIcon}
              <IconSVG />
            </div>
          </div>

          <MPanel
            elevation
            tag="ul"
            className={`m-dropdown-list ${opened ? 'opened' : ''}`}
          >
            {itemsToRender}
          </MPanel>
        </div>
      </>
    );
  })
);

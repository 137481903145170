import React from 'react';

import R from 'ramda';

import { useMyUser } from '~/services/auth';
import { useArkaNavigation } from '~/services/navigation';

import { PartialSelectPropsWithName, Select } from '~/shared/components/Select';

import { FarmFragment } from '../../gql/fragments/farm.graphql';

export const FarmIdSelect: React.FC<
  PartialSelectPropsWithName<FarmFragment>
> = props => {
  const { myUser } = useMyUser();
  const { selectedCompanyId } = useArkaNavigation();

  const userFarms = myUser.farms.filter(
    farm => farm.company.id === selectedCompanyId
  );

  return (
    <Select<FarmFragment>
      {...{
        items: userFarms,
        getItemDescription: R.propOr('', 'id'),
        label: 'Номер фермы',
        placeholder: 'Выберите номер',
        ...props,
      }}
    />
  );
};

import * as Types from '../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBullMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  migrateToBullID?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DeleteBullMutation = { __typename?: 'Mutation', deleteBull?: any | null };


export const DeleteBullDocument = gql`
    mutation deleteBull($id: ID!, $migrateToBullID: ID) {
  deleteBull(id: $id, migrateToBullID: $migrateToBullID)
}
    `;
export type DeleteBullMutationFn = Apollo.MutationFunction<DeleteBullMutation, DeleteBullMutationVariables>;

/**
 * __useDeleteBullMutation__
 *
 * To run a mutation, you first call `useDeleteBullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBullMutation, { data, loading, error }] = useDeleteBullMutation({
 *   variables: {
 *      id: // value for 'id'
 *      migrateToBullID: // value for 'migrateToBullID'
 *   },
 * });
 */
export function useDeleteBullMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBullMutation, DeleteBullMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBullMutation, DeleteBullMutationVariables>(DeleteBullDocument, options);
      }
export type DeleteBullMutationHookResult = ReturnType<typeof useDeleteBullMutation>;
export type DeleteBullMutationResult = Apollo.MutationResult<DeleteBullMutation>;
export type DeleteBullMutationOptions = Apollo.BaseMutationOptions<DeleteBullMutation, DeleteBullMutationVariables>;
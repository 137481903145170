import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportSettingsValueFragmentDoc } from './customReportSettingsValue.graphql';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from './customReportBlueprintSourceFieldValue.graphql';
export type CustomReportChartYAxis_CustomReportChartAggValueAxis_Fragment = { __typename: 'CustomReportChartAggValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string } } };

export type CustomReportChartYAxis_CustomReportChartFieldValueAxis_Fragment = { __typename: 'CustomReportChartFieldValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, blueprintSourceFieldValue: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: any | null } };

export type CustomReportChartYAxisFragment = CustomReportChartYAxis_CustomReportChartAggValueAxis_Fragment | CustomReportChartYAxis_CustomReportChartFieldValueAxis_Fragment;

export const CustomReportChartYAxisFragmentDoc = gql`
    fragment CustomReportChartYAxis on CustomReportChartYAxis {
  __typename
  ... on CustomReportChartAggValueAxis {
    aggFormula
    withRightScale
    valueKey {
      ...CustomReportSettingsValue
    }
  }
  ... on CustomReportChartFieldValueAxis {
    aggFormula
    withRightScale
    blueprintSourceFieldValue {
      ...CustomReportBlueprintSourceFieldValue
    }
  }
}
    ${CustomReportSettingsValueFragmentDoc}
${CustomReportBlueprintSourceFieldValueFragmentDoc}`;
import React, { useMemo } from 'react';

import {
  BarElement,
  CategoryScale,
  Chart,
  ChartOptions,
  ChartType,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  Tooltip,
} from 'chart.js';

import { Skeleton } from '~/shared/components/Skeleton';

import {
  BARS_BORDER_RADIUS_PX,
  BARS_CHART_OPTIONS,
  PERCENT_BARS_CHART_OPTIONS,
} from '../../constants';
import { getChartOptions } from '../../helpers';
import {
  ReactChart,
  ReactChartDatasetConfig,
  ReactChartProps,
} from '../ReactChart';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const BAR_CHART_DATASETS_OPTIONS = {
  datasets: {
    bar: { borderRadius: BARS_BORDER_RADIUS_PX },
    line: { pointStyle: false },
  },
} satisfies ChartOptions;

/**
 * Data item for the bar chart
 */
export type BarChartDataPoint =
  | {
      x: string | undefined;
      y: number;
    }
  | number;

interface Props
  extends Omit<
    ReactChartProps<ChartType, BarChartDataPoint[]>,
    'type' | 'data' | 'datasetConfigs' | 'skeleton'
  > {
  /**
   * Chart data
   */
  datasets: ReactChartDatasetConfig<ChartType, BarChartDataPoint[]>[];
  /**
   * Labels, used in the chart x axis
   */
  labels?: string[];
  /**
   * If true, bars are stacked on both axes
   */
  isStacked?: boolean;
  /**
   * If true, bars are stacked on x axis
   */
  isStackedX?: boolean;
  /**
   * If true, bars are stacked on y axis
   */
  isStackedY?: boolean;
  /**
   * If true, y values a drawn with percent formatting
   */
  isPercents?: boolean;
  /**
   * Additional options for the chart
   */
  chartOptions?: ChartOptions;
}

export const BarChart: React.FC<Props> = ({
  datasets,
  isStacked = false,
  isStackedX = isStacked,
  isStackedY = isStacked,
  isPercents = false,
  labels,
  chartOptions: chartOptionsProp,

  ...chartProps
}) => {
  const chartOptions = getChartOptions<ChartType>(
    isPercents ? PERCENT_BARS_CHART_OPTIONS : BARS_CHART_OPTIONS,
    {
      scales: {
        x: {
          stacked: isStackedX,
        },
        y: {
          stacked: isStackedY,
        },
      },
    },
    BAR_CHART_DATASETS_OPTIONS,
    chartOptionsProp
  );

  const datasetConfigs = useMemo<
    ReactChartDatasetConfig<ChartType, BarChartDataPoint[]>[]
  >(
    () =>
      datasets.map((dataset, index) => ({
        ...dataset,
        borderColor: dataset.color,
        backgroundColor: dataset.color,
        order: dataset.order ?? index,
      })),
    [datasets, labels]
  );

  return (
    <ReactChart
      {...{
        options: chartOptions,
        type: 'bar',
        datasetIdKey: 'first',
        labels,
        datasetConfigs,
        skeleton: <Skeleton.BarChart />,
        ...chartProps,
      }}
    />
  );
};

import React, { useMemo, useRef } from 'react';

import { QueryHookOptions } from '@apollo/client';

import R from 'ramda';

import {
  PartialSelectProps,
  PartialSelectPropsWithName,
  Select,
} from '~/shared/components/Select';
import { defaultGetItemValue } from '~/shared/helpers/itemProps';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { usePrevious } from '~/shared/hooks/usePrevious';

import {
  AnyFragment,
  DefaultIdInput,
  SearchablePaginatedQueryVariables,
} from '../../types';
import { MakeGetFragmentCacheOptionsProps, makeReadFragment } from '../cache';
import {
  makeUsePaginatedQuery,
  MakeUsePaginatedQueryProps,
} from './makeUsePaginatedQuery';

/**
 * Props for fabric for making hooks for using async selects.
 */
type MakeUseAsyncSelectProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends SearchablePaginatedQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
> = MakeUsePaginatedQueryProps<Fragment, QueryData, QueryVariables> &
  MakeGetFragmentCacheOptionsProps & {
    /**
     * Default props for the select
     */
    selectProps?: PartialSelectProps<Fragment>;
    /**
     * Getter for an item key to search in the cache, default to item id field
     */
    getItemKey?: (item: Fragment) => string;
    /**
     * Getter for an input, that is send with ids filter, default is just item.id
     */
    getItemIdQueryInput?: (itemKey: string) => IdInput | undefined;
  };

/**
 * Props for hook for async dropdown, allows to override some props, passed to hook fabric
 */
export interface UseAsyncSelectProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends SearchablePaginatedQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
> {
  /**
   * Usual select props
   */
  selectProps: PartialSelectPropsWithName<Fragment>;
  /**
   * Additional props for the Apollo hook
   */
  queryOptions?: QueryHookOptions<QueryData, QueryVariables>;
  /**
   * Called, when select has loaded some items for the first time
   */
  onFirstLoad?: (items: Fragment[]) => void;
}

/**
 * Fabric to make a hook for using async dropdown.
 * It returns query result and jsx dropdown element, so they can be used separately
 */
export const makeUseAsyncSelect = <
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends SearchablePaginatedQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
>({
  typeName,
  fragment,
  fragmentName,
  getIdentifyFields,

  selectProps,

  getItemKey = defaultGetItemValue,
  getItemIdQueryInput = defaultGetItemValue as any,

  ...usePaginatedQueryProps
}: MakeUseAsyncSelectProps<Fragment, QueryData, QueryVariables, IdInput>) => {
  const usePaginatedQuery = makeUsePaginatedQuery(usePaginatedQueryProps);

  const readFragment = makeReadFragment<Fragment>({
    typeName,
    fragment,
    fragmentName,
    getIdentifyFields,
  });

  return ({
    selectProps: innerSelectProps,
    queryOptions,
    onFirstLoad,
  }: UseAsyncSelectProps<Fragment, QueryData, QueryVariables, IdInput>) => {
    const { search, setSearch, debouncedSearch } =
      useDebouncedSearch(selectProps);

    const isItemsLoadedRef = useRef(false);

    // Get select value to check for unloaded entities
    const selectedIds =
      // TODO think of some way to ensure, we always pass rawValue from form watcher,
      // cause not passing it may cause selected item disappearing from loaded items
      (normalizeToArrayOrUndefined(innerSelectProps.rawValue) ?? []).map(
        String
      );

    const isSearchActive = !!debouncedSearch;
    const isNewItemsPending = debouncedSearch !== search;

    const mainQueryResult = usePaginatedQuery({
      ...queryOptions,
      variables: {
        ...queryOptions?.variables,
        search: debouncedSearch,
      } as QueryVariables,
      onCompleted: queryData => {
        if (!onFirstLoad || isItemsLoadedRef.current) return;

        const loadedItems =
          usePaginatedQueryProps.getItemsFromQueryData(queryData);
        if (!loadedItems.length) return;

        isItemsLoadedRef.current = true;
        onFirstLoad(loadedItems);
      },
      notifyOnNetworkStatusChange: true,
    });

    const {
      isLoading: isPaginatedQueryLoading,
      isFetchingMore,
      hasMore,
      fetchMore,
      client,
      items: mainQueryItems,
    } = mainQueryResult;

    const selectedIdsHash = selectedIds.join('__');

    // Check, if all selected items are in cache and should we query additional items
    const { currentSelectedItems, additionalIdsToRequest } = useMemo(() => {
      const itemFragments = selectedIds.map(id => ({
        id,
        fragment: readFragment(client, id),
      }));

      return {
        currentSelectedItems: itemFragments
          .map(R.prop('fragment'))
          .filter(Boolean),
        additionalIdsToRequest: itemFragments
          .filter(item => !item.fragment)
          .map(item => getItemIdQueryInput(item.id))
          .filter(Boolean),
      };
    }, [selectedIdsHash, mainQueryItems]);

    // Selected items can be outside of the pagination range of the main query, so we should make an additional query for them
    const { loading: isAdditionalItemsLoading, items: additionalQueryItems } =
      usePaginatedQuery({
        ...queryOptions,
        variables: {
          ...queryOptions?.variables,
          ids: additionalIdsToRequest,
        } as QueryVariables,
        skip:
          !additionalIdsToRequest.length ||
          isPaginatedQueryLoading ||
          isSearchActive,
      });

    // Combine main items with additional items and current selected items if they are not in the paginated list.
    let items = useMemo(() => {
      return R.uniqBy(getItemKey, [
        ...currentSelectedItems,
        ...additionalQueryItems,
        ...mainQueryItems,
      ]);
    }, [
      selectedIdsHash,
      mainQueryItems,
      additionalQueryItems,
      currentSelectedItems,
    ]);

    const isLoading = isPaginatedQueryLoading || isAdditionalItemsLoading;

    // Use previous items to display frontend search results, while backend results are loading
    const prevItems = usePrevious(items);
    if (prevItems && isLoading && !items.length) {
      items = prevItems;
    }

    return {
      ...mainQueryResult,
      items,
      renderSelectElement: (
        renderSelectProps?: PartialSelectProps<Fragment>
      ) => (
        <Select
          {...mergeProps(
            {
              items,
              getItemValue: getItemKey,
              isItemMatchingSearch: isNewItemsPending
                ? // If items is still loading, just use default search
                  undefined
                : // If we search items by query, we should only show main query items,
                  // cause others are needed only as selected items, but not in the current search
                  item =>
                    !isSearchActive ||
                    !!mainQueryItems.find(
                      mainQueryItem =>
                        getItemKey(item) === getItemKey(mainQueryItem)
                    ),
              search,
              onSearchChange: setSearch,
              asyncProps: {
                isLoading: isLoading || isNewItemsPending,
                isFetchingMore,
                hasMore: hasMore && !isNewItemsPending,
                onFetchMore: () => {
                  if (!isNewItemsPending) {
                    fetchMore();
                  }
                },
              },
            } satisfies PartialSelectProps<Fragment>,
            selectProps,
            innerSelectProps,
            renderSelectProps
          )}
        />
      ),
    };
  };
};

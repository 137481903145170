import { ApolloCache, DocumentNode } from '@apollo/client';

import { StrictTypedTypePolicies } from '../../__generated__/typedTypePolicies';
import { getFragmentDocumentName } from './getFragmentDocumentName';

/**
 * Props for fabric to make helpers for identifying fragments in the cache
 */
export interface MakeGetFragmentCacheOptionsProps {
  /**
   * Typename of the selectable fragment in the cache
   * You can pass a function to determine a typename by id,
   * We might use interface as a typeName, so you should calculate fragmentName based on globalId
   */
  typeName: keyof StrictTypedTypePolicies | ((id: string) => string);
  /**
   * Gql document of the selectable fragment
   */
  fragment: DocumentNode;
  /**
   * Selectable fragment name, in case it's different from its typeName
   */
  fragmentName?: string | ((id: string) => string);
  /**
   * Some fragments doesn't have an id, so we need to pass a function to determine identify fields
   */
  getIdentifyFields?: (id: string) => Record<string, any>;
}

/**
 * Factory for making an options object used by apollo cache functions to identify fragments
 */
export const makeGetFragmentCacheOptions =
  ({
    typeName: typeNameProp,
    fragment,
    fragmentName: fragmentNameProp = typeof typeNameProp === 'function'
      ? typeNameProp
      : getFragmentDocumentName(fragment) ?? typeNameProp,
    getIdentifyFields = id => ({ id }),
  }: MakeGetFragmentCacheOptionsProps) =>
  (cache: ApolloCache<unknown>, id: string) => {
    const identifyFields = getIdentifyFields(id);

    const typeName =
      typeof typeNameProp === 'function' ? typeNameProp(id) : typeNameProp;

    const normalizedId =
      cache.identify({ __typename: typeName, ...identifyFields }) ??
      `${typeName}:${id}`;

    const fragmentName =
      typeof fragmentNameProp === 'function'
        ? fragmentNameProp(id)
        : fragmentNameProp;

    return {
      fragment,
      fragmentName,
      id: normalizedId,
    };
  };

import React from 'react';

import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Input, InputVariants } from '~/shared/components/Input';

import formStyles from '~/styles/modules/form.module.scss';

import { EditInjectionFormType } from '../../hooks/useInjectionForm';

interface Props
  extends Pick<UseModalStepFormInterface<EditInjectionFormType>, 'formProps'> {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditInjectionForm: React.FC<Props> = ({
  className,
  formProps,
}) => {
  return (
    <Form
      {...{
        className: clsx(formStyles.singleColumnForm, className),
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'name',
          label: 'Название инъекции',
          placeholder: 'Например, От Бурсита',
          isRequired: true,
        }}
      />
      <Input
        {...{
          name: 'daysMilkWithholdAfterLastThreatment',
          variant: InputVariants.int,
          label: 'Количество дней до выхода из молока',
          placeholder: 'Введите количество дней',
          isRequired: true,
        }}
      />
      <Input
        {...{
          name: 'daysMeatWithholdAfterLastThreatment',
          variant: InputVariants.int,
          label: 'Количество дней до выхода из мяса',
          placeholder: 'Введите количество дней',
          isRequired: true,
        }}
      />
      <Input
        {...{
          name: 'cost',
          variant: InputVariants.money,
          label: 'Стоимость инъекции, ₽',
          placeholder: 'Введите стоимость в рублях',
        }}
      />
    </Form>
  );
};

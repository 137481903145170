import React, { ElementType } from 'react';

import clsx from 'clsx';

import {
  Skeleton,
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';

import { TypographyVariants } from '~/styles/__generated__/token-variants';
import typographyStyles from '~/styles/__generated__/typography.module.scss';

import { SkeletonTextProps } from '../Skeleton/components/SkeletonText';
import styles from './index.module.scss';

const TYPOGRAPHY_VARIANT_TO_TAG_DICT: Record<TypographyVariants, ElementType> =
  {
    [TypographyVariants.bodyLarge]: 'span',
    [TypographyVariants.bodyLargeStrong]: 'b',
    [TypographyVariants.bodyMedium]: 'span',
    [TypographyVariants.bodyMediumStrong]: 'b',
    [TypographyVariants.bodySmall]: 'span',
    [TypographyVariants.bodySmallStrong]: 'b',
    [TypographyVariants.descriptionLarge]: 'span',
    [TypographyVariants.descriptionLargeStrong]: 'b',
    [TypographyVariants.descriptionMedium]: 'span',
    [TypographyVariants.descriptionMediumStrong]: 'b',
    [TypographyVariants.descriptionSmall]: 'span',
    [TypographyVariants.descriptionSmallStrong]: 'span',
    [TypographyVariants.displayLarge]: 'span',
    [TypographyVariants.displayLargeStrong]: 'b',
    [TypographyVariants.displayMedium]: 'span',
    [TypographyVariants.displayMediumStrong]: 'b',
    [TypographyVariants.displaySmall]: 'span',
    [TypographyVariants.displaySmallStrong]: 'b',
    [TypographyVariants.heading1]: 'h1',
    [TypographyVariants.heading2]: 'h2',
    [TypographyVariants.heading3]: 'h3',
    [TypographyVariants.heading4]: 'h4',
    [TypographyVariants.heading5]: 'b',
  };

export interface TypographyProps extends Omit<React.HTMLProps<any>, 'ref'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Variant of the typography
   */
  variant: TypographyVariants;
  /**
   * Tag name to render (default depends on variant)
   */
  tag?: ElementType;
  /**
   * If true text will be trimmed
   */
  trim?: boolean;
  /**
   * If true, skeleton is showed, when isLoading is true, if false - nothing rendered (default - true)
   */
  withSkeleton?: boolean;
  /**
   * If true, skeleton is not rendered, the text is always shown
   */
  isStaticContent?: boolean;
  /**
   * Size of the skeleton for the loading state
   */
  skeletonSize?: TextSkeletonSizes;
  /**
   * Additional props for skeleton
   */
  skeletonProps?: Partial<SkeletonTextProps>;
}

export const Typography = React.forwardRef<unknown, TypographyProps>(
  (
    {
      className,

      variant,
      tag: Tag = TYPOGRAPHY_VARIANT_TO_TAG_DICT[variant],

      trim = false,
      withSkeleton = true,
      isStaticContent = false,
      skeletonSize,
      skeletonProps,

      children,

      ...other
    },
    ref
  ) => {
    const { renderWithSkeleton } = useSkeletonContext();

    return renderWithSkeleton(
      withSkeleton ? (
        <Skeleton.Text
          {...{
            className,
            typographyVariant: variant,
            size: skeletonSize,
            ...skeletonProps,
          }}
        />
      ) : null,
      <Tag
        ref={ref}
        className={clsx(
          typographyStyles[variant],
          className,
          trim && [styles.trim, 'ellipsis']
        )}
        {...other}
      >
        {children}
      </Tag>,
      isStaticContent
    );
  }
);

export { TypographyVariants } from '~/styles/__generated__/token-variants';

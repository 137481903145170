import React from 'react';

import { VitalityFilter } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';
import * as yup from 'yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { SelectVariants } from '~/shared/components/Select';

import {
  formatCow,
  useCowSelect,
  useHandlePasteCowIdentifiers,
} from '~/entities/cows';

import formStyles from '~/styles/modules/form.module.scss';

export interface AddCowIdsToListModalProps
  extends InjectedModalProps<AddCowIdsToListModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * onSubmit handler for passing cow id list
   */
  onSubmit: (cowsIds: string[]) => void;
  /**
   * Default selected cows
   */
  defaultValues?: string[];
}

const MAX_TOOLTIP_WIDTH = 264;

const FORM_ID = 'AddCowIdsToListForm';

const SCHEMA = yup.object({
  cowIDs: yup.array(yup.string().required()).default([]),
});

type AddCowsToListFormType = InferValidatedSchema<typeof SCHEMA>;

export const AddCowIdsToListModal: React.FC<AddCowIdsToListModalProps> = ({
  className,
  onSubmit,
  close,
  defaultValues = [],
}) => {
  const formContext = useForm<AddCowsToListFormType>({
    schema: SCHEMA,
    defaultValues: { ...SCHEMA.getDefault(), cowIDs: defaultValues },
  });

  const cowIDs = formContext.watch('cowIDs');

  const { handlePasteCowIdentifiers } = useHandlePasteCowIdentifiers({
    onCowsLoaded: data => {
      const uniqueCows = R.uniq([
        ...(data?.cows.edges.map(({ node }) => node.id) ?? []),
        ...cowIDs,
      ]);

      formContext.setValue('cowIDs', uniqueCows);
    },
  });

  const { renderSelectElement: renderCowSelectElement } = useCowSelect({
    queryOptions: {
      variables: {
        vitalityFilter: VitalityFilter.Alive,
      },
    },
    selectProps: {
      label: 'Номер животного',
      labelProps: {
        tooltipProps: {
          maxWidth: MAX_TOOLTIP_WIDTH,
        },
        tooltip:
          'Доступен массовый ввод — скопируйте список номеров животных и вставьте в поле для ввода',
      },
      isMulti: true,
      variant: SelectVariants.withItemsList,
      rawValue: cowIDs,
      name: 'cowIDs',
      getItemText: item => {
        if (!item) return '';

        return formatCow(item, { prefix: '' });
      },
      onPaste: handlePasteCowIdentifiers,
    },
  });

  const handleSubmit = (form: AddCowsToListFormType) => {
    onSubmit(form.cowIDs);
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Добавление животных в список',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Добавить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: clsx(formStyles.singleColumnForm, 'gap-24'),
        }}
      >
        {renderCowSelectElement()}
      </Form>
    </Modal>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CompanyShortFragmentDoc } from '../fragments/companyShort.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompaniesShortQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type CompaniesShortQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', pageInfo: { __typename?: 'BeriaPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }> } };


export const CompaniesShortDocument = gql`
    query companiesShort($ids: [ID!]) {
  companies(ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...CompanyShort
    }
  }
}
    ${CompanyShortFragmentDoc}`;

/**
 * __useCompaniesShortQuery__
 *
 * To run a query within a React component, call `useCompaniesShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesShortQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompaniesShortQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesShortQuery, CompaniesShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesShortQuery, CompaniesShortQueryVariables>(CompaniesShortDocument, options);
      }
export function useCompaniesShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesShortQuery, CompaniesShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesShortQuery, CompaniesShortQueryVariables>(CompaniesShortDocument, options);
        }
export function useCompaniesShortSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesShortQuery, CompaniesShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesShortQuery, CompaniesShortQueryVariables>(CompaniesShortDocument, options);
        }
export type CompaniesShortQueryHookResult = ReturnType<typeof useCompaniesShortQuery>;
export type CompaniesShortLazyQueryHookResult = ReturnType<typeof useCompaniesShortLazyQuery>;
export type CompaniesShortSuspenseQueryHookResult = ReturnType<typeof useCompaniesShortSuspenseQuery>;
export type CompaniesShortQueryResult = Apollo.QueryResult<CompaniesShortQuery, CompaniesShortQueryVariables>;
import React from 'react';

import { usePress } from '@react-aria/interactions';
import clsx from 'clsx';
import R from 'ramda';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { MenuItemType } from '../../types';
import styles from './index.module.scss';

interface Props<ItemType extends MenuItemType = MenuItemType> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Item to render
   */
  item: ItemType;
  /**
   * Called, when an item is pressed
   */
  onPress?: (item: ItemType) => void;
  /**
   * If true, the item is active by arrow navigation
   */
  isActive?: boolean;
  /**
   * Additional props getter for implementing navigation
   */
  getItemProps?: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => React.ComponentPropsWithoutRef<'li'>;
}

export const MenuItem = <ItemType extends MenuItemType = MenuItemType>({
  className,
  item,
  onPress,
  isActive,
  getItemProps = R.identity as any,
}: Props<ItemType>) => {
  const { pressProps } = usePress({
    onPress: () => {
      onPress?.(item);
      item.onPress?.();
    },
  });
  return (
    <Typography
      {...{
        variant: TypographyVariants.bodySmall,
        className: clsx(styles.root, className, isActive && styles.active),
        tag: 'li',
        tabIndex: isActive ? 0 : -1,
        ...getItemProps(pressProps),
      }}
    >
      <div className="ellipsis">{item.content}</div>
      {item.hasCheck && (
        <Icon
          {...{
            className: 'text-muted',
            variant: IconVariants.check,
          }}
        />
      )}
    </Typography>
  );
};

import { useTranslation } from 'react-i18next';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { SPACED_INTERPUNCT } from '~/shared/constants';

import { MilkingParlorShortFragment } from '~/entities/milkingParlors/gql/fragments/milkingParlorShort.graphql';

import { MILKING_PARLOR_MANUFACTURERS_DICT } from '../constants';

/**
 * Hook for getting milking parlor info string
 */
export const useGetMilkingParlorInfo = () => {
  const { t } = useTranslation(MaslovNamespaces.enums);

  return (
    milkingParlor?: MilkingParlorShortFragment,
    withName: boolean = false
  ) => {
    if (!milkingParlor) return '';

    return [
      withName && milkingParlor.name,
      t(`${EnumStrings.milkingParlorKind}${milkingParlor.kind}`),
      `${milkingParlor.capacity} мест`,
      MILKING_PARLOR_MANUFACTURERS_DICT[milkingParlor.manufacturer],
    ]
      .filter(Boolean)
      .join(SPACED_INTERPUNCT);
  };
};

import React, { useCallback, useMemo } from 'react';

import { MetricFilters } from '~/~legacy/services/AnalyticsService';
import { ListItem } from '~/~legacy/types/keyValue';

import {
  DateRangePicker,
  DateRangePickerThemes,
} from '~/shared/components/DateRangePicker';
import { MDropDown } from '~/shared/components/MDropDown';
import { AnalyticsGroupingPeriodEnum } from '~/shared/graphql';
import { DateRange } from '~/shared/helpers/date';

import { FarmFragment } from '~/entities/farms/gql/fragments/farm.graphql';

import { getPeriodItems } from '../../helpers';
import styles from './index.module.scss';

interface Props {
  filters: MetricFilters;
  farms: FarmFragment[];

  onCahnge: (filters: MetricFilters) => void;
}

export const MetricPageFilters: React.FC<Props> = ({
  filters,
  farms,
  onCahnge,
}) => {
  const periodItems = useMemo(() => {
    return getPeriodItems();
  }, []);

  const farmItems = useMemo(() => {
    const items: ListItem<string>[] = farms.map(farm => {
      return {
        value: farm.id,
        content: farm.name,
      };
    });

    const allFarmsItem: ListItem<string | undefined> = {
      value: undefined,
      content: 'Все фермы',
    };

    return [allFarmsItem, ...items];
  }, [farms]);

  const periodChangeCallback = useCallback(
    (groupingPeriod: AnalyticsGroupingPeriodEnum) => {
      onCahnge({
        ...filters,
        groupingPeriod,
      });
    },
    [filters, onCahnge]
  );

  const farmChangeCallback = useCallback(
    (farmId: string) => {
      onCahnge({
        ...filters,
        farmId,
      });
    },
    [filters, onCahnge]
  );

  const datesRangePeriod: DateRange = {
    since: filters.since || '',
    till: filters.till || '',
  };

  const datesRangeChangedCallback = useCallback(
    (newPeriod: DateRange) => {
      onCahnge({
        ...filters,
        since: newPeriod.since,
        till: newPeriod.till,
      });
    },
    [filters, onCahnge]
  );

  return (
    <table className={styles.filters}>
      <thead>
        <tr>
          <th>Отображение по</th>
          <th>Ферма</th>
          <th>Период</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <MDropDown
              name="groupingPeriod"
              items={periodItems}
              selectedValue={filters.groupingPeriod}
              onChange={periodChangeCallback}
            />
          </td>
          <td>
            <MDropDown
              name="farmId"
              items={farmItems}
              selectedValue={filters.farmId}
              onChange={farmChangeCallback}
            />
          </td>
          <td>
            <DateRangePicker
              name="period"
              value={datesRangePeriod}
              onValueChange={datesRangeChangedCallback}
              theme={DateRangePickerThemes.light}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

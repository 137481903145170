import React, { useEffect } from 'react';

import { useArkaNavigation } from '~/services/navigation';

import { MPageContainer } from '~/shared/components/MPageContainer';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { useCompaniesShortQuery } from '../../gql/queries/companiesShort.graphql';

const COMPANY_CHECK_INTERVAL_MS = 5 * 1000;

export const CompanyLoadingBlocker: React.FC = () => {
  const { selectedCompanyId } = useArkaNavigation();

  const { refetch } = useCompaniesShortQuery({
    variables: { ids: normalizeToArrayOrUndefined(selectedCompanyId) },
    skip: !selectedCompanyId,
  });

  useEffect(() => {
    const intervalId = setInterval(refetch, COMPANY_CHECK_INTERVAL_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <MPageContainer centerContent>
      <Typography variant={TypographyVariants.heading4}>
        Идёт загрузка файлов.
      </Typography>
      <Typography variant={TypographyVariants.heading4}>
        Интерфейс будет заблокирован, пока загрузка не завершится.
      </Typography>
    </MPageContainer>
  );
};

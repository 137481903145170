import React from 'react';
import { NavLink } from 'react-router-dom';

import { ListItem } from '~/~legacy/types/keyValue';

import layoutStyles from '~/styles/modules/layout.module.scss';

import './PageNavigation.scss';

interface Props {
  items: ListItem<string>[];
}

export const PageNavigation: React.FC<Props> = props => {
  const { items } = props;

  const links = items.map(x => (
    <NavLink
      key={x.value}
      to={x.value}
      relative="route"
      className={({ isActive }) => {
        return `m-page-navigation-link ${isActive ? 'active' : ''}`;
      }}
    >
      {x.content}
    </NavLink>
  ));

  return (
    <nav className="m-page-navigation">
      <div className={layoutStyles.fixedContainer}>{links}</div>
    </nav>
  );
};

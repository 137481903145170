import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { mergeProps } from '~/shared/helpers/mergeProps';
import { AnyEnum } from '~/shared/types/utility';

import { Select } from '..';
import {
  PartialSelectProps,
  PartialSelectPropsWithName,
  SelectItemWithId,
} from '../types';

/**
 * Fabric to make a hook for using dropdown, that returns dropdown with localized items
 */
export const makeUseEnumSelect = <T extends AnyEnum>(
  enumObject: T,
  getLabelOrTranslationPrefix: string | ((enumValue: keyof T) => string),
  omittedKeys: (keyof T)[] = [],
  selectProps: PartialSelectProps = {}
) => {
  return (
    innerSelectProps: PartialSelectPropsWithName<SelectItemWithId<keyof T>>
  ) => {
    const { t } = useTranslation(MaslovNamespaces.enums);

    const items = useMemo(() => {
      return Object.entries(enumObject)
        .filter(([enumKey]) => !omittedKeys.includes(enumKey))
        .map(([, enumValue]) => {
          let translatedName;
          if (typeof getLabelOrTranslationPrefix === 'function') {
            translatedName = getLabelOrTranslationPrefix(enumValue);
          } else {
            translatedName = t([
              `${getLabelOrTranslationPrefix}${enumValue}`,
              enumValue,
            ]);
          }

          return {
            id: enumValue as keyof T,
            name: translatedName,
          };
        });
    }, []);

    return {
      items,
      renderSelectElement: (
        renderSelectProps: PartialSelectProps<SelectItemWithId<keyof T>> = {}
      ) => (
        <Select<(typeof items)[number]>
          {...mergeProps(
            { items },
            selectProps,
            innerSelectProps,
            renderSelectProps
          )}
        />
      ),
    };
  };
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DateHardcodedValueFragment = { __typename: 'DateHardcodedValue', dateValue: any };

export const DateHardcodedValueFragmentDoc = gql`
    fragment DateHardcodedValue on DateHardcodedValue {
  __typename
  dateValue: value
}
    `;
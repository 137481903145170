import React, { ReactNode, useState } from 'react';

import { Button, ButtonProps } from '~/shared/components/Button';
import {
  FunctionButton,
  FunctionButtonProps,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Menu, MenuItemType, MenuProps } from '~/shared/components/Menu';
import { Popover, PopoverProps } from '~/shared/components/Popover';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useControllableState } from '~/shared/hooks/useControllableState';

export interface ContextMenuButtonProps<
  ItemType extends MenuItemType = MenuItemType,
> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Items to render in the menu
   */
  items: ItemType[];
  /**
   * If true, renders disabled button without context menu
   */
  isDisabled?: boolean;
  /**
   * Tooltip for icon
   */
  tooltip?: ReactNode;
  /**
   * Props for menu popover
   */
  popoverProps?: Partial<Omit<PopoverProps, 'withListNavigation'>>;
  /**
   * enabling function button as children element (default - true)
   */
  isFunctionButton?: boolean;
  /**
   * Props for function button, by default renders dots icon
   */
  functionButtonProps?: Omit<Partial<FunctionButtonProps>, 'ref'>;
  /**
   * Props for classic button, by default renders dots icon
   */
  buttonProps?: Omit<Partial<ButtonProps>, 'ref'>;
  /**
   * Additional props for menu, e.g.
   */
  menuProps?: Partial<MenuProps<ItemType>>;
}

export const ContextMenuButton = <
  ItemType extends MenuItemType = MenuItemType,
>({
  className,
  isDisabled = false,
  isFunctionButton = true,
  items,
  tooltip,
  popoverProps,
  functionButtonProps,
  buttonProps,
  menuProps,
}: ContextMenuButtonProps<ItemType>) => {
  const [isOpen, setIsOpen] = useControllableState(
    popoverProps?.isOpen,
    popoverProps?.onIsOpenChange,
    popoverProps?.defaultIsOpen ?? false
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const commonButtonProps = {
    className,
    iconVariant: IconVariants.dots,
    isDisabled,
    onPress: e => {
      setIsTooltipOpen(false);

      if (e.pointerType === 'keyboard') {
        // Only handle keyboard open, cause other events are handled in popup
        setIsOpen(current => !current);
      }
    },
    tooltip,
    tooltipProps: {
      isDisabled: !tooltip || isOpen,
      isOpen: isTooltipOpen,
      onIsOpenChange: setIsTooltipOpen,
    },
    iconProps: {
      'data-context-menu-is-open': isOpen,
    },
  } satisfies ButtonProps;

  return (
    <Popover
      {...{
        placement: 'bottom-end',
        isDisabled,
        shouldCloseOnContentClick: true,
        withListNavigation: true,
        listNavigationProps: {
          activeIndex,
          onNavigate: setActiveIndex,
        },
        floatingFocusManagerProps: {
          order: ['floating', 'reference'],
          returnFocus: true,
        },
        renderContent: ({ listRef, getItemProps }) => (
          <Menu
            {...mergeProps(
              {
                items,
                onItemPress: () => {
                  setIsOpen(false);
                },
                activeIndex,
                getItemProps: (index, userProps) => ({
                  ref: node => {
                    listRef.current[index] = node;
                  },
                  ...getItemProps(userProps),
                }),
              } satisfies MenuProps,
              menuProps
            )}
          />
        ),
        isOpen,
        ...popoverProps,
        onIsOpenChange: newIsOpen => {
          setIsTooltipOpen(false);
          setIsOpen(newIsOpen);
        },
      }}
    >
      {isFunctionButton ? (
        <FunctionButton
          {...mergeProps(commonButtonProps, functionButtonProps)}
        />
      ) : (
        <Button
          {...mergeProps(commonButtonProps, buttonProps, {
            isPressed: isOpen,
          })}
        />
      )}
    </Popover>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../../entities/farms/gql/fragments/farm.graphql';
import { CompanyShortFragmentDoc } from '../../../../entities/companies/gql/fragments/companyShort.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyUserQuery = { __typename?: 'Query', myUser: { __typename: 'User', id: string, firstName: string, lastName: string, middleName: string, email: any, farms: Array<{ __typename: 'Farm', id: string, name: string, number: string, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } }>, integrator?: { __typename: 'Integrator', id: string, name: string } | null, companies: { __typename?: 'UserCompanyConnection', edges: Array<{ __typename?: 'UserCompanyEdge', canEditSettings: boolean, node: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } }> } } };


export const MyUserDocument = gql`
    query myUser {
  myUser {
    __typename
    id
    firstName
    lastName
    middleName
    email
    farms {
      ...Farm
      company {
        ...CompanyShort
      }
    }
    integrator {
      __typename
      id
      name
    }
    companies {
      edges {
        canEditSettings
        node {
          ...CompanyShort
        }
      }
    }
  }
}
    ${FarmFragmentDoc}
${CompanyShortFragmentDoc}`;

/**
 * __useMyUserQuery__
 *
 * To run a query within a React component, call `useMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserQuery(baseOptions?: Apollo.QueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, options);
      }
export function useMyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, options);
        }
export function useMyUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, options);
        }
export type MyUserQueryHookResult = ReturnType<typeof useMyUserQuery>;
export type MyUserLazyQueryHookResult = ReturnType<typeof useMyUserLazyQuery>;
export type MyUserSuspenseQueryHookResult = ReturnType<typeof useMyUserSuspenseQuery>;
export type MyUserQueryResult = Apollo.QueryResult<MyUserQuery, MyUserQueryVariables>;
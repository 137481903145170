import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CompanyFragment = { __typename: 'Company', id: string, name: string, blueprintsCount: number, cowsCount: number, usersCount: number, licensedBy: any, tzIdentifier: string };

export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  __typename
  id
  name
  blueprintsCount
  cowsCount
  usersCount
  licensedBy
  tzIdentifier
}
    `;
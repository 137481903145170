import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type UserFragment = { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, integrator?: { __typename: 'Integrator', id: string, name: string } | null };

export const UserFragmentDoc = gql`
    fragment User on User {
  __typename
  id
  email
  firstName
  lastName
  middleName
  integrator {
    __typename
    id
    name
  }
}
    `;
export enum IconVariants {
  arrowDownRightTurn = 'arrowDownRightTurn',
  arrowUp = 'arrowUp',
  block = 'block',
  calendar = 'calendar',
  calendarCheck = 'calendarCheck',
  cancel = 'cancel',
  cancelCircleFilled = 'cancelCircleFilled',
  chart = 'chart',
  check = 'check',
  checkCircleFilled = 'checkCircleFilled',
  chevronDown = 'chevronDown',
  chevronRightCircle = 'chevronRightCircle',
  chevronRightCircleFilled = 'chevronRightCircleFilled',
  clipboardList = 'clipboardList',
  clock = 'clock',
  code = 'code',
  company = 'company',
  cow = 'cow',
  dots = 'dots',
  download = 'download',
  dragHandle = 'dragHandle',
  dragIndicator = 'dragIndicator',
  duplicate = 'duplicate',
  equally = 'equally',
  helpCircleFilled = 'helpCircleFilled',
  infoCircleFilled = 'infoCircleFilled',
  key = 'key',
  metricNegative = 'metricNegative',
  metricPositive = 'metricPositive',
  metricUnchanged = 'metricUnchanged',
  newTab = 'newTab',
  pencil = 'pencil',
  plus = 'plus',
  profile = 'profile',
  search = 'search',
  settings = 'settings',
  spinner = 'spinner',
  star = 'star',
  trash = 'trash',
  update = 'update',
  upload = 'upload',
  user = 'user',
  warningCircleFilled = 'warningCircleFilled',
  xClose = 'xClose',
}
import React from 'react';

import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { TextLink } from '~/shared/components/TextLink';

import { ReactComponent as BrandSvg } from './brand-image.svg';
import styles from './index.module.scss';

export const Brand: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <TextLink
    {...{
      to: MASLOV_ROUTES_TREE.root,
      className: clsx(styles.root, className),
    }}
  >
    <BrandSvg {...props} />
  </TextLink>
);

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowsCopyKeyFragmentDoc } from '../fragments/CowsCopyKey.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CowsCopyKeysForActivationQueryVariables = Types.Exact<{
  keys: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CowsCopyKeysForActivationQuery = { __typename?: 'Query', cowsCopyKeysForActivation: Array<{ __typename: 'CowsCopyKey', key: string, reason: Types.CowsCopyReasonEnum, departDate: any, arriveDate?: any | null, activatedAt?: any | null, toCompanyInn?: string | null, toCompanyName?: string | null, creator: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, toCompany?: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } | null, cows: Array<{ __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }>, userEvents: Array<{ __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum }>, diseases: Array<{ __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> }>, inseminationBulls: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: any | null, bullState: Types.BullState, bullDateOfBirth?: any | null }> }> };


export const CowsCopyKeysForActivationDocument = gql`
    query cowsCopyKeysForActivation($keys: [String!]!) {
  cowsCopyKeysForActivation(keys: $keys) {
    ...CowsCopyKey
  }
}
    ${CowsCopyKeyFragmentDoc}`;

/**
 * __useCowsCopyKeysForActivationQuery__
 *
 * To run a query within a React component, call `useCowsCopyKeysForActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCowsCopyKeysForActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCowsCopyKeysForActivationQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useCowsCopyKeysForActivationQuery(baseOptions: Apollo.QueryHookOptions<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables> & ({ variables: CowsCopyKeysForActivationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>(CowsCopyKeysForActivationDocument, options);
      }
export function useCowsCopyKeysForActivationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>(CowsCopyKeysForActivationDocument, options);
        }
export function useCowsCopyKeysForActivationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>(CowsCopyKeysForActivationDocument, options);
        }
export type CowsCopyKeysForActivationQueryHookResult = ReturnType<typeof useCowsCopyKeysForActivationQuery>;
export type CowsCopyKeysForActivationLazyQueryHookResult = ReturnType<typeof useCowsCopyKeysForActivationLazyQuery>;
export type CowsCopyKeysForActivationSuspenseQueryHookResult = ReturnType<typeof useCowsCopyKeysForActivationSuspenseQuery>;
export type CowsCopyKeysForActivationQueryResult = Apollo.QueryResult<CowsCopyKeysForActivationQuery, CowsCopyKeysForActivationQueryVariables>;
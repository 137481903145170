/* eslint-disable react-hooks/rules-of-hooks -- we use early exit from initialization depending on env */
import { useEffect } from 'react';

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '';

export const useGoogleAnalytics = () => {
  // Now we use Google analytics only in production
  const shouldUseAnalytics =
    GOOGLE_ANALYTICS_ID && process.env.NODE_ENV === 'production';

  if (!shouldUseAnalytics) {
    return;
  }

  useEffect(() => {
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;

    const boundary = document.createComment('Google tag (gtag.js)');

    const script = document.createElement('script');
    script.type = 'text/javascript';

    const googleScript = `
          window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);} 
          gtag('js', new Date()); 
          gtag('config', '${GOOGLE_ANALYTICS_ID}'); 
      `;

    try {
      document.body.appendChild(boundary);
      document.body.appendChild(el);
      script.appendChild(document.createTextNode(googleScript));
      document.body.appendChild(script);
      document.body.appendChild(boundary);
    } catch (e) {
      document.body.appendChild(boundary);
      document.body.appendChild(el);
      script.text = googleScript;
      document.body.appendChild(script);
      document.body.appendChild(boundary);
    }
  }, []);
};

import { DatasetController } from 'chart.js';

import TOKENS from '~/styles/__generated__/tokens.json';

const defaultColor = TOKENS.colorStatus02_30;

function* getGroupColor() {
  const colors: string[] = [defaultColor, TOKENS.colorWarning30];
  let takesCount = 0;

  while (true) {
    const index = takesCount % colors.length;
    const color = colors[index];
    yield color;
    takesCount += 1;
  }
}

interface CowGroupTransition {
  to: number;
  from?: number | null | undefined;
  group: string;
  id: string;
}

export class GroupSpecialController extends DatasetController {
  static id: string = 'groupSpecial';

  // Defaults for charts of this type
  static defaults = {
    datasetElementType: 'bar',
    dataElementType: 'bar',
  };

  // ID of the controller

  // Update the elements in response to new data
  // @param mode : update mode, core calls this method using any of `'active'`, `'hide'`, `'reset'`, `'resize'`, `'show'` or `undefined`
  update() {}

  draw(): void {
    const colorIterator = getGroupColor();
    const meta = this.getMeta() as any;
    // eslint-disable-next-line no-underscore-dangle -- inner chart.js field
    const dataset = meta._dataset;

    const scaleY = this.getScaleForId('y');
    const scaleX = this.getScaleForId('x');

    const { ctx } = this.chart;
    ctx.save();

    dataset.data.forEach((item: CowGroupTransition) => {
      if (scaleX && scaleY) {
        const maxX = scaleX?.max || 0;

        const x = scaleX?.getPixelForValue(item.to) || 0;
        const y = 0;

        const height = scaleY?.bottom || 0 - (scaleY?.paddingBottom || 0);

        const start = item.to;
        const end = item.from ? item.from : maxX;

        const pixelStart = scaleX.getPixelForValue(start);
        const pixelEnd = scaleX?.getPixelForValue(end);
        const width = pixelEnd - pixelStart;

        const textStart = pixelStart + width / 2;
        ctx.fillStyle = colorIterator.next().value || defaultColor;
        ctx.fillRect(x, y, width, height);

        ctx.fillStyle = TOKENS.colorFgMuted;
        ctx.font = '12px Graphik';
        ctx.fillText(item.group, textStart, 16);
      }
    });

    ctx.restore();
  }
}

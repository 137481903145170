import { useRef } from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';

import { isTmpId } from '~/shared/helpers/string';

import { readFarmFragment } from '~/entities/farms';

import {
  CreateUserMutationOptions,
  useCreateUserMutation,
} from '../gql/mutations/createUser.graphql';
import {
  RemoveUserFromCompanyMutationOptions,
  useRemoveUserFromCompanyMutation,
} from '../gql/mutations/removeUserFromCompany.graphql';
import {
  UpdateUserMutationOptions,
  useUpdateUserMutation,
} from '../gql/mutations/updateUser.graphql';
import { updateUserFragment } from '../helpers';
import { UserFormType } from '../types';

/**
 * Hook for CRUD actions on a user
 */
export const useUsersCRUD = () => {
  const client = useApolloClient();

  // We need created farm ids to use them in creating users
  const tempIdsMapRef = useRef<Record<string, string | undefined>>({});

  const getRealId = (farmId: string) =>
    isTmpId(farmId) ? tempIdsMapRef.current[farmId] : farmId;

  const getUserInputFromForm = (form: UserFormType) => ({
    ...R.omit(['id'], form),
    farmIDs: form.farmIDs.map(getRealId).filter(Boolean),
  });

  const mapFarmIdsToCache = (farmIDs: string[]) =>
    farmIDs
      .map(farmId => readFarmFragment(client, getRealId(farmId)))
      .filter(Boolean);

  // User create logic
  const [createUserMutation] = useCreateUserMutation();

  const createUser = (
    form: UserFormType,
    mutationOptions?: Partial<CreateUserMutationOptions>
  ) =>
    createUserMutation({
      variables: {
        input: getUserInputFromForm(form),
      },
      ...mutationOptions,
    });

  // User update logic
  const [updateUserMutation] = useUpdateUserMutation();

  const updateUser = (
    form: UserFormType,
    mutationOptions?: Partial<UpdateUserMutationOptions>
  ) =>
    updateUserMutation({
      variables: {
        id: form.id,
        input: R.omit(['email', 'farmIDs'], getUserInputFromForm(form)),
      },
      optimisticResponse: {
        updateUser: null,
      },
      ...mutationOptions,
      update: R.juxt(
        [
          updateUserFragment(form.id, draft => {
            draft.firstName = form.firstName;
            draft.lastName = form.lastName;
            draft.middleName = form.middleName;
          }),
          mutationOptions?.update,
        ].filter(Boolean)
      ),
    });

  // User delete logic
  const [removeUserFromCompany] = useRemoveUserFromCompanyMutation();
  const deleteUser = (
    companyID: string,
    userId: string,
    mutationOptions?: Partial<RemoveUserFromCompanyMutationOptions>
  ) =>
    removeUserFromCompany({
      variables: {
        id: userId,
        companyID,
      },
      optimisticResponse: { removeUserFromCompany: null },
      ...mutationOptions,
    });

  return {
    mapFarmIdsToCache,

    tempIdsMapRef,

    createUser,
    updateUser,
    deleteUser,
  };
};

import React, { ReactNode } from 'react';

/**
 * Possible menu item variants
 */
export enum MenuItemVariants {
  basic = 'basic',
  destructive = 'destructive',
  delimiter = 'delimiter',
}

/**
 * Config for a menu item
 */
export interface MenuItemType {
  key?: React.Key;
  variant?: MenuItemVariants;
  content?: ReactNode;
  onPress?: () => void;
  hasCheck?: boolean;
}

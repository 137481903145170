import React from 'react';

import { ErrorPage } from '~/apps/chicherin/pages/ErrorPage';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

const DESCRIPTION_ITEMS = [
  'Попробуйте обновить страницу.',
  'Если ошибка повторяется — сообщите об этом администратору',
];

interface Props {
  /**
   * Optional handler for "Update page" button
   */
  onUpdatePage?: () => void;
}

export const ClientErrorPage: React.FC<Props> = ({ onUpdatePage }) => {
  return (
    <ErrorPage
      {...{
        title: 'Что-то пошло не так',
        description: DESCRIPTION_ITEMS.map(description => (
          <Typography key={description} variant={TypographyVariants.bodyMedium}>
            {description}
          </Typography>
        )),
        buttonProps: {
          children: 'Обновить страницу',
          onPress: () =>
            (onUpdatePage ?? window.location.reload.bind(window.location))(),
        },
        onBrandClick: onUpdatePage,
      }}
    />
  );
};

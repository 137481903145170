import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DefaultEventFragment = { __typename: 'DefaultEvent', shortcode: Types.DefaultEventEnum, description: string };

export const DefaultEventFragmentDoc = gql`
    fragment DefaultEvent on DefaultEvent {
  __typename
  shortcode
  description
}
    `;
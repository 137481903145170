import { GraphQLError } from 'graphql';

/**
 * Gets props for a notification from a default graphql error
 */
export const getNotificationPropsFromGQLError = (error: GraphQLError) => ({
  message: error.message,
  title:
    typeof error.extensions?.title === 'string'
      ? error.extensions.title
      : undefined,
});

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../../../../entities/blueprintValues/gql/fragments/sourceField.graphql';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
export type CustomReportHeaderColumnFragment = { __typename: 'CustomReportHeaderColumn', blueprintSourceField: { __typename: 'SourceField', id: string, name: string }, blueprintSourceFieldValues: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: any | null } | null> };

export const CustomReportHeaderColumnFragmentDoc = gql`
    fragment CustomReportHeaderColumn on CustomReportHeaderColumn {
  __typename
  blueprintSourceField {
    ...SourceField
  }
  blueprintSourceFieldValues {
    ...CustomReportBlueprintSourceFieldValue
  }
}
    ${SourceFieldFragmentDoc}
${CustomReportBlueprintSourceFieldValueFragmentDoc}`;
import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowFragmentDoc } from '../fragments/cow.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CowsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vitalityFilter: Types.VitalityFilter;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  identifiers?: Types.InputMaybe<Array<Types.Scalars['Identifier']['input']> | Types.Scalars['Identifier']['input']>;
  penGroupIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type CowsQuery = { __typename?: 'Query', cows: { __typename?: 'CowConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CowEdge', cursor: string, node: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: any } } }> } };


export const CowsDocument = gql`
    query cows($search: String, $first: Int, $after: String, $vitalityFilter: VitalityFilter!, $ids: [ID!], $identifiers: [Identifier!], $penGroupIDs: [ID!], $farmIDs: [ID!]) {
  cows(
    search: $search
    first: $first
    after: $after
    vitalityFilter: $vitalityFilter
    ids: $ids
    identifiers: $identifiers
    penGroupIDs: $penGroupIDs
    farmIDs: $farmIDs
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Cow
      }
    }
  }
}
    ${CowFragmentDoc}`;

/**
 * __useCowsQuery__
 *
 * To run a query within a React component, call `useCowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCowsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      vitalityFilter: // value for 'vitalityFilter'
 *      ids: // value for 'ids'
 *      identifiers: // value for 'identifiers'
 *      penGroupIDs: // value for 'penGroupIDs'
 *      farmIDs: // value for 'farmIDs'
 *   },
 * });
 */
export function useCowsQuery(baseOptions: Apollo.QueryHookOptions<CowsQuery, CowsQueryVariables> & ({ variables: CowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CowsQuery, CowsQueryVariables>(CowsDocument, options);
      }
export function useCowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CowsQuery, CowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CowsQuery, CowsQueryVariables>(CowsDocument, options);
        }
export function useCowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CowsQuery, CowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CowsQuery, CowsQueryVariables>(CowsDocument, options);
        }
export type CowsQueryHookResult = ReturnType<typeof useCowsQuery>;
export type CowsLazyQueryHookResult = ReturnType<typeof useCowsLazyQuery>;
export type CowsSuspenseQueryHookResult = ReturnType<typeof useCowsSuspenseQuery>;
export type CowsQueryResult = Apollo.QueryResult<CowsQuery, CowsQueryVariables>;
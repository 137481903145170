import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintDetailedFragmentDoc } from '../fragments/blueprintDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlueprintDetailedQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BlueprintDetailedQuery = { __typename?: 'Query', blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string, sourceSections: Array<{ __typename: 'SourceSection', id: string, verboseName: string, fields: Array<{ __typename: 'SourceField', id: string, name: string }> }> } | null };


export const BlueprintDetailedDocument = gql`
    query blueprintDetailed($id: ID!) {
  blueprint(id: $id) {
    ...BlueprintDetailed
  }
}
    ${BlueprintDetailedFragmentDoc}`;

/**
 * __useBlueprintDetailedQuery__
 *
 * To run a query within a React component, call `useBlueprintDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlueprintDetailedQuery(baseOptions: Apollo.QueryHookOptions<BlueprintDetailedQuery, BlueprintDetailedQueryVariables> & ({ variables: BlueprintDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>(BlueprintDetailedDocument, options);
      }
export function useBlueprintDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>(BlueprintDetailedDocument, options);
        }
export function useBlueprintDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>(BlueprintDetailedDocument, options);
        }
export type BlueprintDetailedQueryHookResult = ReturnType<typeof useBlueprintDetailedQuery>;
export type BlueprintDetailedLazyQueryHookResult = ReturnType<typeof useBlueprintDetailedLazyQuery>;
export type BlueprintDetailedSuspenseQueryHookResult = ReturnType<typeof useBlueprintDetailedSuspenseQuery>;
export type BlueprintDetailedQueryResult = Apollo.QueryResult<BlueprintDetailedQuery, BlueprintDetailedQueryVariables>;
import React from 'react';

import { useApolloClient } from '@apollo/client';

import {
  MilkingParlorKindEnum,
  MilkingParlorManufacturerEnum,
  ParlorExportStatusFileTagFieldEnum,
} from '@graphql-types';
import R from 'ramda';
import * as yup from 'yup';

import { TagFieldSelect } from '~/features/milkingParlors';
import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { Input, InputVariants } from '~/shared/components/Input';
import { oneOfEnum } from '~/shared/helpers/yup';

import { readFarmFragment, useFarmSelect } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import { MilkingParlorFragment } from '../../gql/fragments/milkingParlor.graphql';
import { useCreateMilkingParlorMutation } from '../../gql/mutations/createMilkingParlor.graphql';
import { useUpdateMilkingParlorMutation } from '../../gql/mutations/updateMilkingParlor.graphql';
import { updateMilkingParlorFragment } from '../../helpers';
import {
  useMilkingParlorKindSelect,
  useMilkingParlorManufacturerSelect,
} from '../../hooks';

export interface EditMilkingParlorModalProps
  extends InjectedModalProps<EditMilkingParlorModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing milking parlor, if not passed, a new one is created
   */
  milkingParlor?: MilkingParlorFragment;
}

const FORM_ID = 'EditMilkingParlorForm';

const SCHEMA = yup.object({
  farmID: yup.string().required(), // ID!
  name: yup.string().default('').required(),
  manufacturer: oneOfEnum(MilkingParlorManufacturerEnum).default(
    MilkingParlorManufacturerEnum.Gea
  ),
  kind: oneOfEnum(MilkingParlorKindEnum)
    .default(MilkingParlorKindEnum.Carousel)
    .required(),
  capacityStart: yup.number().default(null).required(),
  capacityEnd: yup.number().default(null).required(),
  sortingGatesCount: yup.number().default(null).required(),
  connectionInfo: yup.string().default('').required(),
  tagField: oneOfEnum<ParlorExportStatusFileTagFieldEnum>(
    ParlorExportStatusFileTagFieldEnum
  )
    .default(ParlorExportStatusFileTagFieldEnum.Trans)
    .required(),
});

type EditMilkingParlorFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditMilkingParlorFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const EditMilkingParlorModal: React.FC<EditMilkingParlorModalProps> = ({
  className,
  milkingParlor,
  close,
}) => {
  const isEditing = !!milkingParlor;

  const client = useApolloClient();

  const {
    itemsPromise: farmsItemsPromise,
    renderSelectElement: renderFarmsSelectElement,
  } = useFarmSelect({
    selectProps: {
      label: 'Ферма',
      name: 'farmID',
    },
  });

  const { renderSelectElement: renderMilkingParlorManufacturerSelectElement } =
    useMilkingParlorManufacturerSelect({
      name: 'manufacturer',
      label: 'Производитель',
    });

  const { renderSelectElement: renderMilkingParlorKindSelectElement } =
    useMilkingParlorKindSelect({
      name: 'kind',
      label: 'Тип',
    });

  const formContext = useForm<
    EditMilkingParlorFormType,
    EditMilkingParlorFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: () =>
      farmsItemsPromise.then(items => ({
        ...SCHEMA.getDefault(),
        ...R.pick(
          [
            'name',
            'manufacturer',
            'kind',
            'capacityStart',
            'capacityEnd',
            'sortingGatesCount',
            'tagField',
            'connectionInfo',
          ],
          milkingParlor ?? ({} as MilkingParlorFragment)
        ),
        farmID: milkingParlor?.farm.id ?? items[0]?.id,
      })),
  });

  const [createMilkingParlor, { loading: isCreateMilkingParlorLoading }] =
    useCreateMilkingParlorMutation();

  const [updateMilkingParlor, { loading: isUpdateMilkingParlorLoading }] =
    useUpdateMilkingParlorMutation();

  const handleSubmit = async (form: EditMilkingParlorFormTransformedType) => {
    if (isEditing) {
      await updateMilkingParlor({
        variables: {
          id: milkingParlor.id,
          input: form,
        },
        update: updateMilkingParlorFragment(milkingParlor.id, draft => {
          draft.name = form.name;
          draft.manufacturer = form.manufacturer;
          draft.kind = form.kind;
          draft.capacityStart = form.capacityStart;
          draft.capacityEnd = form.capacityEnd;
          draft.capacity = form.capacityEnd - form.capacityStart + 1;
          draft.sortingGatesCount = form.sortingGatesCount;
          draft.connectionInfo = form.connectionInfo;
          draft.tagField = form.tagField;
          const farmFragment = readFarmFragment(client, form.farmID);
          if (farmFragment) {
            draft.farm = farmFragment;
          }
        }),
      });
    } else {
      await createMilkingParlor({
        variables: {
          input: form,
        },
        refetchQueries: ['milkingParlors'],
      });
    }
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing
          ? 'Редактирование доильного зала'
          : 'Новый доильный зал',
        submitButtonProps: {
          form: FORM_ID,
          isLoading:
            isCreateMilkingParlorLoading || isUpdateMilkingParlorLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          className: 'grid gap-16',
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {renderFarmsSelectElement()}
        <Input
          {...{
            name: 'name',
            label: 'Название',
          }}
        />
        {renderMilkingParlorManufacturerSelectElement()}
        {renderMilkingParlorKindSelectElement()}

        <div className={formStyles.twoColumnForm}>
          <Input
            {...{
              name: 'capacityStart',
              label: 'Номер первого места в зале',
              variant: InputVariants.int,
            }}
          />
          <Input
            {...{
              name: 'capacityEnd',
              label: 'Номер последнего места в зале',
              variant: InputVariants.int,
            }}
          />
        </div>
        <Input
          {...{
            name: 'sortingGatesCount',
            label: 'Количество сортировочных ворот',
            variant: InputVariants.int,
          }}
        />
        <TagFieldSelect
          {...{
            name: 'tagField',
            label: 'Передавать как номер транспондера',
          }}
        />
        <Input
          {...{
            name: 'connectionInfo',
            label: 'Строка подключения',
          }}
        />
      </Form>
    </Modal>
  );
};

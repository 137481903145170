import React, { useMemo } from 'react';

import { getTmpId } from '~/shared/helpers/string';

import { SkeletonBarChart } from './components/SkeletonBarChart';
import { SkeletonBlock } from './components/SkeletonBlock';
import { SkeletonText } from './components/SkeletonText';
import {
  SkeletonContext,
  SkeletonContextType,
  useSkeletonContext,
} from './context';

interface Props extends React.PropsWithChildren {
  /**
   * If true, skeleton provides loading state for its children
   */
  isLoading?: boolean;
}

/**
 * Skeleton placeholder type for passing into components with skeletons
 */
export type SkeletonPlaceholder = { id: string } & Record<string, undefined> &
  Record<symbol, boolean>;

const skeletonSymbol = Symbol('skeleton');

/**
 * Single skeleton placeholder
 */
export const getSingleSkeletonPlaceholder = () => {
  const placeholder = { id: getTmpId('skeleton') } as SkeletonPlaceholder;
  placeholder[skeletonSymbol] = true;
  return placeholder;
};

/**
 * Helper to generate an array with passed count, containing undefined placeholders for rendering skeletons
 */
export const getSkeletonPlaceholders = (length: number) =>
  Array.from({ length }, getSingleSkeletonPlaceholder);

/**
 * Checks, if an item is a skeleton placeholder special object
 */
export const isSkeletonPlaceholder = (
  item: unknown
): item is SkeletonPlaceholder =>
  !!item && Object.getOwnPropertySymbols(item).some(s => s === skeletonSymbol);

export const Skeleton = ({
  isLoading: isLoadingProp = false,
  children,
}: Props) => {
  const { isLoading: currentContextIsLoading } = useSkeletonContext();

  // Sometimes we may have a case of rendering nested Skeletons,
  // but we shouldn't end up resetting initial loading state
  const isLoading = currentContextIsLoading || isLoadingProp;

  const contextValue = useMemo<SkeletonContextType>(
    () => ({
      isLoading,
      renderWithSkeleton: (skeleton, content, isStaticContent = false) =>
        isLoading && !isStaticContent ? skeleton : content,
      getSkeletonClassNames: (skeletonClassNames, contentClassNames = '') =>
        isLoading ? skeletonClassNames : contentClassNames,
      renderWithoutSkeleton: content => (isLoading ? null : content),
    }),
    [isLoading, currentContextIsLoading]
  );

  return (
    <SkeletonContext.Provider value={contextValue}>
      {children}
    </SkeletonContext.Provider>
  );
};

Skeleton.Block = SkeletonBlock;
Skeleton.Text = SkeletonText;
Skeleton.BarChart = SkeletonBarChart;

export * from './context';
export { TextSkeletonSizes } from './components/SkeletonText';

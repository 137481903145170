import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * It takes a phone number and returns a formatted phone number
 * @param {string} phone - The phone number to format.
 * @param format - The format to use when formatting the number.
 * @returns A string
 */
export const formatPhoneNumber = (phone: string = '') => {
  // Add plus sign to start of number if it's not provided.
  const phoneWithPlus = `+${phone.replace(/^\+/, '')}`;

  try {
    const parsedNumber = parsePhoneNumber(phoneWithPlus);
    return parsedNumber.formatInternational();
  } catch (e) {
    return '';
  }
};

import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import R from 'ramda';

import { capitalize } from '~/shared/helpers/string';

type IsEnum<T> =
  Exclude<T, undefined> extends string
    ? Exclude<T, undefined> extends never
      ? false
      : true
    : false;

type SerializableParam = string | string[] | undefined;

type SerializableState = Record<string, SerializableParam>;

type ParamValue<T extends SerializableParam> =
  IsEnum<T> extends true
    ? T | undefined
    : T extends any[]
      ? string[]
      : string | undefined;

type SearchParamsValues<T extends SerializableState> = {
  [K in keyof T]: ParamValue<T[K]>;
};

type SearchParamSetterKey<K> = `set${Capitalize<Extract<K, string>>}`;

type SearchParamsSetters<T extends SerializableState> = {
  [K in keyof T as SearchParamSetterKey<K>]: Dispatch<
    SetStateAction<ParamValue<T[K]>>
  >;
};

export type UseSearchParamsStateInterface<T extends SerializableState> =
  SearchParamsValues<T> & SearchParamsSetters<T>;

/**
 * Hook for using state, stored in the search part of the router
 */
export const useSearchParamsState = <T extends SerializableState>(
  initialState: T
): UseSearchParamsStateInterface<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const stateKeys = Object.keys(initialState) as Extract<keyof T, string>[];

  const isArrayParamKey = (key: keyof T) => Array.isArray(initialState[key]);

  const getSearchParamSetterKey = <K extends string>(key: K) =>
    `set${capitalize(key)}` as SearchParamSetterKey<typeof key>;

  const stateKeysHash = stateKeys.join('__');

  const searchParamsValues = useMemo(
    () =>
      stateKeys.reduce((acc, key) => {
        const paramValue = isArrayParamKey(key)
          ? searchParams.getAll(key)
          : searchParams.get(key) ?? undefined;

        acc[key] = (paramValue ?? initialState[key]) as ParamValue<
          T[typeof key]
        >;
        return acc;
      }, {} as SearchParamsValues<T>),
    [stateKeysHash, searchParams]
  );

  const searchParamsSetters = useMemo(
    () =>
      stateKeys.reduce((acc, key) => {
        const setter: Dispatch<
          SetStateAction<ParamValue<T[typeof key]>>
        > = valueOrSetValue => {
          let newValue: SerializableParam;
          if (typeof valueOrSetValue === 'function') {
            const currentValue = searchParamsValues[key];
            newValue = valueOrSetValue(currentValue);
          } else {
            newValue = valueOrSetValue;
          }

          setSearchParams(
            currentParams => {
              currentParams.delete(key);

              if (isArrayParamKey(key) && Array.isArray(newValue)) {
                newValue.forEach(val => {
                  currentParams.append(key, val);
                });
              } else if (newValue && !Array.isArray(newValue)) {
                currentParams.set(key, newValue);
              }

              return currentParams;
            },
            { replace: true }
          );
        };

        acc[getSearchParamSetterKey(key)] = setter as any;

        return acc;
      }, {} as SearchParamsSetters<T>),
    [searchParamsValues, stateKeysHash]
  );

  // Update url state, if it doesn't match initial values
  useEffect(() => {
    stateKeys.forEach(key => {
      const initialParamValue = initialState[key] as SerializableParam;
      const currentParamValue = searchParamsValues[key];
      if (
        !searchParams.has(key) &&
        !R.equals(initialParamValue, currentParamValue)
      ) {
        (searchParamsSetters[getSearchParamSetterKey(key)] as any)(
          initialParamValue
        );
      }
    });
  }, []);

  return {
    ...searchParamsValues,
    ...searchParamsSetters,
  };
};

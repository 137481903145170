import R from 'ramda';

import { makeUseAsyncSelect } from '~/services/gql';

import { CompanyFragmentDoc } from '~/entities/companies/gql/fragments/company.graphql';
import { useCompaniesQuery } from '~/entities/companies/gql/queries/companies.graphql';

/**
 * Select for a company
 */
export const useCompanySelect = makeUseAsyncSelect({
  typeName: 'Company',
  fragment: CompanyFragmentDoc,
  useQuery: useCompaniesQuery,
  getItemsFromQueryData: data => data.companies.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.companies.pageInfo,

  selectProps: {
    placeholder: 'Выберите наименование',
  },
});

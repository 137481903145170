import { useCallback, useLayoutEffect, useState } from 'react';

import { useEventListener } from '~/shared/hooks/useEventListener';

interface Size {
  width: number;
  height: number;
}

/**
 * Hook for reactive elements size measurement
 */
export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] => {
  // Use state to store ref, so it can be reactive
  const [ref, setRef] = useState<T | null>(null);

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth ?? 0,
      height: ref?.offsetHeight ?? 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener('resize', handleResize);

  useLayoutEffect(() => {
    handleResize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
};

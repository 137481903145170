import {
  AnalyticalMetricEnum,
  AnalyticalSettingEnum,
  AnalyticsGroupingPeriodEnum,
  AnalyticsMetricPeriodEnum,
  HerdStateChartBar,
  PageInfo,
  SetAnalyticalMetricTargetInput,
  SetAnalyticalSettingSettingInput,
} from '~/shared/graphql';

export enum AnalyticsArea {
  herd = 'herd',
  reproduction = 'reproduction',
}

interface AnalyticsFilters {
  groupingPeriod: AnalyticsGroupingPeriodEnum;
  farmId?: string;
  since?: string;
  till?: string;
}

export interface AnalyticsDashboardFilters extends AnalyticsFilters {
  metricsPeriod: AnalyticsMetricPeriodEnum;
}

interface HerdStateMetricData {
  metric: AnalyticalMetricEnum;
  target?: number | null;
  value?: number | null;
  delta?: number | null;
}

export interface AppAnalyticalTarget {
  id: string;
  validSince: string;
  validTill?: string;
  metric: AnalyticalMetricEnum;
  value: number;
}

export interface HerdStateMetricChart {
  bars: HerdStateChartBar[];
  targets: AppAnalyticalTarget[];
}

export interface AppHerdState {
  metrics: HerdStateMetricData[];
  cowCountChart: HerdStateMetricChart;
}

export interface MetricFilters extends AnalyticsFilters {
  metric: AnalyticalMetricEnum;
}

export interface HerdMetricData {
  metricChart: HerdStateMetricChart;
}

export interface AnalyticTargetFilters {
  metric: AnalyticalMetricEnum;
  from?: string | null;
}

export interface AppAnalyticTarget {
  id: string;
  validSince: string;
  validTill?: string;
  metric: AnalyticalMetricEnum;
  value: number;
}

export interface MetricSetting {
  setting: AnalyticalSettingEnum;
  value: number;
}

export interface AnalyticsService {
  getHerdState(filters: AnalyticsDashboardFilters): Promise<AppHerdState>;
  getHerdMetricDetails(filters: MetricFilters): Promise<HerdMetricData>;

  getTargets(
    filters: AnalyticTargetFilters
  ): Promise<[AppAnalyticTarget[], PageInfo]>;

  addTarget(input: SetAnalyticalMetricTargetInput): Promise<void>;
  setAnalyticalSetting(input: SetAnalyticalSettingSettingInput): Promise<void>;
}

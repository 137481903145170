import React from 'react';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { useAuth, useMyUser } from '~/services/auth';
import { useArkaNavigation } from '~/services/navigation';

import { MAvatar } from '~/shared/components/MAvatar/MAvatar';
import { MLinkButton } from '~/shared/components/MLinkButton';
import { PhoneLink } from '~/shared/components/PhoneLink';
import { Popover } from '~/shared/components/Popover';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';

import styles from './index.module.scss';
import { useSystemInfoModal } from './modals';

export const UserMenu: React.FC = () => {
  const { navigate } = useArkaNavigation();

  const { logout } = useAuth();
  const { myUser } = useMyUser();

  const userName = formatFullNameWithInitials(myUser);

  const hasManyCompanies = myUser.companies.edges.length > 1;

  const { open: openSystemInfoModal } = useSystemInfoModal();

  return (
    <Popover
      {...{
        placement: 'bottom-end',
        shouldCloseOnContentClick: true,
        renderContent: () => (
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <Typography variant={TypographyVariants.bodySmall} tag="div">
                {userName}
              </Typography>
              <Typography
                variant={TypographyVariants.bodySmall}
                className="text-muted"
              >
                {myUser.email}
              </Typography>
            </li>
            {hasManyCompanies && (
              <li className={styles.menuItem}>
                <MLinkButton
                  onClick={() =>
                    navigate(MASLOV_ROUTES_TREE.Auth.companySelector)
                  }
                >
                  Перейти к списку компаний
                </MLinkButton>
              </li>
            )}
            <li className={styles.menuItem}>
              <MLinkButton onClick={logout}>Выйти из системы</MLinkButton>
            </li>
            <li className={styles.menuItem}>
              <Typography
                variant={TypographyVariants.bodySmall}
                className="text-muted"
                tag="div"
              >
                Номер тех. поддержки
              </Typography>
              <PhoneLink phone={process.env.REACT_APP_SUPPORT_PHONE} />
            </li>
            <li className={styles.menuItem}>
              <MLinkButton onClick={() => openSystemInfoModal()}>
                Системная информация
              </MLinkButton>
            </li>
          </ul>
        ),
      }}
    >
      <MAvatar size="small" title={userName} tabIndex={0} />
    </Popover>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { EmployeeShortFragmentDoc } from '../../../employees/gql/fragments/employeeShort.graphql';
export type CalvingFragment = { __typename: 'Calving', id: string, happenedAt: any, isAbortion: boolean, difficultyScore: number, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null };

export const CalvingFragmentDoc = gql`
    fragment Calving on Calving {
  __typename
  id
  happenedAt
  isAbortion
  difficultyScore
  cow {
    ...CowShort
  }
  employee {
    ...EmployeeShort
  }
}
    ${CowShortFragmentDoc}
${EmployeeShortFragmentDoc}`;
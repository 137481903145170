/* eslint-disable react-hooks/rules-of-hooks -- we use early exit from initialization depending on env */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMyUserUnsafe } from '~/services/auth';

// Use any to allow accessing these keys on window
const YM_COUNTER_ID = +(process.env.REACT_APP_YM_COUNTER_ID ?? '');
const YM_CALLBACK_FIELD = 'ym';
const YM_COUNTER_INSTANCE_FIELD = `yaCounter${YM_COUNTER_ID}`;
const YM_SCRIPT_PATH = 'https://mc.yandex.ru/metrika/tag.js';

const YM_OPTIONS = {
  defer: true,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  triggerEvent: true,
};

enum MetrikaUserRoles {
  farmer = 'farmer',
  integrator = 'integrator',
}

/**
 * Function to insert Metrika script on page
 */
const insertMetrika = () => {
  (window as any)[YM_CALLBACK_FIELD] =
    (window as any)[YM_CALLBACK_FIELD] ||
    function () {
      ((window as any)[YM_CALLBACK_FIELD].a =
        (window as any)[YM_CALLBACK_FIELD].a || []).push(arguments);
    };
  (window as any)[YM_CALLBACK_FIELD].l = 1 * (new Date() as any);

  for (let j = 0; j < document.scripts.length; j += 1) {
    if (document.scripts[j].src === YM_SCRIPT_PATH) {
      return;
    }
  }

  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.async = true;
  el.src = YM_SCRIPT_PATH;

  document.head.append(el);
};

/**
 * Proxy Metrika call to existing instance
 */
const proxyMetrika = (methodName: string | number, ...args: any[]) => {
  const counterInstance = (window as any)[YM_COUNTER_INSTANCE_FIELD];
  if (counterInstance) {
    counterInstance[methodName](...args);
  } else {
    (window as any)[YM_CALLBACK_FIELD](methodName, ...args);
  }
};

export const useYandexMetrika = () => {
  // Now we use Metrika only in production
  const shouldUseMetrika =
    YM_COUNTER_ID && process.env.NODE_ENV === 'production';

  if (!shouldUseMetrika) {
    return () => {};
  }

  // Init Metrika
  useEffect(() => {
    document.addEventListener(`yacounter${YM_COUNTER_ID}inited`, () => {
      (window as any)[YM_CALLBACK_FIELD].a
        .slice(1)
        .forEach((args: any[]) => proxyMetrika(args[0], ...[...args].slice(1)));
    });

    insertMetrika();

    proxyMetrika(YM_COUNTER_ID, 'init', YM_OPTIONS);
  }, []);

  // Track SPA url change
  const location = useLocation();
  useEffect(() => {
    proxyMetrika('hit', location.pathname + location.search);
  }, [location.pathname, location.search]);

  // Send additional user params
  const { myUser } = useMyUserUnsafe();
  useEffect(() => {
    if (!myUser) return;

    proxyMetrika('userParams', {
      UserID: myUser.id,
      // Old logic of integrator check, cause we're not sending the company,
      // so we should just check, if user has an integrator account.
      // We still don't have stable metrics gathering process, so this is ok for now
      role: myUser?.integrator
        ? MetrikaUserRoles.integrator
        : MetrikaUserRoles.farmer,
    });
  }, [myUser]);

  return proxyMetrika;
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';

import { useEventSelect } from '../../../../hooks';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Input name prefix for using inside the form
   */
  inputPrefix: string;
  /**
   * Called, when an injection is removed by user
   */
  onRemove?: () => void;
}

export const ProtocolInjectionFormRow: React.FC<Props> = ({
  className,
  inputPrefix,
  onRemove,
}) => {
  const formContext = useFormContext();

  const fieldName = `${inputPrefix}injectionID`;

  const { renderSelectElement: renderInjectionSelectElement } = useEventSelect({
    queryOptions: {
      variables: {
        kinds: [EventKindEnum.Injection],
      },
    },
    selectProps: {
      name: fieldName,
      rawValue: formContext.watch(fieldName),
      className: styles.input,
      label: 'Название инъекции',
      placeholder: 'Выберите инъекцию',
    },
  });

  return (
    <div className={clsx(styles.root, className)}>
      {renderInjectionSelectElement()}

      <Input
        {...{
          className: styles.input,
          name: `${inputPrefix}comment`,
          label: 'Комментарий',
          placeholder: 'Введите текст',
        }}
      />
      <Icon
        {...{
          className: clsx('text-muted', styles.icon),
          variant: IconVariants.trash,
          onPress: onRemove,
        }}
      />
    </div>
  );
};

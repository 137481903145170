import React from 'react';

export const Pencil = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.0999 8.2L19.1999 9.1L14.8999 4.8L15.7999 3.9C16.9999 2.7 18.8999 2.7 20.0999 3.9C21.2999 5.1 21.2999 7 20.0999 8.2Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2 9.09999L8.9 19.4C7.9 20.4 6.5 21 5 21H4C3.4 21 3 20.5 3 20V19C3 17.6 3.6 16.2 4.6 15.1L14.9 4.79999"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

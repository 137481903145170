import React, { useMemo } from 'react';

import {
  CategoryScale,
  Chart,
  ChartOptions,
  ChartType,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  Tooltip,
} from 'chart.js';

import { Skeleton } from '~/shared/components/Skeleton';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import TOKENS from '~/styles/__generated__/tokens.json';

import {
  CHART_DATASET_COLORS,
  CHART_DATASET_HOVER_COLORS,
  PIE_CHART_OPTIONS,
} from '../../constants';
import { getChartOptions } from '../../helpers';
import {
  ReactChart,
  ReactChartDatasetConfig,
  ReactChartProps,
} from '../ReactChart';
import { PIE_CHART_LABELS_PLUGIN } from './pieChartLabelsPlugin';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);

/**
 * Data item for the pie chart
 */
export type PieChartDataPoint =
  | {
      x: string | undefined;
      y: number;
    }
  | number;

interface Props
  extends Omit<
    ReactChartProps<ChartType, PieChartDataPoint[]>,
    'type' | 'data' | 'datasetConfigs' | 'skeleton'
  > {
  /**
   * Chart data
   */
  datasets: ReactChartDatasetConfig<ChartType, PieChartDataPoint[]>[];
  /**
   * Labels, used in the chart x axis
   */
  labels?: string[];
  /**
   * Additional options for the chart
   */
  chartOptions?: ChartOptions;
}

export const PieChart: React.FC<Props> = ({
  datasets,
  labels = [],
  chartOptions: chartOptionsProp,

  ...chartProps
}) => {
  const chartOptions = getChartOptions<ChartType>(
    PIE_CHART_OPTIONS,
    chartOptionsProp
  );

  const datasetConfigs = useMemo<
    ReactChartDatasetConfig<ChartType, PieChartDataPoint[]>[]
  >(
    () =>
      datasets.map((dataset, index) => ({
        ...dataset,
        data: dataset.data.map(dataPoint => {
          if (typeof dataPoint === 'number') {
            return dataPoint;
          }
          return 'y' in dataPoint ? dataPoint.y : dataPoint;
        }),
        borderColor: TOKENS.colorStatus05OnStatus05,
        hoverBorderColor: TOKENS.colorStatus05OnStatus05,
        backgroundColor: labels.map(
          (label, labelIndex) =>
            CHART_DATASET_COLORS[labelIndex % CHART_DATASET_COLORS.length]
        ),
        hoverBackgroundColor: labels.map(
          (label, labelIndex) =>
            CHART_DATASET_HOVER_COLORS[
              labelIndex % CHART_DATASET_HOVER_COLORS.length
            ]
        ),
        borderWidth: NUMBER_TOKENS.borderWidth1,
        order: dataset.order ?? index,
      })),
    [datasets, labels]
  );

  return (
    <ReactChart
      {...{
        options: chartOptions,
        type: 'pie',
        datasetIdKey: 'first',
        labels,
        datasetConfigs,
        skeleton: <Skeleton.BarChart />,
        plugins: [PIE_CHART_LABELS_PLUGIN],
        ...chartProps,
      }}
    />
  );
};

import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { ListItem } from '~/~legacy/types/keyValue';

import { MPageContainer } from '~/shared/components/MPageContainer';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { PageNavigation } from '../PageNavigation';

export const HerdPageContainer: React.FC = () => {
  const categories = useMemo(() => {
    const items: ListItem<string>[] = [
      {
        value: MASLOV_ROUTES_TREE.User.herd.cows,
        content: 'Животные',
      },
      {
        value: MASLOV_ROUTES_TREE.User.herd.penGroups,
        content: 'Группы животных',
      },
      {
        value: MASLOV_ROUTES_TREE.User.herd.bulls,
        content: 'Генетика',
      },
    ];
    return items;
  }, []);

  return (
    <MPageContainer>
      <PageNavigation items={categories} />
      <div className={layoutStyles.fixedContainer}>
        <Outlet />
      </div>
    </MPageContainer>
  );
};

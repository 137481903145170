import React, { ReactNode } from 'react';
import { InViewHookResponse } from 'react-intersection-observer';

import clsx from 'clsx';

import { Button, ButtonProps } from '~/shared/components/Button';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Loader } from '~/shared/components/Loader';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

export interface DataBlockedMessageProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Icon variant to display
   */
  iconVariant?: IconVariants;
  /**
   * If true, loader is displayed, instead of an icon
   */
  isLoading?: boolean;
  /**
   * Ref, used to track loader visibility for loading additional items
   */
  loaderRef?: InViewHookResponse['ref'];
  /**
   * Message to display
   */
  message: ReactNode;
  /**
   * Message to display, when isLoading is true
   */
  loadingMessage?: ReactNode;
  /**
   * Additional description text to display
   */
  description?: ReactNode;
  /**
   * If passed, renders a button for some action with blocked data
   */
  buttonProps?: Partial<Omit<ButtonProps, 'ref'>>;
}

export const DataBlockedMessage: React.FC<DataBlockedMessageProps> = ({
  className,
  isLoading = false,
  iconVariant = isLoading ? undefined : IconVariants.infoCircleFilled,
  loaderRef,
  message,
  loadingMessage = message,
  description,
  buttonProps,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      {iconVariant && <Icon variant={iconVariant} className="text-muted" />}
      {isLoading && <Loader size={SizeVariants.size24} ref={loaderRef} />}
      <Typography variant={TypographyVariants.bodySmallStrong}>
        {isLoading ? loadingMessage : message}
      </Typography>
      {!!description && (
        <Typography
          className="text-soft"
          variant={TypographyVariants.descriptionLarge}
        >
          {description}
        </Typography>
      )}
      {buttonProps && !isLoading && (
        <Button
          {...{
            className: 'mt-4',
            ...buttonProps,
          }}
        />
      )}
    </div>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { TestMilkingFragmentDoc } from '../fragments/testMilking.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestMilkingsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  testMilkingUploadIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type TestMilkingsQuery = { __typename?: 'Query', testMilkings: { __typename?: 'TestMilkingConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TestMilkingEdge', cursor: string, node: { __typename: 'TestMilking', id: string, happenedAt: any, daysInMilk: number, weightKilograms: number, fatPercent?: number | null, proteinPercent?: number | null, sccThousandsPerMl?: number | null, ureaMgPerDl?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: any } | null, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any } } }> } };


export const TestMilkingsDocument = gql`
    query testMilkings($after: String, $first: Int, $testMilkingUploadIDs: [ID!]) {
  testMilkings(
    after: $after
    first: $first
    testMilkingUploadIDs: $testMilkingUploadIDs
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TestMilking
      }
    }
  }
}
    ${TestMilkingFragmentDoc}`;

/**
 * __useTestMilkingsQuery__
 *
 * To run a query within a React component, call `useTestMilkingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestMilkingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestMilkingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      testMilkingUploadIDs: // value for 'testMilkingUploadIDs'
 *   },
 * });
 */
export function useTestMilkingsQuery(baseOptions?: Apollo.QueryHookOptions<TestMilkingsQuery, TestMilkingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestMilkingsQuery, TestMilkingsQueryVariables>(TestMilkingsDocument, options);
      }
export function useTestMilkingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestMilkingsQuery, TestMilkingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestMilkingsQuery, TestMilkingsQueryVariables>(TestMilkingsDocument, options);
        }
export function useTestMilkingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestMilkingsQuery, TestMilkingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestMilkingsQuery, TestMilkingsQueryVariables>(TestMilkingsDocument, options);
        }
export type TestMilkingsQueryHookResult = ReturnType<typeof useTestMilkingsQuery>;
export type TestMilkingsLazyQueryHookResult = ReturnType<typeof useTestMilkingsLazyQuery>;
export type TestMilkingsSuspenseQueryHookResult = ReturnType<typeof useTestMilkingsSuspenseQuery>;
export type TestMilkingsQueryResult = Apollo.QueryResult<TestMilkingsQuery, TestMilkingsQueryVariables>;
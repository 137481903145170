import React from 'react';

import { ReactComponent as UserPicSVG } from '~/~legacy/icons/user-circle.svg';

import './MAvatar.scss';

interface Props extends React.ComponentProps<'figure'> {
  image?: string;
  title: string;

  className?: string;
  size?: 'small' | 'normal' | 'big';

  click?: () => void;
}

export const MAvatar = React.forwardRef<HTMLElement, Props>(
  ({ title, size, className, click, ...figureProps }: Props, ref) => {
    return (
      <figure
        ref={ref}
        className={`
        m-avatar 
        ${className || ''}
        ${size || ''}`}
        title={title}
        onClick={click}
        {...figureProps}
      >
        <UserPicSVG />
      </figure>
    );
  }
);

import { CartesianScaleOptions, Plugin } from 'chart.js';

const SCALE_TRANSLATE_PX = 6;
const SCALE_COLORS_DICT: Record<string, string> = {};

/**
 * Plugin to render axis labels with same rotation,
 * cause default label are rendered differently for left and right axis
 */
export const ROTATED_AXIS_LABELS_PLUGIN: Plugin = {
  id: 'rotatedAxisLabels',
  beforeDraw: chart => {
    const {
      ctx,
      scales,
      chartArea: { top, bottom },
    } = chart;

    const axisRotationAngle = 270 * (Math.PI / 180);

    const scaleConfigEntries = Object.entries(
      chart.config.options?.scales ?? {}
    ) as [string, CartesianScaleOptions & { type?: 'linear' }][];
    scaleConfigEntries.forEach(([scaleName, scaleConfig]) => {
      if (
        scaleConfig?.type !== 'linear' ||
        !scaleConfig.display ||
        !scaleConfig.title?.display
      ) {
        return;
      }
      ctx.save();

      const currentScale = scales[scaleName];
      const isRightPosition = scaleConfig.position === 'right';
      const titleConfig = scaleConfig.title;

      const textToRender = titleConfig.text?.toString() ?? '';
      // Assume we always pass the color in string format, no gradient objects
      const textColor = titleConfig.color?.toString() ?? '';

      // Override color config for default axis label, so it will be calculated but won't be rendered
      SCALE_COLORS_DICT[scaleName] = textColor;
      // we need this hack to avoid writing whole labels rendering logic from scratch
      // eslint-disable-next-line no-param-reassign
      scaleConfig.title.color = 'transparent';

      const { actualBoundingBoxAscent, actualBoundingBoxDescent } =
        ctx.measureText(textToRender);
      const textHeight = actualBoundingBoxAscent + actualBoundingBoxDescent;

      const translateXValue = isRightPosition
        ? currentScale.right - textHeight - SCALE_TRANSLATE_PX
        : currentScale.left + textHeight + SCALE_TRANSLATE_PX;

      ctx.translate(translateXValue, (top + bottom) / 2);
      ctx.rotate(axisRotationAngle);

      ctx.fillStyle = SCALE_COLORS_DICT[scaleName];
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(textToRender, 0, 0);

      ctx.restore();
    });
  },
  afterDraw: chart => {
    const scaleConfigEntries = Object.entries(
      chart.config.options?.scales ?? {}
    ) as [string, CartesianScaleOptions & { type?: 'linear' }][];
    scaleConfigEntries.forEach(([scaleName, scaleConfig]) => {
      if (scaleConfig?.type !== 'linear' || !scaleConfig.title?.display) {
        return;
      }
      // Restore color setting for next draw cycle
      // eslint-disable-next-line no-param-reassign
      scaleConfig.title.color = SCALE_COLORS_DICT[scaleName];
    });
  },
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { ErrorPage } from '~/apps/chicherin/pages/ErrorPage';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

const DESCRIPTION_ITEMS = [
  'В адресе есть ошибка или страница удалена.',
  'Если ошибка повторяется — сообщите об этом администратору',
];

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ErrorPage
      {...{
        title: 'Страница не найдена',
        errorCode: 404,
        description: DESCRIPTION_ITEMS.map(description => (
          <Typography key={description} variant={TypographyVariants.bodyMedium}>
            {description}
          </Typography>
        )),
        buttonProps: {
          children: 'Вернуться на главную страницу',
          onPress: () => navigate(MASLOV_ROUTES_TREE.root),
        },
      }}
    />
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../../../entities/users/gql/fragments/user.graphql';
import { CompanyShortFragmentDoc } from '../../../../entities/companies/gql/fragments/companyShort.graphql';
import { CowShortFragmentDoc } from '../../../../entities/cows/gql/fragments/cowShort.graphql';
import { UserEventFragmentDoc } from '../../../../entities/events/gql/fragments/userEvent.graphql';
import { DiseaseFragmentDoc } from '../../../../entities/events/gql/fragments/disease.graphql';
import { BullFragmentDoc } from '../../../../entities/bulls/gql/fragments/bull.graphql';
export type CowsCopyKeyFragment = { __typename: 'CowsCopyKey', key: string, reason: Types.CowsCopyReasonEnum, departDate: any, arriveDate?: any | null, activatedAt?: any | null, toCompanyInn?: string | null, toCompanyName?: string | null, creator: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, toCompany?: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } | null, cows: Array<{ __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }>, userEvents: Array<{ __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum }>, diseases: Array<{ __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> }>, inseminationBulls: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: any | null, bullState: Types.BullState, bullDateOfBirth?: any | null }> };

export const CowsCopyKeyFragmentDoc = gql`
    fragment CowsCopyKey on CowsCopyKey {
  __typename
  key
  reason
  departDate
  arriveDate
  activatedAt
  toCompanyInn
  toCompanyName
  creator {
    ...User
  }
  company {
    ...CompanyShort
  }
  toCompany {
    ...CompanyShort
  }
  cows {
    ...CowShort
  }
  userEvents {
    ...UserEvent
  }
  diseases {
    ...Disease
  }
  inseminationBulls {
    ...Bull
  }
}
    ${UserFragmentDoc}
${CompanyShortFragmentDoc}
${CowShortFragmentDoc}
${UserEventFragmentDoc}
${DiseaseFragmentDoc}
${BullFragmentDoc}`;
import React, { ReactNode } from 'react';

import { MFooterBumber } from '../MFooterBumber';

import './MPageContainer.scss';

interface Props {
  children?: ReactNode;

  centerContent?: boolean;
  scrollContent?: boolean;
  className?: string;
  noBumber?: boolean;
}

export const MPageContainer: React.FC<Props> = props => {
  const {
    children,
    className,
    noBumber,
    centerContent = false,
    scrollContent = false,
  } = props;

  const bumber = noBumber ? null : <MFooterBumber />;

  return (
    <div
      className={`
        m-page-container 
        ${centerContent ? 'm-centered-content' : ''}
        ${scrollContent ? 'scroll' : ''}
        ${className || ''}`}
    >
      {children}
      {bumber}
    </div>
  );
};

import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { useConfirm } from '~/services/modals';

import { DotsLoader } from '~/shared/components/DotsLoader';
import { FunctionButtonVariants } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Table, TableColumnConfig } from '~/shared/components/Table';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { MDASH } from '~/shared/constants';
import { DateFormats, formatDate } from '~/shared/helpers/date';
import { normalizeBetweenTwoRanges } from '~/shared/helpers/number';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { MonitorEntryFragment } from '../../gql/fragments/monitorEntry.graphql';
import { MonitorLaunchResultValueFragment } from '../../gql/fragments/monitorLaunchResultValue.graphql';
import { useDeleteMonitorLaunchMutation } from '../../gql/mutations/deleteMonitorLaunch.graphql';
import {
  isMonitorEntry,
  isMonitorLaunchCalculatingValue,
  isMonitorLaunchErrorValue,
  isMonitorLaunchResultValue,
  makeUpdateMonitorQuery,
} from '../../helpers';
import { AnyMonitorEntry } from '../../types';
import { MonitorEntryTargetCell } from '../MonitorEntryTargetCell';
import styles from './index.module.scss';

const NAME_COLUMN_WIDTH_PX = 170;
const MONITOR_COLUMN_WIDTH_PX = 108;
const TARGET_COLUMN_WIDTH_PX = 136;

const AVAILABLE_COLORS = [50, 75, 100, 150];

const MONITOR_ROWS_SKELETONS_COUNT = 8;
const MONITOR_COLUMNS_SKELETONS_COUNT = 7;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Entries, to display in the table, which can be groups or actual entries
   */
  entries: AnyMonitorEntry[];
}

export const MonitorTable = React.forwardRef(
  ({ className, entries }: Props, ref) => {
    const [deleteMonitorLaunch] = useDeleteMonitorLaunchMutation();
    const { isLoading } = useSkeletonContext();

    const confirmDelete = useConfirm();

    // Calculate range borders for displaying gradient on cells with target value
    const valueRangesBordersDict = useMemo(() => {
      return entries.reduce<
        Record<
          string,
          | {
              minLesserValue: number;
              maxLesserValue: number;
              minGreaterValue: number;
              maxGreaterValue: number;
            }
          | undefined
        >
      >((acc, item) => {
        if (!isMonitorEntry(item)) return acc;

        const { target } = item;
        if (R.isNil(target)) return acc;

        const valuesLesserThanTarget = item.values
          .filter(
            (v): v is MonitorLaunchResultValueFragment =>
              isMonitorLaunchResultValue(v) && v.value < target
          )
          .map(R.prop('value'));
        const valuesGreaterOrEqualThanTarget = item.values
          .filter(
            (v): v is MonitorLaunchResultValueFragment =>
              isMonitorLaunchResultValue(v) && v.value >= target
          )
          .map(R.prop('value'));

        const rangeBorders = {
          minLesserValue: Math.min(...valuesLesserThanTarget),
          maxLesserValue: Math.max(...valuesLesserThanTarget),
          minGreaterValue: Math.min(...valuesGreaterOrEqualThanTarget),
          maxGreaterValue: Math.max(...valuesGreaterOrEqualThanTarget),
        };

        acc[item.id] = rangeBorders;
        return acc;
      }, {});
    }, [entries]);

    const allMonitorLaunches = R.uniqBy(
      R.path(['monitorLaunch', 'id']),
      entries.filter(isMonitorEntry).flatMap(e => e.values)
    );

    const allMonitorLaunchesWithPlaceholders = isLoading
      ? getSkeletonPlaceholders(MONITOR_COLUMNS_SKELETONS_COUNT)
      : allMonitorLaunches;

    const valueColumnConfigs = allMonitorLaunchesWithPlaceholders.map<
      TableColumnConfig<AnyMonitorEntry | SkeletonPlaceholder>
    >(monitorLaunchItem => {
      if (isSkeletonPlaceholder(monitorLaunchItem)) {
        return {
          width: MONITOR_COLUMN_WIDTH_PX,
          title: monitorLaunchItem.id,
          key: monitorLaunchItem.id,
          cellTypographyProps: (_, index) => {
            if (index === 0) {
              return { withSkeleton: false };
            }
            return undefined;
          },
          cellClassName: (_, index) =>
            index === 0 && 'background-neutral-opaque-container-default',
        };
      }
      const {
        monitorLaunch: { id: launchId, happenedAt },
      } = monitorLaunchItem;

      const formattedHappenedAt = formatDate(
        happenedAt,
        DateFormats.dayAndMonthShort
      );

      const getCurrentValue = (item: MonitorEntryFragment) =>
        item.values.find(v => v.monitorLaunch.id === launchId);

      const isInCalculationProcess =
        isMonitorLaunchCalculatingValue(monitorLaunchItem);

      return {
        title: formattedHappenedAt,
        functionButtonProps: isInCalculationProcess
          ? undefined
          : {
              iconVariant: IconVariants.trash,
              variant: FunctionButtonVariants.primary,
              tooltip: 'Удалить расчёт',
              tooltipProps: {
                contentClassName: '-mt-4',
              },
              iconProps: {
                size: SizeVariants.size24,
              },
              onPress: () =>
                confirmDelete({
                  title: `Удаление расчёта`,
                  message: (
                    <div className="grid gap-12">
                      <Typography
                        tag="p"
                        variant={TypographyVariants.bodySmall}
                      >
                        Вы хотите удалить расчёт за{' '}
                        <Typography
                          variant={TypographyVariants.bodySmallStrong}
                        >
                          {formatDate(happenedAt, DateFormats.dayAndMonth)}
                        </Typography>
                        ?
                      </Typography>
                      <Typography
                        tag="p"
                        variant={TypographyVariants.bodySmall}
                      >
                        Это действие невозможно отменить.
                      </Typography>
                    </div>
                  ),
                  isDelete: true,
                }).then(isConfirmed => {
                  if (!isConfirmed) return;

                  deleteMonitorLaunch({
                    variables: {
                      id: launchId,
                    },
                    optimisticResponse: { deleteMonitorLaunch: null },
                    update: makeUpdateMonitorQuery(draft => {
                      draft.monitor.edges.forEach(draftEdge => {
                        if (!isMonitorEntry(draftEdge.node)) return;

                        const removedMonitorLaunchIndex =
                          draftEdge.node.values.findLastIndex(
                            ({ monitorLaunch }) => monitorLaunch.id === launchId
                          );

                        draftEdge.node.values.splice(
                          removedMonitorLaunchIndex,
                          1
                        );
                      });
                    }),
                  });
                }),
            },
        key: launchId,
        headerClassName: 'position-relative',
        width: MONITOR_COLUMN_WIDTH_PX,
        cellClassName: item => {
          if (isSkeletonPlaceholder(item)) return undefined;

          if (!isMonitorEntry(item)) {
            return 'background-neutral-opaque-container-default';
          }

          const currentValue = getCurrentValue(item);

          if (!currentValue || isMonitorLaunchErrorValue(currentValue)) {
            return styles.errorCell;
          }

          const currentRageBorders = valueRangesBordersDict[item.id];
          let backgroundClassName;
          if (
            currentRageBorders &&
            isMonitorLaunchResultValue(currentValue) &&
            !R.isNil(item.target)
          ) {
            const isLesserThanTarget = currentValue.value < item.target;

            const currentBackgroundColor = isLesserThanTarget
              ? 'error'
              : 'success';

            const currentRangeMin = isLesserThanTarget
              ? currentRageBorders.minLesserValue
              : currentRageBorders.minGreaterValue;
            const currentRangeMax = isLesserThanTarget
              ? currentRageBorders.maxLesserValue
              : currentRageBorders.maxGreaterValue;

            const shadeIndex = Math.round(
              normalizeBetweenTwoRanges(
                currentValue.value,
                currentRangeMin,
                currentRangeMax,
                0,
                AVAILABLE_COLORS.length - 1
              )
            );

            let colorShade = AVAILABLE_COLORS[shadeIndex];
            // Positive values should be from lighter for lesser values to darker for bigger ones
            if (isLesserThanTarget) {
              colorShade = R.reverse(AVAILABLE_COLORS)[shadeIndex];
            }
            backgroundClassName = `background-${currentBackgroundColor}-${colorShade}`;
          }

          return clsx(
            'position-relative',
            backgroundClassName,
            isMonitorLaunchResultValue(currentValue) && 'ellipsis'
          );
        },
        renderCellContent: item => {
          if (isSkeletonPlaceholder(item)) return undefined;

          if (!isMonitorEntry(item)) return '';

          const currentValue = getCurrentValue(item);

          if (!currentValue) return MDASH;

          if (isMonitorLaunchCalculatingValue(currentValue)) {
            return <DotsLoader />;
          }

          if (isMonitorLaunchErrorValue(currentValue)) {
            return (
              <Tooltip content={currentValue.message}>
                <div>&mdash;</div>
              </Tooltip>
            );
          }

          return currentValue.value;
        },
      };
    });

    const columnConfigs: TableColumnConfig<
      AnyMonitorEntry | SkeletonPlaceholder
    >[] = [
      {
        title: 'Показатель',
        key: 'name',
        renderCellContent: item => {
          if (isSkeletonPlaceholder(item)) return undefined;

          return (
            <div
              {...{
                className: isMonitorEntry(item) ? 'ellipsis' : styles.groupName,
                title: item.name,
              }}
            >
              {item.name}
            </div>
          );
        },
        width: NAME_COLUMN_WIDTH_PX,
        columnClassName: styles.nameCell,
        cellTypographyProps: {
          className: 'col-span-full',
        },
        cellClassName: (item, index) => {
          return (
            ((!isSkeletonPlaceholder(item) && !isMonitorEntry(item)) ||
              (isSkeletonPlaceholder(item) && index === 0)) &&
            'background-neutral-opaque-container-default'
          );
        },
      },
      ...valueColumnConfigs,
      {
        title: 'Целевое значение',
        key: 'target',
        columnClassName: styles.targetCell,
        cellClassName: (item, index) => {
          return (
            ((!isSkeletonPlaceholder(item) && !isMonitorEntry(item)) ||
              (isSkeletonPlaceholder(item) && index === 0)) &&
            'background-neutral-opaque-container-default'
          );
        },
        width: TARGET_COLUMN_WIDTH_PX,
        renderCellContent: item => {
          if (isSkeletonPlaceholder(item)) return undefined;

          if (!isMonitorEntry(item)) return '';

          return (
            <MonitorEntryTargetCell
              {...{
                monitorEntry: item,
              }}
            />
          );
        },
      },
    ];

    return (
      <Table<AnyMonitorEntry>
        {...{
          ref,
          className: clsx(className, 'min-w-full w-min'),
          items: entries,
          columnConfigs,
          withBorder: false,
          noItemsMessage: null,
          isLoading,
          skeletonItemsCount: MONITOR_ROWS_SKELETONS_COUNT,
        }}
      />
    );
  }
);

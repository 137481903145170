import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

/**
 * Possible
 */
export enum FeedbackVariants {
  neutral = 'neutral',
  info = 'info',
  error = 'error',
}

export interface FeedbackProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Feedback content
   */
  content: ReactNode;
  /**
   * Level of feedback
   */
  variant?: FeedbackVariants;
}

export const FieldFeedback: React.FC<FeedbackProps> = ({
  className,
  content,
  variant = FeedbackVariants.info,
}) => {
  const { t } = useTranslation([MaslovNamespaces.validation]);

  let localizedFeedback =
    typeof content === 'string' ? t(content, content) : content;
  if (
    content &&
    typeof content === 'object' &&
    'string' in content &&
    'values' in content
  ) {
    localizedFeedback = t(content.string as string, content.values as object);
  }

  return (
    <Typography
      variant={TypographyVariants.descriptionLarge}
      tag="div"
      className={clsx(className, 'ellipsis', {
        'text-muted': variant === FeedbackVariants.neutral,
        'text-info': variant === FeedbackVariants.info,
        'text-error': variant === FeedbackVariants.error,
      })}
    >
      {localizedFeedback}
    </Typography>
  );
};

import { SelectThemes } from '~/shared/components/Select';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { FarmFragment } from '../gql/fragments/farm.graphql';
import { useFarmSelect } from './useFarmSelect';

const ALL_FARMS_ID = 'allFarms';

/**
 * Hook for reusing farms filter
 */
export const useFarmsFilter = (withAllFarms: boolean = true) => {
  const { farmId, setFarmId } = useSearchParamsState({
    farmId: undefined,
  });

  const innerFarmId = farmId === undefined ? ALL_FARMS_ID : farmId;

  // Farms filter
  const {
    items: farmSelectItems,
    renderSelectElement: renderFarmsSelectElementInner,
  } = useFarmSelect({
    selectProps: {
      label: 'Ферма',
      className: 'default-control',
      theme: SelectThemes.light,
      name: 'farmId',
      rawValue: innerFarmId,
      onValueChange: farm =>
        setFarmId(farm?.id === ALL_FARMS_ID ? undefined : farm?.id),
    },
    onFirstLoad: items => {
      if (!withAllFarms) {
        setFarmId(items[0].id);
      }
    },
  });
  const farmItems: FarmFragment[] = [
    ...wrapConditionalArrayElement<FarmFragment>(
      withAllFarms && {
        __typename: 'Farm',
        id: ALL_FARMS_ID,
        name: 'Все фермы',
        number: '',
      }
    ),
    ...farmSelectItems,
  ];

  const renderFarmsSelectElement: typeof renderFarmsSelectElementInner =
    props => renderFarmsSelectElementInner({ items: farmItems, ...props });

  const selectedFarmId = withAllFarms
    ? farmId
    : farmId ?? farmSelectItems.at(0)?.id;

  return {
    farmId: selectedFarmId,
    renderFarmsSelectElement,
  };
};

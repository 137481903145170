import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { TestMilkingUploadFragmentDoc } from '../fragments/testMilkingUpload.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestMilkingUploadsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TestMilkingUploadsQuery = { __typename?: 'Query', testMilkingUploads: { __typename?: 'TestMilkingUploadConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TestMilkingUploadEdge', cursor: string, node: { __typename: 'TestMilkingUpload', id: string, happenedAt: any, cowsCount: number, hasUploadIssues: boolean, testMilkings: Array<{ __typename: 'TestMilking', id: string, happenedAt: any, daysInMilk: number, weightKilograms: number, fatPercent?: number | null, proteinPercent?: number | null, sccThousandsPerMl?: number | null, ureaMgPerDl?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: any } | null, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any } }> } }> } };


export const TestMilkingUploadsDocument = gql`
    query testMilkingUploads($after: String, $first: Int) {
  testMilkingUploads(after: $after, first: $first) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TestMilkingUpload
      }
    }
  }
}
    ${TestMilkingUploadFragmentDoc}`;

/**
 * __useTestMilkingUploadsQuery__
 *
 * To run a query within a React component, call `useTestMilkingUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestMilkingUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestMilkingUploadsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTestMilkingUploadsQuery(baseOptions?: Apollo.QueryHookOptions<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>(TestMilkingUploadsDocument, options);
      }
export function useTestMilkingUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>(TestMilkingUploadsDocument, options);
        }
export function useTestMilkingUploadsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>(TestMilkingUploadsDocument, options);
        }
export type TestMilkingUploadsQueryHookResult = ReturnType<typeof useTestMilkingUploadsQuery>;
export type TestMilkingUploadsLazyQueryHookResult = ReturnType<typeof useTestMilkingUploadsLazyQuery>;
export type TestMilkingUploadsSuspenseQueryHookResult = ReturnType<typeof useTestMilkingUploadsSuspenseQuery>;
export type TestMilkingUploadsQueryResult = Apollo.QueryResult<TestMilkingUploadsQuery, TestMilkingUploadsQueryVariables>;
import React from 'react';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Input } from '~/shared/components/Input';

import formStyles from '~/styles/modules/form.module.scss';

import { EditDiseaseFormType } from '../../hooks/useDiseaseForm';
import { EventAsyncSelect } from '../EventAsyncSelect';

interface Props
  extends Pick<UseModalStepFormInterface<EditDiseaseFormType>, 'formProps'> {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditDiseaseForm: React.FC<Props> = ({ className, formProps }) => {
  return (
    <Form
      {...{
        className: clsx(formStyles.singleColumnForm, className),
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'name',
          label: 'Название заболевания',
          placeholder: 'Например, Мастит',
          isRequired: true,
        }}
      />
      <EventAsyncSelect
        {...{
          queryOptions: {
            variables: {
              kinds: [EventKindEnum.Protocol],
            },
          },
          name: 'protocolIDs',
          isMulti: true,
          label: 'Протокол лечения',
          placeholder: 'Выберите протокол',
        }}
      />
    </Form>
  );
};

import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { useNotifications } from '~/services/notifications';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId } from '~/shared/helpers/string';

import { EMPLOYEE_SCHEMA } from '~/entities/employees';

import panelStyles from '~/styles/modules/panel.module.scss';

import { EditCompanyFormType } from '../..';
import companyFieldArraysStyles from '../../companyFieldArrays.module.scss';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditEmployeesArrayFieldsForm: React.FC<Props> = ({
  className,
}) => {
  const { sendNeutralToast } = useNotifications();

  const formContext = useFormContext<EditCompanyFormType>();

  const {
    fields: employeesArrayItems,
    remove: removeFromEmployeesArray,
    append: appendToEmployeesArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'employees',
    keyName: 'uniqKey',
  });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    theme: ButtonThemes.accent,
    variant: ButtonVariants.secondary,
    onPress: () =>
      appendToEmployeesArray({
        ...EMPLOYEE_SCHEMA.getDefault(),
        id: getTmpId('employee'),
      }),
    children: 'Добавить сотрудника',
  } satisfies ButtonProps;

  if (!employeesArrayItems.length) {
    return (
      <div className={clsx('p-24', className, panelStyles.panel)}>
        <DataBlockedMessage
          {...{
            className: 'p-24',
            message: 'Сотрудники пока не добавлены',
            buttonProps: addButtonProps,
          }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={companyFieldArraysStyles.tableHeader}>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Номер
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Фамилия
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Имя
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Отчество
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Комментарий
        </Typography>
      </div>
      {employeesArrayItems.map((employeeFields, employeeIndex) => (
        <div
          key={employeeFields.id}
          className={companyFieldArraysStyles.tableRow}
        >
          <Input
            {...{
              name: `employees.${employeeIndex}.number`,
              className: companyFieldArraysStyles.input,
              placeholder: '№',
            }}
          />
          <Input
            {...{
              name: `employees.${employeeIndex}.lastName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `employees.${employeeIndex}.firstName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `employees.${employeeIndex}.middleName`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <Input
            {...{
              name: `employees.${employeeIndex}.comment`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <FunctionButton
            {...{
              className: companyFieldArraysStyles.deleteButton,
              iconVariant: IconVariants.trash,
              onPress: () => {
                removeFromEmployeesArray(employeeIndex);
                sendNeutralToast('Сотрудник удалён');
              },
            }}
          />
        </div>
      ))}
      <Button
        {...{
          ...addButtonProps,
          size: ButtonSizes.small24,
        }}
      />
    </div>
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportSettingsValueFragmentDoc } from './customReportSettingsValue.graphql';
import { SourceFieldFragmentDoc } from '../../../blueprintValues/gql/fragments/sourceField.graphql';
export type CustomReportChartXAxis_CustomReportChartValueAxis_Fragment = { __typename: 'CustomReportChartValueAxis', valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string } } };

export type CustomReportChartXAxis_CustomReportChartFieldAxis_Fragment = { __typename: 'CustomReportChartFieldAxis', blueprintSourceField: { __typename: 'SourceField', id: string, name: string } };

export type CustomReportChartXAxisFragment = CustomReportChartXAxis_CustomReportChartValueAxis_Fragment | CustomReportChartXAxis_CustomReportChartFieldAxis_Fragment;

export const CustomReportChartXAxisFragmentDoc = gql`
    fragment CustomReportChartXAxis on CustomReportChartXAxis {
  __typename
  ... on CustomReportChartValueAxis {
    valueKey {
      ...CustomReportSettingsValue
    }
  }
  ... on CustomReportChartFieldAxis {
    blueprintSourceField {
      ...SourceField
    }
  }
}
    ${CustomReportSettingsValueFragmentDoc}
${SourceFieldFragmentDoc}`;
import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationFragmentDoc } from '../../../inseminations/gql/fragments/insemination.graphql';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
export type CowDefaultEventFragment = { __typename?: 'CowDefaultEvent', id: string, daysInPregnancy?: number | null, retirementReason?: Types.CowRetirementReason | null, event: { __typename?: 'DefaultEvent', name: string, shortcode: Types.DefaultEventEnum, kind: Types.EventKindEnum }, penGroupFrom?: { __typename: 'PenGroup', id: string, name: string } | null, penGroupTo?: { __typename: 'PenGroup', id: string, name: string } | null, optionalProtocol?: { __typename: 'Protocol', id: string, name: string } | null, weighing?: { __typename?: 'Weighing', weightKilograms: number } | null, heightMeasurement?: { __typename?: 'HeightMeasurement', heightCentimeters: number } | null, bodyConditionScore?: { __typename?: 'BodyConditionScore', bodyConditionScore: number } | null, insemination?: { __typename: 'Insemination', id: string, result: Types.InseminationResult, daysInMilk: number, happenedAt: any, cow: { __typename: 'Cow', retiredAt?: any | null, id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: any | null, bullState: Types.BullState, bullDateOfBirth?: any | null }, employee?: { __typename: 'Employee', id: string, firstName: string, lastName: string } | null, scheme?: { __typename: 'InseminationScheme', id: string, name: string } | null } | null, calving?: { __typename: 'Calving', id: string, results: Array<{ __typename: 'CalvingResult', id: string, isBull: boolean, isDead: boolean }>, calfCows: Array<{ __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }> } | null };

export const CowDefaultEventFragmentDoc = gql`
    fragment CowDefaultEvent on CowDefaultEvent {
  id
  event {
    name
    shortcode
    kind
  }
  penGroupFrom {
    __typename
    id
    name
  }
  penGroupTo {
    __typename
    id
    name
  }
  optionalProtocol: protocol {
    __typename
    id
    name
  }
  daysInPregnancy
  retirementReason
  weighing {
    weightKilograms
  }
  heightMeasurement {
    heightCentimeters
  }
  bodyConditionScore {
    bodyConditionScore
  }
  insemination {
    ...Insemination
  }
  calving {
    __typename
    id
    results {
      __typename
      id
      isBull
      isDead
    }
    calfCows {
      ...CowShort
    }
  }
}
    ${InseminationFragmentDoc}
${CowShortFragmentDoc}`;
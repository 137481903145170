import { ApolloClient, InMemoryCache } from '@apollo/client';

import fragmentMatcher from '../../__generated__/fragment-matcher.json';
import { GqlAuthProvider, HandleGqlError } from '../../types';
import { makeMainLink } from './makeMainLink';
import { typePolicies } from './typePolicies';

/**
 * Creates apollo client
 */
export const makeApolloClient = (
  apolloUri: string,
  gqlAuthProvider: GqlAuthProvider,
  onError: HandleGqlError
) => {
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: makeMainLink(apolloUri, gqlAuthProvider, onError),
    cache: new InMemoryCache({
      addTypename: false,
      resultCaching: true,
      typePolicies,
      possibleTypes: fragmentMatcher.possibleTypes,
    }),
  });
};

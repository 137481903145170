import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MonitorLaunchErrorValueFragment = { __typename: 'MonitorLaunchErrorValue', message: string, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: any } };

export const MonitorLaunchErrorValueFragmentDoc = gql`
    fragment MonitorLaunchErrorValue on MonitorLaunchErrorValue {
  __typename
  monitorLaunch {
    __typename
    id
    happenedAt
  }
  message
}
    `;
import React from 'react';

import { useConfirm } from '~/services/modals';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { formatCow } from '~/entities/cows';

import { CowNormalizedEvent } from '../types';

/**
 * Hook for cow event rollback confirmation
 */
export const useConfirmRollbackEvent = () => {
  const confirmDelete = useConfirm();

  const confirmRollback = (
    event: CowNormalizedEvent,
    hasSeveralInjections: boolean = false
  ) =>
    confirmDelete({
      title: 'Удаление события',

      calloutMessage: hasSeveralInjections
        ? 'Событие содержит несколько инъекций'
        : undefined,
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить событие{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {event.name}
            </Typography>{' '}
            у животного{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatCow(event.originalCowEvent.cow, { prefix: '' })}
            </Typography>
            ?
          </Typography>
          {hasSeveralInjections && (
            <Typography tag="p" variant={TypographyVariants.bodySmall}>
              Все инъекции, входящие в это событие, будут удалены.
            </Typography>
          )}
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      submitButtonProps: {
        children: 'Подтвердить',
      },
    });

  return confirmRollback;
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupDetailedFragmentDoc } from '../fragments/penGroupDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePenGroupMutationVariables = Types.Exact<{
  input: Types.CreatePenGroupInput;
}>;


export type CreatePenGroupMutation = { __typename?: 'Mutation', createPenGroup: { __typename: 'PenGroup', id: string, identifier: any, name: string, isMilking: boolean, isDry: boolean, isHospital: boolean, isCalf: boolean, isArtificialInsemination: boolean, isWithBull: boolean, isUser: boolean, capacity?: number | null, occupied: number, occupiedPercent?: number | null, remaining?: number | null, cows: Array<{ __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }>, farm: { __typename: 'Farm', id: string, name: string, number: string } } };


export const CreatePenGroupDocument = gql`
    mutation createPenGroup($input: CreatePenGroupInput!) {
  createPenGroup(input: $input) {
    ...PenGroupDetailed
  }
}
    ${PenGroupDetailedFragmentDoc}`;
export type CreatePenGroupMutationFn = Apollo.MutationFunction<CreatePenGroupMutation, CreatePenGroupMutationVariables>;

/**
 * __useCreatePenGroupMutation__
 *
 * To run a mutation, you first call `useCreatePenGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePenGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPenGroupMutation, { data, loading, error }] = useCreatePenGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePenGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreatePenGroupMutation, CreatePenGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePenGroupMutation, CreatePenGroupMutationVariables>(CreatePenGroupDocument, options);
      }
export type CreatePenGroupMutationHookResult = ReturnType<typeof useCreatePenGroupMutation>;
export type CreatePenGroupMutationResult = Apollo.MutationResult<CreatePenGroupMutation>;
export type CreatePenGroupMutationOptions = Apollo.BaseMutationOptions<CreatePenGroupMutation, CreatePenGroupMutationVariables>;
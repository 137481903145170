import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CompanyDetailedFragmentDoc } from '../fragments/companyDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompaniesDetailedQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type CompaniesDetailedQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', pageInfo: { __typename?: 'BeriaPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'Company', id: string, name: string, isLockedForWrite: boolean, licensedBy: any, tzIdentifier: string, farms: { __typename?: 'CompanyFarmConnection', nodes: Array<{ __typename: 'Farm', id: string, name: string, number: string }> }, employees: { __typename?: 'EmployeeTypeConnection', nodes: Array<{ __typename: 'Employee', id: string, comment: string, number: string, firstName: string, middleName: string, lastName: string, company: { __typename: 'Company', id: string } }> }, users: { __typename?: 'CompanyUserConnection', edges: Array<{ __typename?: 'CompanyUserEdge', node: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, farmsInCompany: Array<{ __typename: 'Farm', id: string, name: string, number: string }> }> } }> } };


export const CompaniesDetailedDocument = gql`
    query companiesDetailed($ids: [ID!]) {
  companies(ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...CompanyDetailed
    }
  }
}
    ${CompanyDetailedFragmentDoc}`;

/**
 * __useCompaniesDetailedQuery__
 *
 * To run a query within a React component, call `useCompaniesDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesDetailedQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompaniesDetailedQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>(CompaniesDetailedDocument, options);
      }
export function useCompaniesDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>(CompaniesDetailedDocument, options);
        }
export function useCompaniesDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>(CompaniesDetailedDocument, options);
        }
export type CompaniesDetailedQueryHookResult = ReturnType<typeof useCompaniesDetailedQuery>;
export type CompaniesDetailedLazyQueryHookResult = ReturnType<typeof useCompaniesDetailedLazyQuery>;
export type CompaniesDetailedSuspenseQueryHookResult = ReturnType<typeof useCompaniesDetailedSuspenseQuery>;
export type CompaniesDetailedQueryResult = Apollo.QueryResult<CompaniesDetailedQuery, CompaniesDetailedQueryVariables>;
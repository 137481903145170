import React, { ReactNode } from 'react';

import { ServicesContext } from '~/~legacy/components/ServicesContext';
import { ClientErrorPage } from '~/apps/chicherin/pages';

interface Props {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  rerender: boolean;
}

export class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      rerender: false,
    };

    this.resetError = this.resetError.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    const hasChunk = error.message.indexOf('chunk') !== -1;
    const hasFailed = error.message.indexOf('failed') !== -1;
    const hasMissing = error.message.indexOf('missing:') !== -1;

    const rerender = hasChunk && hasFailed && hasMissing;

    return { hasError: true, error, rerender };
  }

  resetError() {
    this.setState(prev => ({ ...prev, hasError: false }));
  }

  render() {
    if (this.state.rerender) {
      window.location.reload();
    }

    if (this.state.hasError) {
      return <ClientErrorPage onUpdatePage={this.resetError} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.contextType = ServicesContext;

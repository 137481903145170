import React from 'react';

import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { Checkbox } from '~/shared/components/Checkbox';
import { Input, InputVariants } from '~/shared/components/Input';

import { usePenGroupSelect } from '~/entities/penGroups';

import formStyles from '~/styles/modules/form.module.scss';

import { EditProtocolFormType } from '../../hooks/useProtocolForm';

interface Props
  extends Pick<UseModalStepFormInterface<EditProtocolFormType>, 'formProps'> {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditProtocolForm: React.FC<Props> = ({ className, formProps }) => {
  const { formContext } = formProps;

  const shouldTransferToAnotherPenGroup = formContext.watch(
    'shouldTransferToAnotherPenGroup'
  );

  const { renderSelectElement: renderPenGroupIdSelectElement } =
    usePenGroupSelect({ selectProps: { name: 'penGroupID' } });

  return (
    <Form
      {...{
        className: clsx(formStyles.singleColumnForm, className),
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'name',
          label: 'Название протокола лечения',
          placeholder: 'Например, Лечение Язвы',
          isRequired: true,
        }}
      />
      <Input
        {...{
          name: 'expectedDaysOnProtocol',
          variant: InputVariants.int,
          label: 'Количество дней на протоколе',
          placeholder: 'Введите количество дней',
        }}
      />
      <Checkbox
        {...{
          name: 'shouldTransferToAnotherPenGroup',
          onValueChange: newValue => {
            if (!newValue) {
              formContext.setValue('penGroupID', null);
            }
          },
          label: 'Происходит перевод в группу',
        }}
      />
      {shouldTransferToAnotherPenGroup && renderPenGroupIdSelectElement()}
    </Form>
  );
};

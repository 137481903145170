import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { CloseButton } from '~/shared/components/CloseButton';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { Icon } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';

import { NOTIFICATION_ICON_VARIANTS_DICT } from '../../constants';
import notificationStyles from '../../notifications.module.scss';
import { NotificationProps, NotificationVariants } from '../../types';
import styles from './index.module.scss';

/**
 * Possible sizes for a Callout
 */
export enum CalloutSizes {
  small8 = 'small8',
  medium16 = 'medium16',
}

export interface CalloutProps extends NotificationProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Header title to display
   */
  title?: ReactNode;
  /**
   * Subheader to display (default to message prop)
   */
  subtitle?: ReactNode;
  /**
   * Size for the Callout padding
   */
  size?: CalloutSizes;
}

export const Callout: React.FC<CalloutProps> = ({
  className,
  variant = NotificationVariants.warning,
  message,
  title,
  subtitle = message,
  onClose,
  size = CalloutSizes.medium16,
  withCloseButton = !!onClose,
  functionButtonProps = [],
}) => {
  const functionButtonPropsArray = normalizeValuesToArray(functionButtonProps);
  const withFunctionButtons = !!functionButtonPropsArray?.length;

  return (
    <div
      className={clsx(
        styles.root,
        styles[size],
        notificationStyles[variant],
        className
      )}
    >
      <Icon
        className={styles.icon}
        variant={NOTIFICATION_ICON_VARIANTS_DICT[variant]}
      />
      <div
        className={clsx(
          'flex flex-col gap-4 ml-12 mr-a py-2',
          styles.textWrapper
        )}
      >
        {!!title && (
          <Typography tag="h4" variant={TypographyVariants.bodySmallStrong}>
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography variant={TypographyVariants.bodySmall}>
            {subtitle}
          </Typography>
        )}
      </div>
      {withFunctionButtons &&
        functionButtonPropsArray.map((buttonProps, buttonIndex) => (
          <FunctionButton
            {...{
              key: buttonIndex,
              className: styles.functionButtonItem,
              ...buttonProps,
            }}
          />
        ))}
      {withCloseButton && <CloseButton onPress={onClose} />}
    </div>
  );
};

import React, { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { Brand } from '../Brand';
import { UserMenu } from '../UserMenu';
import styles from './index.module.scss';

export interface HeaderLink {
  key: string;
  value: ReactNode;
}

interface Props {
  /**
   * Company select element
   */
  companySelector: ReactElement;
  /**
   * Animal select element
   */
  quickSearch: ReactNode;
  /**
   * Header link items
   */
  links: HeaderLink[];
}

export const Header: React.FC<Props> = ({
  links,
  companySelector,
  quickSearch,
}) => {
  const navLinks = links.map(link => (
    <NavLink
      key={link.key}
      className={({ isActive }) =>
        clsx(styles.navLinkItem, isActive && styles.activeNavLink)
      }
      to={link.key}
    >
      <Typography
        className="flex items-center gap-8"
        variant={TypographyVariants.bodySmall}
      >
        {link.value}
      </Typography>
    </NavLink>
  ));

  return (
    <header className={clsx(styles.root, 'mx-16')}>
      <Brand className="mx-8" />
      <div className={styles.companySelector}>{companySelector}</div>
      <div className="flex-1">{navLinks}</div>
      <div className="mr-16">{quickSearch}</div>
      <div className="flex justify-end">
        <UserMenu />
      </div>
    </header>
  );
};

import { relayStylePagination } from '@apollo/client/utilities';

import { TypedTypePolicies } from '../../__generated__/typedTypePolicies';

const replaceMerge = (existing: unknown, incoming: unknown) => incoming;

export const typePolicies = {
  Query: {
    fields: {
      calendarEntries: relayStylePagination([
        'since',
        'till',
        'roleIDs',
        'search',
        'viewKind',
      ]),
      penGroups: relayStylePagination([
        'search',
        'ids',
        'isWithBull',
        'isArtificialInsemination',
        'isMilking',
        'isDry',
        'isHospital',
        'isCalf',
        'isUser',
      ]),
      testMilkingUploads: relayStylePagination(),
      testMilkings: relayStylePagination(['testMilkingUploadIDs']),
      companies: relayStylePagination(['search', 'ids']),
      cows: relayStylePagination([
        'search',
        'ids',
        'identifiers',
        'vitalityFilter',
        'farmIDs',
      ]),
      bulls: relayStylePagination(['search', 'ids', 'excludeIDs']),
      semenDoses: relayStylePagination(['search']),
      monitor: relayStylePagination(['since', 'till']),
      monitorGroups: relayStylePagination(['search']),
      blueprints: relayStylePagination([
        'search',
        'canBeUsedInMonitor',
        'canBeUsedInCustomReport',
        'roleIDs',
        'runType',
        'viewKind',
        'ids',
      ]),
      farms: relayStylePagination(['search']),
      events: relayStylePagination([
        'search',
        'kinds',
        'ids',
        'withDefault',
        'cowIDs',
      ]),
      cowEvents: relayStylePagination([
        'cowIDs',
        'search',
        'since',
        'till',
        'kinds',
        'blueprintLaunchID',
        'eventIDs',
      ]),
      blueprintLaunches: relayStylePagination(['since', 'till', 'search']),
      analytics: {
        merge: true,
      },
      cowPlannedInjections: relayStylePagination(),
      milkingParlors: relayStylePagination(['farmIDs']),
      customReports: relayStylePagination(['ids']),
      cowsCopyKeys: { keyArgs: ['reason', 'isActivated'] },
    },
  },
  Cow: {
    merge: true,
  },
  CowStaticField: {
    keyFields: ['kind'],
  },
  CowCalculatedField: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['blueprintSourceField', ['id'] as any],
  },
  CalendarDivider: {
    keyFields: ['date'],
  },
  CowsCopyKey: {
    keyFields: ['key'],
  },
  DefaultEvent: {
    keyFields: ['shortcode'],
  },
  CalendarEntry: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['date', 'blueprint', ['id'] as any],
  },
  TestMilkingUpload: {
    merge: true,
    fields: {
      testMilkings: {
        merge: replaceMerge,
      },
    },
  },
  MonitorEntry: {
    merge: true,
    fields: {
      values: {
        merge: replaceMerge,
      },
    },
  },
  ConceptionRateChartBullCandle: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['bull', ['id'] as any],
  },
  ConceptionRateChartDaysInMilkCandle: {
    keyFields: ['daysInMilk'],
  },
  ConceptionRateChartInseminationNumberCandle: {
    keyFields: ['inseminationNumber'],
  },
  ConceptionRateChartStudCodeCandle: {
    keyFields: ['studCode'],
  },
  ConceptionRateChartInseminationSchemeCandle: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['inseminationScheme', ['id'] as any],
  },
  ConceptionRateChartEmployeeCandle: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['employee', ['id'] as any],
  },
  CowPlannedInjections: {
    // graphql codegen doesn't generate typings for nested key fields,
    // so we use as any for arrays - https://github.com/dotansimha/graphql-code-generator/issues/5025
    keyFields: ['cowProtocol', ['id'] as any],
  },
  LivestockForecastPeriod: {
    keyFields: ['since', 'farmIDs'],
  },
  LivestockForecastMonth: {
    keyFields: ['forecastAt', 'farmIDs'],
  },
  MilkingParlor: {
    merge: true,
    fields: {
      intervals: {
        merge: replaceMerge,
      },
    },
  },
  CustomReportLaunchResult: {
    keyFields: ['hashID'],
  },
  CustomReport: {
    merge: true,
    fields: {
      chartsSettings: {
        merge: replaceMerge,
      },
    },
  },
  CustomReportChartSettings: {
    merge: true,
    fields: {
      yAxes: {
        merge: replaceMerge,
      },
    },
  },
} satisfies TypedTypePolicies;

/**
 * Type with all queries, for which type policies are defined
 */
export type QueryFieldsWithPolicies =
  keyof (typeof typePolicies)['Query']['fields'];

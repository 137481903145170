import React from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { GetPathRelativeToCompany } from '~/services/navigation';

import { MPageContainer } from '~/shared/components/MPageContainer';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { ApplicationShell } from '../../components/ApplicationShell';
import { AnalyticsPageContainer } from '../components/AnalyticsPageContainer';
import { EntitiesPageContainer } from '../components/EntitiesPageContainer';
import { EventsPageContainer } from '../components/EventsPageContainer';
import { HerdPageContainer } from '../components/HerdPageContainer';

const ProductionCalendarPage = React.lazy(() =>
  import('~/pages/user/productionCalendar').then(component => ({
    default: component.ProductionCalendarPage,
  }))
);

const IncidentsPage = React.lazy(() =>
  import('~/pages/user/incidents').then(component => ({
    default: component.IncidentsPage,
  }))
);

const PlannedInjectionsPage = React.lazy(() =>
  import('~/pages/user/plannedInjections').then(component => ({
    default: component.PlannedInjectionsPage,
  }))
);

const BlueprintExecutionPage = React.lazy(() =>
  import('~/pages/user/blueprint').then(component => ({
    default: component.BlueprintExecutionPage,
  }))
);

const LivestockForecastPage = React.lazy(() =>
  import('~/pages/user/analytics/livestockForecast').then(component => ({
    default: component.LivestockForecastPage,
  }))
);

const AnalyticsHerdPage = React.lazy(() =>
  import('~/pages/user/analytics/herd').then(component => ({
    default: component.AnalyticsHerdPage,
  }))
);

const AnalyticsReproductionPage = React.lazy(() =>
  import('~/pages/user/analytics/reproduction').then(component => ({
    default: component.AnalyticsReproductionPage,
  }))
);

const AnalyticsMilkingReportPage = React.lazy(() =>
  import('~/pages/user/analytics/milking').then(component => ({
    default: component.AnalyticsMilkingReportPage,
  }))
);

const HerdStateMetricPage = React.lazy(() =>
  import('~/pages/user/analytics/metric').then(component => ({
    default: component.HerdStateMetricPage,
  }))
);

const ReproductionTablePage = React.lazy(() =>
  import('~/pages/user/analytics/reproductionTable').then(component => ({
    default: component.ReproductionTablePage,
  }))
);

const AnalyticsMonitorPage = React.lazy(() =>
  import('~/pages/user/analytics/monitor').then(component => ({
    default: component.AnalyticsMonitorPage,
  }))
);

const CustomReportsPage = React.lazy(() =>
  import('~/pages/user/analytics/customReports').then(component => ({
    default: component.CustomReportsPage,
  }))
);

const CustomReportLaunchPage = React.lazy(() =>
  import('~/pages/user/analytics/customReportLaunch').then(component => ({
    default: component.CustomReportLaunchPage,
  }))
);

const CustomReportLaunchDetailPage = React.lazy(() =>
  import('~/pages/user/analytics/customReportLaunchDetail').then(component => ({
    default: component.CustomReportLaunchDetailPage,
  }))
);

const HerdPenGroupsPage = React.lazy(() =>
  import('~/pages/user/herd/penGroups').then(component => ({
    default: component.HerdPenGroupsPage,
  }))
);

const BullsPage = React.lazy(() =>
  import('~/pages/user/herd/bulls').then(component => ({
    default: component.BullsPage,
  }))
);

const CowPage = React.lazy(() =>
  import('~/pages/user/cow').then(component => ({
    default: component.CowPage,
  }))
);

const HerdCowsPage = React.lazy(() =>
  import('~/pages/user/herd/cows').then(component => ({
    default: component.HerdCowsPage,
  }))
);

const MoveCowsKeysPage = React.lazy(() =>
  import('~/pages/user/herd/moveCowsKeys').then(component => ({
    default: component.MoveCowsKeysPage,
  }))
);

const CowEventsPage = React.lazy(() =>
  import('~/pages/user/events/cowEvents').then(component => ({
    default: component.CowEventsPage,
  }))
);

const TestMilkingUploadsPage = React.lazy(() =>
  import('~/pages/user/entities/milkings').then(component => ({
    default: component.TestMilkingUploadsPage,
  }))
);

const MilkingParlorsPage = React.lazy(() =>
  import('~/pages/user/entities/milkingParlors').then(component => ({
    default: component.MilkingParlorsPage,
  }))
);

const MilkingsSchedulePage = React.lazy(() =>
  import('~/pages/user/entities/milkingsSchedule').then(component => ({
    default: component.MilkingsSchedulePage,
  }))
);

const MilkingParlorIntervalPage = React.lazy(() =>
  import('~/pages/user/entities/milkingParlorInterval').then(component => ({
    default: component.MilkingParlorIntervalPage,
  }))
);

const SemenDosesPage = React.lazy(() =>
  import('~/pages/user/entities/semenDoses').then(component => ({
    default: component.SemenDosesPage,
  }))
);

const AllEventsPage = React.lazy(() =>
  import('~/pages/user/events/all').then(component => ({
    default: component.AllEventsPage,
  }))
);

const CommandsPage = React.lazy(() =>
  import('~/pages/user/events/commands').then(component => ({
    default: component.CommandsPage,
  }))
);

// TODO rework router to use new createBrowserRouter API
// to allow to use loader function and returning redirect
const NavigateToCustomReportId = () => {
  const params = useParams();
  return (
    <Navigate replace to={`../?customReportId=${params.customReportId}`} />
  );
};

export const getUserRoutes = (
  getPathRelativeToCompany: GetPathRelativeToCompany
) => [
  <Route
    key="index"
    index
    element={
      <Navigate
        replace
        to={getPathRelativeToCompany(
          MASLOV_ROUTES_TREE.User.userProductionCalendar
        )}
      />
    }
  />,
  <Route
    key={MASLOV_ROUTES_TREE.User.root}
    path={MASLOV_ROUTES_TREE.User.root}
    element={<ApplicationShell />}
  >
    <Route
      index
      element={
        <Navigate
          replace
          to={getPathRelativeToCompany(
            MASLOV_ROUTES_TREE.User.userProductionCalendar
          )}
        />
      }
    />
    <Route
      path={MASLOV_ROUTES_TREE.User.productionCalendar}
      element={
        <MPageContainer>
          <ProductionCalendarPage />
        </MPageContainer>
      }
    />
    <Route
      path={MASLOV_ROUTES_TREE.User.blueprint}
      element={<BlueprintExecutionPage />}
    />
    <Route
      path={MASLOV_ROUTES_TREE.User.incidents}
      element={
        <MPageContainer>
          <IncidentsPage />
        </MPageContainer>
      }
    />
    <Route
      path={MASLOV_ROUTES_TREE.User.plannedInjections}
      element={
        <div
          className={layoutStyles.fixedContainer}
          style={{ paddingBottom: 64 }}
        >
          <PlannedInjectionsPage />
        </div>
      }
    />
    <Route
      path={MASLOV_ROUTES_TREE.User.entities.index}
      element={<EntitiesPageContainer />}
    >
      <Route
        index
        element={
          <Navigate
            replace
            to={MASLOV_ROUTES_TREE.User.entities.milkingParlors}
          />
        }
      />

      <Route path={MASLOV_ROUTES_TREE.User.entities.milkingParlors}>
        <Route index element={<MilkingParlorsPage />} />

        <Route path={MASLOV_ROUTES_TREE.User.entities.milkingsScheduleFarmId}>
          <Route
            index
            element={
              <Navigate
                replace
                to={MASLOV_ROUTES_TREE.User.entities.milkingsSchedule}
              />
            }
          />

          <Route
            path={MASLOV_ROUTES_TREE.User.entities.milkingsSchedule}
            element={<MilkingsSchedulePage />}
          />
        </Route>

        <Route path={MASLOV_ROUTES_TREE.User.entities.milkingParlorInterval}>
          <Route index element={<Navigate replace to="../" />} />

          <Route
            path={
              MASLOV_ROUTES_TREE.User.entities.milkingParlorIntervalIntervalId
            }
            element={<MilkingParlorIntervalPage />}
          />
        </Route>
      </Route>

      <Route
        path={MASLOV_ROUTES_TREE.User.entities.milkings}
        element={<TestMilkingUploadsPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.entities.semenDoses}
        element={<SemenDosesPage />}
      />
    </Route>

    <Route
      path={MASLOV_ROUTES_TREE.User.cow}
      element={
        <MPageContainer>
          <div className={layoutStyles.fixedContainer}>
            <CowPage />
          </div>
        </MPageContainer>
      }
    />

    <Route
      path={MASLOV_ROUTES_TREE.User.herd.index}
      element={<HerdPageContainer />}
    >
      <Route
        index
        element={<Navigate replace to={MASLOV_ROUTES_TREE.User.herd.cows} />}
      />
      <Route path={MASLOV_ROUTES_TREE.User.herd.cows}>
        <Route index element={<HerdCowsPage />} />
        <Route
          path={MASLOV_ROUTES_TREE.User.herd.moveCowsKeys}
          element={<MoveCowsKeysPage />}
        />
      </Route>
      <Route
        path={MASLOV_ROUTES_TREE.User.herd.penGroups}
        element={<HerdPenGroupsPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.herd.bulls}
        element={<BullsPage />}
      />
    </Route>
    <Route
      path={MASLOV_ROUTES_TREE.User.analytics.index}
      element={<AnalyticsPageContainer />}
    >
      <Route
        index
        element={
          <Navigate
            replace
            to={MASLOV_ROUTES_TREE.User.analytics.livestockForecast}
          />
        }
      />

      <Route
        path={MASLOV_ROUTES_TREE.User.analytics.livestockForecast}
        element={<LivestockForecastPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.analytics.herd}
        element={<AnalyticsHerdPage />}
      />
      <Route path={MASLOV_ROUTES_TREE.User.analytics.reproduction}>
        <Route index element={<AnalyticsReproductionPage />} />
        <Route
          path={MASLOV_ROUTES_TREE.User.analytics.reproductionTable}
          element={<ReproductionTablePage />}
        />
      </Route>

      <Route
        path={MASLOV_ROUTES_TREE.User.analytics.milking}
        element={<AnalyticsMilkingReportPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.analytics.metric}
        element={<HerdStateMetricPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.analytics.monitor}
        element={<AnalyticsMonitorPage />}
      />

      <Route path={MASLOV_ROUTES_TREE.User.analytics.customReports}>
        <Route index element={<CustomReportsPage />} />

        <Route path={MASLOV_ROUTES_TREE.User.analytics.customReportId}>
          <Route index element={<NavigateToCustomReportId />} />

          <Route path={MASLOV_ROUTES_TREE.User.analytics.customReportLaunch}>
            <Route index element={<CustomReportLaunchPage />} />

            <Route
              path={MASLOV_ROUTES_TREE.User.analytics.customReportLaunchDetail}
              element={<CustomReportLaunchDetailPage />}
            />
          </Route>
        </Route>
      </Route>
    </Route>

    <Route
      path={MASLOV_ROUTES_TREE.User.events.index}
      element={<EventsPageContainer />}
    >
      <Route
        index
        element={<Navigate replace to={MASLOV_ROUTES_TREE.User.events.all} />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.events.all}
        element={<AllEventsPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.events.cowEvents}
        element={<CowEventsPage />}
      />
      <Route
        path={MASLOV_ROUTES_TREE.User.events.commands}
        element={<CommandsPage />}
      />
    </Route>
  </Route>,
];

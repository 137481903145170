import React, { ReactNode } from 'react';

import { Brand } from '~/apps/chicherin/components/Brand';

import {
  Button,
  ButtonProps,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

const WavesImage = React.lazy(() =>
  import('./waves-image.svg').then(component => ({
    default: component.ReactComponent,
  }))
);

interface Props {
  /**
   * Title of error page
   */
  title: string;
  /**
   * Optional http error code
   */
  errorCode?: 404 | 500 | 503;
  /**
   * Optional description which will be placed under the errorType
   */
  description?: ReactNode;
  /**
   * Optional props for button on error page. If it's missing, there will be no button
   */
  buttonProps?: Omit<ButtonProps, 'ref'>;
  /**
   * Optional handler for Brand click event
   */
  onBrandClick?: () => void;
}

export const ErrorPage: React.FC<Props> = ({
  title,
  errorCode,
  description,
  buttonProps,
  onBrandClick: handleBrandClick,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.mainBlock}>
        <Brand onClick={handleBrandClick} />
        <div className="grid gap-24">
          <div>
            <Typography tag="div" variant={TypographyVariants.displaySmall}>
              {title}
            </Typography>
            {!!errorCode && (
              <Typography
                tag="div"
                className="text-muted"
                variant={TypographyVariants.bodyMedium}
              >
                {`Ошибка ${errorCode}`}
              </Typography>
            )}
          </div>
          <div className="grid gap-16">{description}</div>
        </div>
        {buttonProps && (
          <div>
            <Button
              {...{
                variant: ButtonVariants.secondary,
                theme: ButtonThemes.accent,
                ...buttonProps,
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.wavesImage}>
        <WavesImage />
      </div>
    </div>
  );
};

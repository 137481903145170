import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationShortFragmentDoc } from '../fragments/inseminationShort.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InseminationsShortQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type InseminationsShortQuery = { __typename?: 'Query', inseminations: { __typename?: 'InseminationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'InseminationEdge', cursor: string, node: { __typename: 'Insemination', id: string, happenedAt: any, movedToGroupWithBullAt?: any | null, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: any | null, bullState: Types.BullState, bullDateOfBirth?: any | null } } }> } };


export const InseminationsShortDocument = gql`
    query inseminationsShort($search: String, $first: Int, $after: String) {
  inseminations(first: $first, search: $search, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...InseminationShort
      }
    }
  }
}
    ${InseminationShortFragmentDoc}`;

/**
 * __useInseminationsShortQuery__
 *
 * To run a query within a React component, call `useInseminationsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useInseminationsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInseminationsShortQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInseminationsShortQuery(baseOptions?: Apollo.QueryHookOptions<InseminationsShortQuery, InseminationsShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InseminationsShortQuery, InseminationsShortQueryVariables>(InseminationsShortDocument, options);
      }
export function useInseminationsShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InseminationsShortQuery, InseminationsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InseminationsShortQuery, InseminationsShortQueryVariables>(InseminationsShortDocument, options);
        }
export function useInseminationsShortSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InseminationsShortQuery, InseminationsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InseminationsShortQuery, InseminationsShortQueryVariables>(InseminationsShortDocument, options);
        }
export type InseminationsShortQueryHookResult = ReturnType<typeof useInseminationsShortQuery>;
export type InseminationsShortLazyQueryHookResult = ReturnType<typeof useInseminationsShortLazyQuery>;
export type InseminationsShortSuspenseQueryHookResult = ReturnType<typeof useInseminationsShortSuspenseQuery>;
export type InseminationsShortQueryResult = Apollo.QueryResult<InseminationsShortQuery, InseminationsShortQueryVariables>;
import { BarController, BarElement, Element, UpdateMode } from 'chart.js';
import { AnyObject } from 'chart.js/dist/types/basic';

import TOKENS from '~/styles/__generated__/tokens.json';

import { CandleChartDataPoint } from '.';

const CANDLE_WIDTH_PX = 2;

interface CandleElement extends BarElement {
  candleHeight: number;
}

export class CandleController extends BarController {
  static id: string = 'candle';

  updateElements(
    elements: BarElement[],
    start: number,
    count: number,
    mode: UpdateMode
  ) {
    super.updateElements(elements, start, count, mode);

    const dataset = this.getDataset();

    const scaleY = this.getScaleForId('y');
    if (!scaleY) return;

    elements.forEach((element, index) => {
      const currentDatasetItem = dataset.data?.[
        index
      ] as unknown as CandleChartDataPoint;

      const candleHeight =
        scaleY.getPixelForValue(currentDatasetItem.max) -
        scaleY.getPixelForValue(currentDatasetItem.y);

      this.updateElement(element, index, { candleHeight }, mode);
    });
  }

  draw(): void {
    super.draw();

    const { data } = this.getMeta();

    const scaleY = this.getScaleForId('y');
    if (!scaleY) return;

    const { ctx } = this.chart;
    ctx.save();

    (data as Element<AnyObject, AnyObject>[]).forEach(item => {
      ctx.fillStyle = TOKENS.colorNeutral700;

      ctx.fillRect(
        item.x - CANDLE_WIDTH_PX / 2,
        item.y,
        CANDLE_WIDTH_PX,
        (item as CandleElement).candleHeight
      );
    });

    ctx.restore();
  }
}

import React from 'react';

import clsx from 'clsx';

import { Callout, NotificationVariants } from '~/services/notifications';

import {
  Button,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { downloadFile } from '~/shared/helpers/downloadFile';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const LoadTestMilkingInstruction: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx('grid justify-items-start gap-12', className)}>
      <Typography variant={TypographyVariants.bodyMediumStrong} tag="h3">
        Подготовьте файл для загрузки
      </Typography>
      <Typography variant={TypographyVariants.bodySmall} tag="p">
        Скачайте шаблон, заполните результаты и сохраните
        в&nbsp;формате&nbsp;.csv
      </Typography>
      <Button
        {...{
          theme: ButtonThemes.accent,
          variant: ButtonVariants.secondary,
          onPress: () => {
            downloadFile('/samples/milkings_template.csv', 'milkings', 'csv');
          },
        }}
      >
        Скачать шаблон
      </Button>
      <Callout
        {...{
          className: 'mt-12',
          title: 'Проверьте, все ли ячейки заполнены',
          message:
            'Если в ячейке надоя будет стоять 0 или она не будет заполнена, то в карточку коровы не будут переданы данные',
          variant: NotificationVariants.info,
        }}
      />
    </div>
  );
};

import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';
import { AppAnalyticsService } from '~/features/analytics';

import { MLoader } from '~/shared/components/MLoader';
import { MPageContainer } from '~/shared/components/MPageContainer';

import { PageNavigation } from '../PageNavigation';

export const AnalyticsPageContainer: React.FC = () => {
  const { initService, initialized } = useServiceInitialization();

  const http = useService<HTTPTransport>(StandardServices.Http);

  useEffect(() => {
    const analyticsSvc = new AppAnalyticsService(http);

    initService(MaslovServices.AnalyticsService, analyticsSvc);
  }, []);

  const tabs = [
    {
      value: MASLOV_ROUTES_TREE.User.analytics.livestockForecast,
      content: 'Прогноз поголовья',
    },
    {
      value: MASLOV_ROUTES_TREE.User.analytics.herd,
      content: 'Статус стада',
    },
    {
      value: MASLOV_ROUTES_TREE.User.analytics.reproduction,
      content: 'Воспроизводство',
    },
    {
      value: MASLOV_ROUTES_TREE.User.analytics.milking,
      content: 'Доение',
    },
    {
      value: MASLOV_ROUTES_TREE.User.analytics.monitor,
      content: 'Показатели',
    },
    {
      value: MASLOV_ROUTES_TREE.User.analytics.customReports,
      content: 'Отчёты',
    },
  ];

  return initialized ? (
    <MPageContainer>
      <PageNavigation items={tabs} />
      <Outlet />
    </MPageContainer>
  ) : (
    <MLoader />
  );
};

import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type PenGroupFragment = { __typename: 'PenGroup', id: string, name: string, identifier: any, farm: { __typename: 'Farm', id: string } };

export const PenGroupFragmentDoc = gql`
    fragment PenGroup on PenGroup {
  __typename
  id
  name
  identifier
  farm {
    __typename
    id
  }
}
    `;
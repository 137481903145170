import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BullFragment = { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: any | null, bullState: Types.BullState, bullDateOfBirth?: any | null };

export const BullFragmentDoc = gql`
    fragment Bull on Bull {
  __typename
  id
  name
  registrationNumber
  breed
  usdaNumber
  retirementReason
  bullState: state
  bullDateOfBirth: dateOfBirth
  category
  retiredAt
}
    `;
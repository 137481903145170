import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowShortFragment = { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any };

export const CowShortFragmentDoc = gql`
    fragment CowShort on Cow {
  __typename
  id
  identifier
  name
  state
  previousState
  dateOfBirth
}
    `;
import React from 'react';

import { QueryHookOptions } from '@apollo/client';

import clsx from 'clsx';
import * as yup from 'yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { SelectVariants } from '~/shared/components/Select';

import { EventAsyncSelect } from '~/entities/events';
import {
  EventsQuery,
  EventsQueryVariables,
} from '~/entities/events/gql/queries/events.graphql';

import formStyles from '~/styles/modules/form.module.scss';

const FORM_ID = 'AddEventIdsToListForm';

const SCHEMA = yup.object({
  userEventIDs: yup.array(yup.string().required()).default([]),
});

type AddEventIdsToListFormType = InferValidatedSchema<typeof SCHEMA>;

export interface AddEventIdsToListModalProps
  extends InjectedModalProps<AddEventIdsToListModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * onSubmit handler for passing selected events to form
   */
  onSubmit: (selectedEvents: string[]) => void;
  /**
   * Default selected events
   */
  defaultValues?: string[];
  /**
   * Query options for user events select
   */
  queryOptions: QueryHookOptions<EventsQuery, EventsQueryVariables>;
}

export const AddEventIdsToListModal: React.FC<AddEventIdsToListModalProps> = ({
  className,
  close,
  onSubmit,
  queryOptions,
  defaultValues = [],
}) => {
  const formContext = useForm<AddEventIdsToListFormType>({
    schema: SCHEMA,
    defaultValues: { ...SCHEMA.getDefault(), userEventIDs: defaultValues },
  });

  const handleSubmit = ({ userEventIDs }: AddEventIdsToListFormType) => {
    onSubmit(userEventIDs);
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Добавление событий в список',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Добавить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: clsx(formStyles.singleColumnForm, 'gap-24'),
        }}
      >
        <EventAsyncSelect
          {...{
            name: 'userEventIDs',
            label: 'Пользовательское событие',
            isMulti: true,
            variant: SelectVariants.withItemsList,
            queryOptions,
          }}
        />
      </Form>
    </Modal>
  );
};

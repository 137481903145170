import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../../entities/cows/gql/fragments/cowShort.graphql';
export type CutCodeFragment = { __typename: 'CutCode', sortingGates: Array<{ __typename?: 'SortingGate', number: number, value: number }>, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any } };

export const CutCodeFragmentDoc = gql`
    fragment CutCode on CutCode {
  __typename
  sortingGates {
    number
    value
  }
  cow {
    ...CowShort
  }
}
    ${CowShortFragmentDoc}`;
import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
export type PenGroupDetailedFragment = { __typename: 'PenGroup', id: string, identifier: any, name: string, isMilking: boolean, isDry: boolean, isHospital: boolean, isCalf: boolean, isArtificialInsemination: boolean, isWithBull: boolean, isUser: boolean, capacity?: number | null, occupied: number, occupiedPercent?: number | null, remaining?: number | null, cows: Array<{ __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any }>, farm: { __typename: 'Farm', id: string, name: string, number: string } };

export const PenGroupDetailedFragmentDoc = gql`
    fragment PenGroupDetailed on PenGroup {
  __typename
  id
  identifier
  name
  isMilking
  isDry
  isHospital
  isCalf
  isArtificialInsemination
  isWithBull
  isUser
  capacity
  occupied
  occupiedPercent
  remaining
  cows {
    ...CowShort
  }
  farm {
    ...Farm
  }
}
    ${CowShortFragmentDoc}
${FarmFragmentDoc}`;
import React from 'react';
import { Link } from 'react-router-dom';

import { AnalyticalMetric, AnalyticalMetricEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Loader } from '~/shared/components/Loader';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { renderOrMdash } from '~/shared/helpers/render';

import panelStyles from '~/styles/modules/panel.module.scss';

import { METRIC_CONFIGS } from '../../constants';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Metric type
   */
  metricName: AnalyticalMetricEnum;
  /**
   * Metric data
   */
  metric?: AnalyticalMetric;

  /**
   * Formatter which is applied to all values: metric, target and delta
   */
  formatValue: (metricValue: number) => string;
  /**
   * Is data for metric beeing loaded
   */
  isLoading?: boolean;
  /**
   * If true, card is blocked and can't be navigated to
   */
  isBlocked?: boolean;
  /**
   * Url, opening on the card click
   */
  cardLinkTo: string;
}

export const DashboardCard: React.FC<Props> = ({
  className,

  metricName,
  metric,

  formatValue,
  isLoading = false,
  isBlocked = false,
  cardLinkTo,
}) => {
  const metricConfig = METRIC_CONFIGS[metricName];

  const renderValue = (value: number | undefined | null) =>
    renderOrMdash(!R.isNil(value) && formatValue(Math.abs(value)));

  const metricDeltaSettings = match(metric?.delta)
    .with(
      P.number.gt(0),
      R.always({
        className: 'text-success',
        iconName: IconVariants.metricPositive,
      })
    )
    .with(
      P.number.lt(0),
      R.always({
        className: 'text-error',
        iconName: IconVariants.metricNegative,
      })
    )
    .otherwise(
      R.always({
        className: 'text-muted',
        iconName: IconVariants.metricUnchanged,
      })
    );

  return (
    <Link
      {...{
        to: isBlocked ? '#' : cardLinkTo,
        className: clsx(
          className,
          panelStyles.panel,
          styles.root,
          isBlocked && styles.blocked
        ),
      }}
    >
      <div className="flex justify-between mb-8">
        <Typography
          variant={TypographyVariants.bodyMediumStrong}
          className={clsx('ellipsis', isBlocked && 'text-disabled')}
        >
          {metricConfig.shortName ?? metricConfig.name}
        </Typography>
        <Icon
          {...{
            className: isBlocked ? 'text-disabled' : 'text-accent',
            variant: IconVariants.chevronDown,
            rotate: RotateVariants.left,
          }}
        />
      </div>
      {isBlocked && (
        <DataBlockedMessage
          className="flex-1 content-center"
          message="Нет данных для отображения"
        />
      )}
      {isLoading && <Loader className="flex-1" />}
      {!isLoading && !isBlocked && (
        <div className={styles.content}>
          <div>
            <Typography
              tag="div"
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              Факт
            </Typography>
            <Typography
              tag="div"
              variant={TypographyVariants.displaySmall}
              className="text-success"
            >
              {renderValue(metric?.value)}
            </Typography>

            <Typography
              {...{
                tag: 'div',
                variant: TypographyVariants.bodyMediumStrong,
                className: clsx(
                  'flex items-center gap-4',
                  metricDeltaSettings.className
                ),
              }}
            >
              {!R.isNil(metric?.delta) && (
                <Icon variant={metricDeltaSettings.iconName} />
              )}
              {renderValue(metric?.delta)}
            </Typography>
          </div>
          <div>
            <Typography
              tag="div"
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              Цель
            </Typography>
            <Typography
              tag="div"
              variant={TypographyVariants.displaySmall}
              className="text-accent"
            >
              {renderValue(metric?.target)}
            </Typography>
          </div>
        </div>
      )}
    </Link>
  );
};

import React, {
  CSSProperties,
  ElementType,
  Fragment,
  ReactNode,
  useLayoutEffect,
} from 'react';
import { InViewHookResponse } from 'react-intersection-observer';

import { Loader } from '~/shared/components/Loader';
import {
  getSkeletonPlaceholders,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import {
  useInfiniteScroll,
  UseInfiniteScrollProps,
} from '~/shared/hooks/useInfiniteScroll';

export interface AsyncListProps<
  Item,
  // Generally we should always render AsyncList with skeletons,
  // but this argument can be set to false to implicitly disable type check for rendering with with skeletons
  // WARNING: you should not pass skeletonItemsCount, if you disable skeletons,
  // otherwise you'll get runtime skeletons without type check.
  // Tried to use discriminated union to solve this, but got lots of problems with extending this interface
  WithSkeletonItems extends boolean = true,
  ItemToRender = WithSkeletonItems extends true
    ? Item | SkeletonPlaceholder
    : Item,
> extends UseInfiniteScrollProps {
  /**
   * className applied to the root element (if any)
   */
  className?: string;
  /**
   * Css styles, applied to the root element
   */
  style?: CSSProperties | undefined;

  /**
   * Items to render in list
   */
  items: Item[];
  /**
   * If passed, renders skeleton placeholders, while loading initial items
   */
  skeletonItemsCount?: number;
  /**
   * Render prop for rendering single item in the list
   */
  renderItem: (
    item: ItemToRender,
    index: number,
    array: ItemToRender[]
  ) => ReactNode;
  /**
   * Tag name to render (React.Fragment is used by default)
   */
  wrapperTag?: ElementType;
  /**
   * If true, it indicates, that it is loading next items.
   */
  isFetchingMore?: boolean;

  /**
   * Message, displayed, when there are no items in the list.
   * This prop is required, cause each list should have its uniq message
   */
  noItemsMessage: ReactNode;
  /**
   * If true, no items message is replaced with noSearchItemsMessage
   */
  isSearchActive?: boolean;
  /**
   * Message, displayed, when there are no items in the list, but user has active search
   */
  noSearchItemsMessage?: ReactNode;
  /**
   * Additional description, when no search items are found
   */
  noSearchItemsDescription?: ReactNode;
  /**
   * Render prop for rendering loader that is displayed on loading next items
   * and should contain a ref for infinite scroll algorithm to work
   */
  renderLoader?: (sentryRef?: InViewHookResponse['ref']) => ReactNode;
  /**
   * If true, the inner div is used for tracking infinite scroll,
   * otherwise, default document.window is used, or you can pass root prop for useInfiniteScroll
   */
  withInnerRootRef?: boolean;
}

const renderDefaultLoader = (sentryRef?: InViewHookResponse['ref']) => (
  <Loader className="col-span-full" ref={sentryRef} />
);

const AsyncListInternal = <Item extends any>(
  {
    className,
    style,

    items,
    skeletonItemsCount,
    renderItem,
    wrapperTag: WrapperTag = Fragment,
    isFetchingMore,

    noItemsMessage,
    noSearchItemsMessage = noItemsMessage,
    isSearchActive = false,
    renderLoader = renderDefaultLoader,

    withInnerRootRef = false,

    ...infiniteScrollProps
  }: AsyncListProps<Item>,
  ref: React.Ref<HTMLElement>
) => {
  const { rootRef, sentryRef } = useInfiniteScroll(infiniteScrollProps);

  const { isLoading, hasMore } = infiniteScrollProps;

  // Prevent scroll sticking to the bottom border
  // and continuously calling onFetchMore, when scrolling to fast
  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollTop -= 1;
    }
  }, [items.length]);

  const noItemsMessageToDisplay = isSearchActive
    ? noSearchItemsMessage
    : noItemsMessage;

  const isSkeletonLoading =
    !!skeletonItemsCount && isLoading && !isFetchingMore && !items.length;

  const itemsToRender = isSkeletonLoading
    ? getSkeletonPlaceholders(skeletonItemsCount)
    : items;

  return (
    <Skeleton isLoading={isSkeletonLoading}>
      <WrapperTag
        ref={mergeRefs(ref, withInnerRootRef ? rootRef : undefined)}
        className={className}
        style={style}
      >
        {!itemsToRender.length && !isLoading && noItemsMessageToDisplay}

        {itemsToRender.map(renderItem)}

        {!isSkeletonLoading && isLoading && !isFetchingMore && renderLoader?.()}
        {(isFetchingMore || hasMore) && renderLoader?.(sentryRef)}
      </WrapperTag>
    </Skeleton>
  );
};

// Workaround for typing generic HOC
type RenderAsyncList = <
  Item extends any,
  WithSkeletonItems extends boolean = true,
>(
  props: AsyncListProps<Item, WithSkeletonItems>
) => React.ReactElement;

export const AsyncList = React.forwardRef<HTMLElement, AsyncListProps<any>>(
  AsyncListInternal
) as RenderAsyncList;

import R from 'ramda';

import { makeUseAsyncSelect } from '~/services/gql';

import { BullFragmentDoc } from '../gql/fragments/bull.graphql';
import { useBullsQuery } from '../gql/queries/bulls.graphql';
import { formatBull } from '../helpers';

/**
 * Select for a bull
 */
export const useBullSelect = makeUseAsyncSelect({
  typeName: 'Bull',
  fragment: BullFragmentDoc,
  useQuery: useBullsQuery,
  getItemsFromQueryData: data => data.bulls.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.bulls.pageInfo,

  selectProps: {
    placeholder: 'Введите рег. номер или кличку',
    getItemValue: item => item.id,
    getItemText: item => formatBull(item, { prefix: '' }),
  },
});

import { EventIdInput, InputMaybe } from '@graphql-types';
import R from 'ramda';

import { getTypePartFromGlobalId, makeUseAsyncSelect } from '~/services/gql';

import {
  EventFragment,
  EventFragmentDoc,
} from '../gql/fragments/event.graphql';
import {
  EventsQuery,
  EventsQueryVariables,
  useEventsQuery,
} from '../gql/queries/events.graphql';
import { getEventId, getEventIdsFilter, isDefaultEvent } from '../helpers';

/**
 * Select for a event
 */
export const useEventSelect = makeUseAsyncSelect<
  EventFragment,
  EventsQuery,
  EventsQueryVariables,
  InputMaybe<Array<EventIdInput> | EventIdInput>
>({
  typeName: getTypePartFromGlobalId,
  fragment: EventFragmentDoc,
  useQuery: useEventsQuery,
  getItemsFromQueryData: data => data.events.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.events.pageInfo,
  getItemKey: getEventId,
  getItemIdQueryInput: getEventIdsFilter,

  selectProps: {
    label: 'Событие',
    placeholder: 'Выберите событие',
    getItemValue: getEventId,
    getItemDescription: event =>
      isDefaultEvent(event) ? event.shortcode : event?.kind,
    getItemText: item => {
      if (!item) return '';

      return item.name || item.kind;
    },
  },
});

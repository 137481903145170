import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type PenGroupShortFragment = { __typename: 'PenGroup', id: string, name: string, identifier: any };

export const PenGroupShortFragmentDoc = gql`
    fragment PenGroupShort on PenGroup {
  __typename
  id
  name
  identifier
}
    `;
import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MonitorLaunchCalculatingValueFragment = { __typename: 'MonitorLaunchCalculatingValue', monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: any } };

export const MonitorLaunchCalculatingValueFragmentDoc = gql`
    fragment MonitorLaunchCalculatingValue on MonitorLaunchCalculatingValue {
  __typename
  monitorLaunch {
    __typename
    id
    happenedAt
  }
}
    `;
import React from 'react';

import clsx from 'clsx';

import { MenuItem } from './components/MenuItem';
import styles from './index.module.scss';
import { MenuItemType, MenuItemVariants } from './types';

export interface MenuProps<ItemType extends MenuItemType = MenuItemType> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Items to render in the menu
   */
  items: ItemType[];
  /**
   * Called, when an item is pressed
   */
  onItemPress?: (item: ItemType, index: number) => void;
  /**
   * Index of an item, activated by arrow navigation
   */
  activeIndex?: number | null;
  /**
   * Additional props getter for implementing navigation
   */
  getItemProps?: (
    index: number,
    userProps?: React.HTMLProps<HTMLElement>
  ) => React.ComponentProps<'li'>;
  /**
   * If true, each item has a delimiter, (default - false)
   */
  withDelimiters?: boolean;
}

export const Menu = <ItemType extends MenuItemType = MenuItemType>({
  className,
  items,
  onItemPress,
  activeIndex,
  getItemProps,
  withDelimiters = false,
}: MenuProps<ItemType>) => {
  return (
    <ul
      className={clsx(
        styles.root,
        withDelimiters && styles.withDelimiters,
        className
      )}
    >
      {items.map((item, index) => {
        if (item.variant === MenuItemVariants.delimiter) {
          return (
            <hr
              {...{
                key: index,
                className: styles.delimiter,
              }}
            />
          );
        }
        return (
          <MenuItem<ItemType>
            {...{
              key: `${item.key}__${index}`,
              className: clsx(
                styles.item,
                item.variant === MenuItemVariants.destructive && 'text-error'
              ),
              item,
              onPress: i => onItemPress?.(i, index),
              isActive: activeIndex === index,
              getItemProps: userProps =>
                getItemProps?.(index, userProps) ?? userProps ?? {},
            }}
          />
        );
      })}
    </ul>
  );
};

export * from './types';

import React, { useEffect, useRef, useState } from 'react';

import { usePress } from '@react-aria/interactions';
import { useId, useResizeObserver } from '@react-aria/utils';

import { useControllableState } from '~/shared/hooks/useControllableState';
import { usePrevious } from '~/shared/hooks/usePrevious';

/**
 * Props of any accordion
 */
export interface UseAccordionProps extends React.PropsWithChildren {
  /**
   * Open state
   */
  isOpen?: boolean;
  /**
   * Default open state
   */
  defaultIsOpen?: boolean;
  /**
   * Unmount content after closing
   */
  shouldUnmountClosedChildren?: boolean;
  /**
   * Called, when accordion open state changes
   */
  onToggle?: (isOpen: boolean) => void;
}

const COLLAPSE_DURATION = 300;

/**
 * Hook for reusing accordion pattern
 */
export const useAccordion = ({
  isOpen: isOpenProp,
  defaultIsOpen = isOpenProp,
  shouldUnmountClosedChildren = true,
  onToggle,
}: UseAccordionProps = {}) => {
  const accordionId = useId();

  const childrenRef = useRef<HTMLDivElement>(null);
  const childrenContainerRef = useRef<HTMLDivElement>(null);

  const [shouldRenderContent, setShouldRenderContent] = useState(
    shouldUnmountClosedChildren ? defaultIsOpen : true
  );

  // Enable content rendering if shouldUnmountClosedChildren has changed and is true
  useEffect(() => {
    if (!shouldUnmountClosedChildren && !shouldRenderContent) {
      setShouldRenderContent(true);
    }
  }, [shouldUnmountClosedChildren]);

  const renderContentTimeoutRef = useRef<NodeJS.Timeout>();
  const toggle = (newIsOpen: boolean) => {
    if (shouldUnmountClosedChildren) {
      clearTimeout(renderContentTimeoutRef.current);
      if (newIsOpen) {
        setShouldRenderContent(true);
      } else {
        renderContentTimeoutRef.current = setTimeout(() => {
          setShouldRenderContent(false);
        }, COLLAPSE_DURATION);
      }
    }

    setTimeout(() => {
      onToggle?.(newIsOpen);
    }, 0);
  };

  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    toggle,
    defaultIsOpen
  );

  const prevIsOpen = usePrevious(isOpen);
  // Actualize shouldRenderContent if isOpenProp changes
  useEffect(() => {
    if (isOpenProp !== undefined && isOpenProp !== prevIsOpen) {
      setIsOpen(isOpenProp);
    }
  }, [isOpenProp]);

  const { pressProps } = usePress({
    onPress: () => setIsOpen(currentIsOpen => !currentIsOpen),
  });

  const [childrenScrollHeight, setChildrenScrollHeight] = useState(
    defaultIsOpen ? 'auto' : childrenRef.current?.scrollHeight ?? 0
  );

  // Update children max height on resize
  useResizeObserver({
    ref: childrenContainerRef,
    onResize: () => {
      const setHeight = () =>
        setChildrenScrollHeight(childrenRef.current?.scrollHeight ?? 0);
      setHeight();
      setTimeout(setHeight, COLLAPSE_DURATION);
    },
  });

  const childrenHeight = isOpen ? childrenScrollHeight : 0;

  const openableHeaderProps = {
    id: `${accordionId}-header`,
    ...pressProps,
  };

  const openButtonProps = {
    tabIndex: 0,
    id: accordionId,
    role: 'button',
    ...pressProps,
  };

  const childrenWrapperProps = {
    ref: childrenRef,
    style: {
      maxHeight: childrenHeight,
    },
    role: 'region',
    'aria-labelledby': accordionId,
    id: `${accordionId}-panel`,
  };

  return {
    isOpen,
    setIsOpen,
    shouldRenderContent,

    childrenContainerRef,

    openableHeaderProps,
    openButtonProps,
    childrenWrapperProps,
  };
};

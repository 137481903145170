import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  InjectedStepperModalProps,
  ModalSizes,
  ProgressBar,
  withStepperModal,
} from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { mergeProps } from '~/shared/helpers/mergeProps';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useActivateCowsCopyKeyMutation } from '../../gql/mutations/activateCowsCopyKey.graphql';
import { useCowsCopyKeysForActivationLazyQuery } from '../../gql/queries/cowsCopyKeysForActivation.graphql';
import { readCowsCopyKeyFragment } from '../../helpers';
import { ActivateKeyForm } from './components';
import { ActivatedKeyInfoCard } from './components/ActivatedKeyInfoCard';
import { DiseaseMappingsForm } from './components/DiseaseMappingsForm';
import { IdentifierMappingsForm } from './components/IdentifierMappingsForm';
import { InseminationBullMappingsForm } from './components/InseminationBullMappingsForm';
import { UserEventMappingsForm } from './components/UserEventMappingsForm';
import {
  filterIdentifiersByMappingType,
  makeCowsCopyKeyPayload,
} from './helpers';
import {
  useDiseaseMappingsForm,
  useIdentifierMappingsForm,
  useInseminationBullMappingsForm,
  useUserEventMappingsForm,
} from './hooks';
import stepStyles from './steps.module.scss';
import {
  AddCowByKeyModalFormTypes,
  AddCowByKeyModalStepperState,
  AddCowByKeyModalSteps,
  AddCowByKeyModalZeroStepWrapperProps,
} from './types';

export interface AddCowByKeyModalProps
  extends InjectedStepperModalProps<
    AddCowByKeyModalProps,
    AddCowByKeyModalStepperState,
    AddCowByKeyModalZeroStepWrapperProps
  > {
  /**
   * className applied to the root element
   */
  className?: string;
}

const AddCowByKeyModalInternal: React.FC<AddCowByKeyModalProps> = ({
  className,

  stepNumber,
  stepsCount,
  close,

  stepperModalState: cowByKeyPayload,
  setStepperModalState: setCowByKeyPayload,
  StepperModalWrapperComponent,
  stepperModalWrapperProps,
}) => {
  const { sendSuccessToast } = useNotifications();

  const [fetchCowsCopyKeys, { loading: isLoading, client }] =
    useCowsCopyKeysForActivationLazyQuery();

  const [activateCowsCopyKey, { loading: isActivateCowsCopyKeyLoading }] =
    useActivateCowsCopyKeyMutation();

  const moveKey = cowByKeyPayload?.key;
  const cowsCopyKey = readCowsCopyKeyFragment(client, moveKey);

  const submitActivateCowsByCopyKey = (
    payload: AddCowByKeyModalStepperState
  ) => {
    if (!payload) return;

    const {
      diseaseMappings,
      userEventMappings,
      inseminationBullMappings,
      ...input
    } = payload;

    activateCowsCopyKey({
      variables: {
        input: {
          ...input,
          diseaseMappings: filterIdentifiersByMappingType(diseaseMappings),
          userEventMappings: filterIdentifiersByMappingType(userEventMappings),
          inseminationBullMappings: filterIdentifiersByMappingType(
            inseminationBullMappings
          ),
        },
      },
    }).then(() => {
      close();
      sendSuccessToast('Животные добавлены');
    });
  };

  return (
    <StepperModalWrapperComponent
      {...mergeProps(stepperModalWrapperProps, {
        key: `${!!cowByKeyPayload}_${stepperModalWrapperProps.key}`,
        submitActivateCowsByCopyKey,
        cowsCopyKey,
        modalProps: {
          className: clsx(stepStyles.root, className),
          size: ModalSizes.large1192,
          title: 'Добавление животных по ключу',
          submitButtonProps: {
            children: 'Добавить животных',
            isLoading: isActivateCowsCopyKeyLoading,
            isDisabled: !cowByKeyPayload,
          },
          isRequireExplicitClosing: !!cowByKeyPayload,
        },
      })}
    >
      {stepElement => (
        <>
          {!cowByKeyPayload && (
            <ActivateKeyForm
              {...{
                isLoading,
                submit: async key => {
                  const { data } = await fetchCowsCopyKeys({
                    variables: {
                      keys: [key],
                    },
                  });

                  const cowsCopyKeyForActivation =
                    data?.cowsCopyKeysForActivation.at(0);

                  if (cowsCopyKeyForActivation) {
                    setCowByKeyPayload(
                      makeCowsCopyKeyPayload(cowsCopyKeyForActivation)
                    );
                  }
                  return cowsCopyKeyForActivation;
                },
              }}
            />
          )}

          {!!cowByKeyPayload && (
            <div className={clsx(formStyles.singleColumnForm, 'gap-24')}>
              <ActivatedKeyInfoCard moveKey={cowByKeyPayload?.key} />
              <ProgressBar {...{ stepsCount, stepNumber }} />
              <div className={clsx(panelStyles.outerPanel, 'p-16')}>
                {stepElement}
              </div>
            </div>
          )}
        </>
      )}
    </StepperModalWrapperComponent>
  );
};

export const AddCowByKeyModal = withStepperModal<
  AddCowByKeyModalProps,
  AddCowByKeyModalSteps,
  AddCowByKeyModalFormTypes,
  AddCowByKeyModalFormTypes,
  AddCowByKeyModalStepperState,
  AddCowByKeyModalZeroStepWrapperProps
>({
  stepsDict: AddCowByKeyModalSteps,
  getDefaultStepperState: R.always(undefined),
  onNextStepSubmit: ({
    stepperModalState,
    setStepperModalState,

    isLastStep,
    goToNextStep,
    submitActivateCowsByCopyKey,

    form,
  }) => {
    if (!stepperModalState) return;

    const completedPayload = {
      ...stepperModalState,
      ...form,
    };

    setStepperModalState(completedPayload);

    if (isLastStep) {
      submitActivateCowsByCopyKey(completedPayload);
    } else {
      goToNextStep();
    }
  },
  stepHooks: {
    [AddCowByKeyModalSteps.identifierMappings]: ({
      cowsCopyKey,
      stepperModalState: cowByKeyPayload,
      handleNextStepSubmit,
    }) => {
      if (!cowByKeyPayload) {
        return null;
      }

      const { formProps, ...otherFormHookResults } = useIdentifierMappingsForm({
        defaultValues: R.pick(
          ['identifierMappings', 'arriveDate', 'penGroupID', 'farmID'],
          cowByKeyPayload
        ),
        onSubmit: handleNextStepSubmit,
      });

      return {
        ...otherFormHookResults,
        stepElement: (
          <IdentifierMappingsForm
            {...{
              cows: cowsCopyKey?.cows ?? [],
              formProps,
            }}
          />
        ),
      };
    },

    [AddCowByKeyModalSteps.diseaseMappings]: ({
      cowsCopyKey,
      stepperModalState: cowByKeyPayload,
      handleNextStepSubmit,
    }) => {
      if (!cowByKeyPayload) {
        return null;
      }

      const { formProps, ...otherFormHookResults } = useDiseaseMappingsForm({
        defaultValues: R.pick(['diseaseMappings'], cowByKeyPayload),
        onSubmit: handleNextStepSubmit,
      });
      return {
        ...otherFormHookResults,
        stepElement: (
          <DiseaseMappingsForm
            {...{
              formProps,
              diseases: cowsCopyKey?.diseases ?? [],
            }}
          />
        ),
      };
    },

    [AddCowByKeyModalSteps.userEventMappings]: ({
      cowsCopyKey,
      stepperModalState: cowByKeyPayload,
      handleNextStepSubmit,
    }) => {
      if (!cowByKeyPayload) {
        return null;
      }

      const { formProps, ...otherFormHookResults } = useUserEventMappingsForm({
        defaultValues: R.pick(['userEventMappings'], cowByKeyPayload),
        onSubmit: handleNextStepSubmit,
      });
      return {
        ...otherFormHookResults,
        stepElement: (
          <UserEventMappingsForm
            {...{
              formProps,
              userEvents: cowsCopyKey?.userEvents ?? [],
            }}
          />
        ),
      };
    },

    [AddCowByKeyModalSteps.inseminationBullMappings]: ({
      cowsCopyKey,
      stepperModalState: cowByKeyPayload,
      handleNextStepSubmit,
    }) => {
      if (!cowByKeyPayload) {
        return null;
      }

      const { formProps, ...otherFormHookResults } =
        useInseminationBullMappingsForm({
          defaultValues: R.pick(['inseminationBullMappings'], cowByKeyPayload),
          onSubmit: handleNextStepSubmit,
        });
      return {
        ...otherFormHookResults,
        stepElement: (
          <InseminationBullMappingsForm
            {...{
              inseminationBulls: cowsCopyKey?.inseminationBulls ?? [],
              formProps,
            }}
          />
        ),
      };
    },
  },
})(AddCowByKeyModalInternal);

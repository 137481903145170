import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { TestMilkingUploadFragmentDoc } from '../fragments/testMilkingUpload.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadTestMilkingCsvMutationVariables = Types.Exact<{
  fileInput: Types.TestMilkingFileInput;
}>;


export type LoadTestMilkingCsvMutation = { __typename?: 'Mutation', loadTestMilkingCsv: { __typename: 'TestMilkingUpload', id: string, happenedAt: any, cowsCount: number, hasUploadIssues: boolean, testMilkings: Array<{ __typename: 'TestMilking', id: string, happenedAt: any, daysInMilk: number, weightKilograms: number, fatPercent?: number | null, proteinPercent?: number | null, sccThousandsPerMl?: number | null, ureaMgPerDl?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: any } | null, cow: { __typename: 'Cow', id: string, identifier: any, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: any } }> } };


export const LoadTestMilkingCsvDocument = gql`
    mutation loadTestMilkingCsv($fileInput: TestMilkingFileInput!) {
  loadTestMilkingCsv(fileInput: $fileInput) {
    ...TestMilkingUpload
  }
}
    ${TestMilkingUploadFragmentDoc}`;
export type LoadTestMilkingCsvMutationFn = Apollo.MutationFunction<LoadTestMilkingCsvMutation, LoadTestMilkingCsvMutationVariables>;

/**
 * __useLoadTestMilkingCsvMutation__
 *
 * To run a mutation, you first call `useLoadTestMilkingCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadTestMilkingCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadTestMilkingCsvMutation, { data, loading, error }] = useLoadTestMilkingCsvMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *   },
 * });
 */
export function useLoadTestMilkingCsvMutation(baseOptions?: Apollo.MutationHookOptions<LoadTestMilkingCsvMutation, LoadTestMilkingCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadTestMilkingCsvMutation, LoadTestMilkingCsvMutationVariables>(LoadTestMilkingCsvDocument, options);
      }
export type LoadTestMilkingCsvMutationHookResult = ReturnType<typeof useLoadTestMilkingCsvMutation>;
export type LoadTestMilkingCsvMutationResult = Apollo.MutationResult<LoadTestMilkingCsvMutation>;
export type LoadTestMilkingCsvMutationOptions = Apollo.BaseMutationOptions<LoadTestMilkingCsvMutation, LoadTestMilkingCsvMutationVariables>;
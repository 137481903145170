import React from 'react';

import * as yup from 'yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { TimeInput } from '~/shared/components/TimeInput';

import { useSetMilkingParlorFarmSettingsMutation } from '../../gql/mutations/setMilkingParlorFarmSettings.graphql';
import { updateFarmWithMilkingParlorSettingsFragment } from '../../helpers';

export interface EditMilkingStartModalProps
  extends InjectedModalProps<EditMilkingStartModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Id of the farm to set milking start time
   */
  farmId: string;
  /**
   * Current milking start time
   */
  firstMilkingPerDayStartOn?: string;
}

const FORM_ID = 'EditMilkingStartForm';

const SCHEMA = yup.object({
  firstMilkingPerDayStartOn: yup.string().required(), // Time!
});

type EditMilkingStartFormType = InferValidatedSchema<typeof SCHEMA>;

export const EditMilkingStartModal: React.FC<EditMilkingStartModalProps> = ({
  className,
  farmId,
  firstMilkingPerDayStartOn,

  close,
}) => {
  const [setMilkingParlorFarmSettings] =
    useSetMilkingParlorFarmSettingsMutation();

  const formContext = useForm<EditMilkingStartFormType>({
    schema: SCHEMA,
    defaultValues: { ...SCHEMA.getDefault(), firstMilkingPerDayStartOn },
  });

  const handleSubmit = (form: EditMilkingStartFormType) => {
    setMilkingParlorFarmSettings({
      variables: {
        farmID: farmId,
        ...form,
      },
      optimisticResponse: { setMilkingParlorFarmSettings: null },
      update: updateFarmWithMilkingParlorSettingsFragment(farmId, draft => {
        draft.milkingParlorSettings = {
          firstMilkingPerDayStartOn: form.firstMilkingPerDayStartOn,
        };
      }),
    });

    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Изменение времени',
        submitButtonProps: {
          form: FORM_ID,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <TimeInput
          {...{
            name: 'firstMilkingPerDayStartOn',
            label: 'Начало нового дня доения',
          }}
        />
      </Form>
    </Modal>
  );
};

import { makeUpdateFragment } from '~/services/gql';

import {
  FarmWithMilkingParlorSettingsFragment,
  FarmWithMilkingParlorSettingsFragmentDoc,
} from './gql/fragments/farmWithMilkingParlorSettings.graphql';
import {
  MilkingParlorFragment,
  MilkingParlorFragmentDoc,
} from './gql/fragments/milkingParlor.graphql';
import {
  MilkingParlorIntervalDetailedFragment,
  MilkingParlorIntervalDetailedFragmentDoc,
} from './gql/fragments/milkingParlorIntervalDetailed.graphql';
import {
  MilkingParlorIntervalWithMilkingParlorFragment,
  MilkingParlorIntervalWithMilkingParlorFragmentDoc,
} from './gql/fragments/milkingParlorIntervalWithMilkingParlor.graphql';

/**
 * Update MilkingParlorFragment in the cache
 */
export const updateMilkingParlorFragment =
  makeUpdateFragment<MilkingParlorFragment>({
    typeName: 'MilkingParlor',
    fragment: MilkingParlorFragmentDoc,
  });

/**
 * Update MilkingParlorIntervalDetailedFragment in the cache
 */
export const updateMilkingParlorIntervalDetailedFragment =
  makeUpdateFragment<MilkingParlorIntervalDetailedFragment>({
    typeName: 'MilkingParlorInterval',
    fragment: MilkingParlorIntervalDetailedFragmentDoc,
    fragmentName: 'MilkingParlorIntervalDetailed',
  });

/**
 * Update MilkingParlorIntervalWithMilkingParlorFragment in the cache
 */
export const updateMilkingParlorIntervalWithMilkingParlorFragment =
  makeUpdateFragment<MilkingParlorIntervalWithMilkingParlorFragment>({
    typeName: 'MilkingParlorInterval',
    fragment: MilkingParlorIntervalWithMilkingParlorFragmentDoc,
    fragmentName: 'MilkingParlorIntervalWithMilkingParlor',
  });

/**
 * Update FarmWithMilkingParlorSettingsFragment in the cache
 */
export const updateFarmWithMilkingParlorSettingsFragment =
  makeUpdateFragment<FarmWithMilkingParlorSettingsFragment>({
    typeName: 'Farm',
    fragment: FarmWithMilkingParlorSettingsFragmentDoc,
    fragmentName: 'FarmWithMilkingParlorSettings',
  });

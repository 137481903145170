import React from 'react';

import { TestMilkingUploadResolveConflictsStrategy } from '@graphql-types';
import clsx from 'clsx';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { ResolveTestMilkingConflictsFormType } from '../../hooks/useResolveTestMilkingConflictsForm';

interface Props
  extends Pick<
    UseModalStepFormInterface<ResolveTestMilkingConflictsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
}

const RESOLVE_CONFLICTS_STRATEGY_RADIO_ITEMS = [
  {
    id: TestMilkingUploadResolveConflictsStrategy.Sum,
    name: 'Суммировать с предыдущими результатами',
    description:
      'Объёмы молока коровы суммируются. Остальные показатели будут взвешенно усреднены по объёму молока',
  },
  {
    id: TestMilkingUploadResolveConflictsStrategy.Overwrite,
    name: 'Оставить только загружаемые результаты',
    description:
      'Контрольная дойка коровы будет полностью перезаписана новыми результатами, без суммирования',
  },
  {
    id: TestMilkingUploadResolveConflictsStrategy.Ignore,
    name: 'Игнорировать',
    description:
      'Останется существующая контрольная дойка коровы, никаких изменений не будет записано',
  },
];

export const ResolveTestMilkingConflictsForm: React.FC<Props> = ({
  className,
  formProps,
}) => {
  return (
    <Form
      {...{
        className: clsx('grid gap-12', className),
        ...formProps,
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong} tag="h3">
        Как записать результаты для совпадающих коров
      </Typography>
      <RadioGroup
        {...{
          name: 'resolveConflictsStrategy',
          variant: RadioGroupVariants.verticalRadio,
          items: RESOLVE_CONFLICTS_STRATEGY_RADIO_ITEMS,
        }}
      />
    </Form>
  );
};

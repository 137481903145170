import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

/**
 * Returns a url to a custom report launch
 */
export const getCustomReportLaunchPageUrl = (
  customReportId: string,
  launchHashId: string
) =>
  `${MASLOV_ROUTES_TREE.User.analytics.customReportsUrl}/${customReportId}/${launchHashId}`;

import { CowState, SourceFieldKindEnum, ValueKindEnum } from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';

import { DateFormats, formatDate } from '~/shared/helpers/date';
import { formatShortName } from '~/shared/helpers/nameFormat';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { formatBull } from '~/entities/bulls';
import { formatCalving } from '~/entities/calvings';
import { COW_STATES_DICT, formatCow } from '~/entities/cows';
import { formatInsemination } from '~/entities/inseminations';
import { formatPenGroup } from '~/entities/penGroups';
import { formatSemenDose } from '~/entities/semenDoses';

import { BlueprintValueFragment } from '../gql/fragments/blueprintValue.graphql';

// Small helper for match expressions readability to avoid writing a lot of typeguards
const TN = <T extends BlueprintValueFragment['__typename']>(__typename: T) => ({
  __typename,
});

const COW_STATE_FIELD_KINDS = [
  SourceFieldKindEnum.CowState,
  SourceFieldKindEnum.CowPreviousState,
];

/**
 * Hook for formatting blueprint values for edit or display
 */
export const useBlueprintValues = () => {
  const getBlueprintValueForDisplay = (
    value: BlueprintValueFragment,
    fieldKind: SourceFieldKindEnum,
    valueKind: ValueKindEnum
  ): string => {
    return match(value)
      .with(TN('Disease'), R.prop('name'))
      .with(TN('Injection'), R.prop('name'))
      .with(TN('InseminationScheme'), R.prop('name'))
      .with(TN('Protocol'), R.prop('name'))
      .with(TN('UserEvent'), R.prop('name'))
      .with(TN('Farm'), R.prop('name'))
      .with(TN('PenGroup'), matchedValue => formatPenGroup(matchedValue))
      .with(TN('Cow'), matchedValue => formatCow(matchedValue))
      .with(TN('Bull'), matchedValue => formatBull(matchedValue))
      .with(TN('Calving'), matchedValue => formatCalving(matchedValue))
      .with(TN('Employee'), matchedValue => formatShortName(matchedValue))
      .with(TN('Insemination'), matchedValue =>
        formatInsemination(matchedValue)
      )
      .with(TN('SemenDose'), matchedValue => formatSemenDose(matchedValue))

      .with(TN('BlueprintInput'), R.prop('name'))
      .with(TN('SourceField'), R.prop('name'))

      .with(TN('DateHardcodedValue'), matchedValue =>
        formatDate(matchedValue.dateValue, DateFormats.full)
      )
      .with(TN('DatetimeHardcodedValue'), matchedValue =>
        formatDate(matchedValue.datetimeValue, DateFormats.fullWithTime)
      )
      .with(TN('FloatHardcodedValue'), matchedValue =>
        formatNumber(matchedValue.floatValue)
      )
      .with(TN('IntHardcodedValue'), matchedValue => {
        const val = matchedValue.intValue;
        if (valueKind === ValueKindEnum.Bool) {
          return val ? 'Да' : 'Нет';
        }

        // We don't format cow identifier as a number
        if (fieldKind === SourceFieldKindEnum.CowIdentifier) {
          return val.toString();
        }

        return formatInt(val);
      })
      .with(TN('JSONHardcodedValue'), matchedValue =>
        JSON.stringify(matchedValue.jsonValue)
      )
      .with(TN('StrHardcodedValue'), matchedValue => {
        const val = matchedValue.strValue;
        if (
          valueKind === ValueKindEnum.CowState ||
          COW_STATE_FIELD_KINDS.includes(fieldKind)
        ) {
          return COW_STATES_DICT[val as CowState] ?? val;
        }

        return val;
      })
      .exhaustive();
  };

  return {
    getBlueprintValueForDisplay,
  };
};

import React from 'react';

import dayjs from 'dayjs';
import * as yup from 'yup';

import { isMonitorEntry, makeUpdateMonitorQuery } from '~/features/monitor';
import { useCalculateMonitorMutation } from '~/features/monitor/gql/mutations/calculateMonitor.graphql';
import { makeMonitorLaunchPlaceholder } from '~/features/monitor/modals/CalculateMonitorModal/helpers';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal, ModalSizes } from '~/services/modals';

import { DateInput } from '~/shared/components/DateInput';
import { formatDateForBackend } from '~/shared/helpers/date';

export interface CalculateMonitorModalProps
  extends InjectedModalProps<CalculateMonitorModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const FORM_ID = 'CalculateMonitorForm';

const SCHEMA = yup.object({
  calculationDate: yup
    .string()
    .required()
    .test('checkDateRange', 'Выберите дату не позднее текущего дня', dateTime =>
      dayjs().isSameOrAfter(dayjs(dateTime), 'day')
    ),
});

type CalculateMonitorFormType = InferValidatedSchema<typeof SCHEMA>;

export const CalculateMonitorModal: React.FC<CalculateMonitorModalProps> = ({
  className,
  close,
}) => {
  const [calculateMonitor, { loading: isCalculateLoading }] =
    useCalculateMonitorMutation();

  const formContext = useForm<CalculateMonitorFormType>({
    schema: SCHEMA,
    mode: 'onChange',
    defaultValues: {
      calculationDate: formatDateForBackend(dayjs(), true),
    },
  });

  const handleSubmit = (form: CalculateMonitorFormType) => {
    calculateMonitor({
      variables: {
        runOn: form.calculationDate,
      },
      // backend doesn't always update monitor calculation status immediately
      // -> make update locally
      update: makeUpdateMonitorQuery(draft => {
        draft.monitor.edges.forEach(draftEdge => {
          if (!isMonitorEntry(draftEdge.node)) return;

          const calculatedMonitorLaunchIndex =
            draftEdge.node.values.findLastIndex(({ monitorLaunch }) =>
              dayjs(monitorLaunch.happenedAt).isSameOrBefore(
                form.calculationDate,
                'day'
              )
            );

          draftEdge.node.values.splice(
            calculatedMonitorLaunchIndex + 1,
            0,
            makeMonitorLaunchPlaceholder(form.calculationDate)
          );
        });
      }),
    }).then(close);
  };

  return (
    <Modal
      {...{
        className,
        title: 'Расчёт на выбранную дату',
        size: ModalSizes.small516,
        submitButtonProps: {
          form: FORM_ID,
          children: 'Рассчитать',
          isLoading: isCalculateLoading,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <DateInput
          {...{
            name: 'calculationDate',
            label: 'Дата расчёта',
          }}
        />
      </Form>
    </Modal>
  );
};

import React, { useMemo } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  Chart,
  ChartDataset,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  Tooltip,
} from 'chart.js';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { getChartOptions } from '~/features/charts';

import { getNormalizedCowEvent, isCowInjection } from '~/entities/cowEvents';
import { getEventId } from '~/entities/events';
import { TestMilkingFields } from '~/entities/testMilkings';

import { CowLactationGraphFragment } from '../../gql/fragments/cowLactationGraph.graphql';
import { getCowChartOptions } from '../../helpers';
import {
  CowChartData,
  CowChartEventData,
  CowChartLineDataSources,
  CowChartLineValueTypes,
  CowChartSettings,
} from '../../types';
import {
  BASIC_LINE_DATASET_PROPS,
  DASHED_LINE_DATASET_PROPS,
  EVENT_BUBBLE_RADIUS_PX,
  THICK_LINE_BORDER_WIDTH_PX,
} from './constants';
import {
  CowEventsSpecialController,
  GroupSpecialController,
  TodayBarSpecialController,
} from './controllers';
import { getLineConfigByValueType } from './helpers';
import styles from './index.module.scss';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

Chart.register(GroupSpecialController);
Chart.register(CowEventsSpecialController);
Chart.register(TodayBarSpecialController);

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Lactation graphs to display on the chart
   */
  lactationGraphs: CowLactationGraphFragment[];
  /**
   * Id of the lactation with the largest number for displaying the divider
   */
  lastLactationId?: string;
  /**
   * Filter for event ids, displayed on the chart
   */
  eventIDs: string[];
  /**
   * Chart display settings from the settings modal
   */
  settings: CowChartSettings;
}

export const CowChart: React.FC<Props> = ({
  className,
  lactationGraphs,
  lastLactationId,
  eventIDs,
  settings,
}) => {
  const chartOptions = useMemo(
    () =>
      getChartOptions(
        getCowChartOptions(
          settings.testMilkFractions.includes(TestMilkingFields.somaticCells)
        )
      ),
    [settings.testMilkFractions]
  );

  const chartData = useMemo(() => {
    const makeCheckSettings =
      (settingsField: keyof CowChartSettings) =>
      (fieldToCheck: TestMilkingFields) =>
        settings[settingsField].includes(fieldToCheck);

    const checkMilk = makeCheckSettings('milkFractions');
    const checkTestMilk = makeCheckSettings('testMilkFractions');
    const checkPredicted = makeCheckSettings('approximationFractions');

    // Milk fraction settings
    const hasMilkWeight = checkMilk(TestMilkingFields.weight);
    const hasMilkFat = checkMilk(TestMilkingFields.fat);
    const hasMilkProtein = checkMilk(TestMilkingFields.protein);

    // Test milk fraction settings
    const hasTestMilkWeight = checkTestMilk(TestMilkingFields.weight);
    const hasTestMilkFat = checkTestMilk(TestMilkingFields.fat);
    const hasTestMilkProtein = checkTestMilk(TestMilkingFields.protein);
    const hasTestMilkSomatic = checkTestMilk(TestMilkingFields.somaticCells);

    // Approximation fraction settings
    const hasApproximationWeight = checkPredicted(TestMilkingFields.weight);
    const hasApproximationFat = checkPredicted(TestMilkingFields.fat);
    const hasApproximationProtein = checkPredicted(TestMilkingFields.protein);

    const datasets = lactationGraphs.flatMap(lactationGraph => {
      // Events data
      const eventsData: CowChartEventData[] = lactationGraph.events
        .filter(
          cowEvent =>
            // We don't show injections on the chart
            !isCowInjection(cowEvent.event) &&
            (!eventIDs.length ||
              eventIDs.includes(
                'event' in cowEvent.event
                  ? getEventId(cowEvent.event.event)
                  : cowEvent.event.id
              ))
        )
        .map(cowEvent => {
          const event = getNormalizedCowEvent(cowEvent.event);
          return {
            x: cowEvent.daysInMilk,
            y: 0,
            r: EVENT_BUBBLE_RADIUS_PX,
            happenedAt: cowEvent.event.happenedAt,
            backgroundColor: event.config.color,
            event,
          };
        });

      const eventsGraph: ChartDataset = {
        type: 'cowEventsSpecial',
        data: eventsData,
        backgroundColor: eventsData.map(x => x.backgroundColor),
      };

      const makeGetChartData =
        <T extends CowLactationGraphFragment[CowChartLineDataSources][number]>(
          milkData: T[]
        ) =>
        (
          yAxisFieldName: keyof T,
          shouldFilterOutFalsyYAxisValues = true,
          shouldIncludeHappenedAt = true
        ) =>
          milkData
            .map(item =>
              item[yAxisFieldName] || !shouldFilterOutFalsyYAxisValues
                ? ({
                    x: item.daysInMilk,
                    y: item[yAxisFieldName],
                    lactationNumber: lactationGraph.lactationNumber,
                    ...(shouldIncludeHappenedAt
                      ? { happenedAt: item.happenedAt }
                      : {}),
                  } as CowChartData)
                : null
            )
            .filter(Boolean);

      const getDataForMilk = makeGetChartData(lactationGraph.milk);
      const getDataForTestMilk = makeGetChartData(lactationGraph.testMilk);
      const getDataForPredictedMilk = makeGetChartData(
        lactationGraph.predictedMilk
      );

      // Milk data
      const milkWeightDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.milkWeightKilograms),
        label: 'надой',
        data: getDataForMilk('milkWeightKilograms', false),
        borderWidth: THICK_LINE_BORDER_WIDTH_PX,
        yAxisID: 'y',
      };

      const milkFatDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.fatPercent),
        label: 'жир',
        data: getDataForMilk('fatPercent'),
      };

      const milkProteinDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.proteinPercent),
        label: 'белок',
        data: getDataForMilk('proteinPercent'),
      };

      // Test milk data
      const testMilkWeightDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.milkWeightKilograms),
        label: 'контрольный надой',
        data: getDataForTestMilk('milkWeightKilograms', false),
        yAxisID: 'y',
      };

      const testMilkFatDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.fatPercent),
        label: 'контрольный жир',
        data: getDataForTestMilk('fatPercent'),
      };

      const testMilkProteinDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.proteinPercent),
        label: 'контрольный белок',
        data: getDataForTestMilk('proteinPercent'),
      };

      const testMilkSomaticCellsDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.sccThousandsPerMl),
        label: 'соматические клетки',
        yAxisID: 'rightScc',
        data: getDataForTestMilk('sccThousandsPerMl', true, false),
      };

      // Approximate fact milk data
      const isItemBeforeNow = (item: CowChartData) =>
        dayjs(item.happenedAt).isBefore(dayjs());
      const isItemSameOrAfterNow = (item: CowChartData) =>
        dayjs(item.happenedAt).isSameOrAfter(dayjs());

      const approximateMilkWeightData = getDataForPredictedMilk(
        'milkWeightKilograms',
        false
      );

      const approximateFactMilkWeightDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.milkWeightKilograms),
        label: 'расчётный надой',
        data: approximateMilkWeightData.filter(isItemBeforeNow),
        borderWidth: THICK_LINE_BORDER_WIDTH_PX,
        yAxisID: 'y',
      };

      const approximatePlanMilkWeightDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.milkWeightKilograms),
        label: 'планируемый надой',
        data: approximateMilkWeightData.filter(isItemSameOrAfterNow),
        yAxisID: 'y',
      };

      const approximateMilkFatData = getDataForPredictedMilk('fatPercent');

      const approximateFactMilkFatDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.fatPercent),
        label: 'расчётный жир',
        data: approximateMilkFatData.filter(isItemBeforeNow),
        borderWidth: THICK_LINE_BORDER_WIDTH_PX,
      };

      const approximatePlanMilkFatDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.fatPercent),
        label: 'планируемый жир',
        data: approximateMilkFatData.filter(isItemSameOrAfterNow),
      };

      const approximateMilkProteinData =
        getDataForPredictedMilk('proteinPercent');

      const approximateFactMilkProteinDataset: ChartDataset = {
        ...BASIC_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.proteinPercent),
        label: 'расчётный белок',
        data: approximateMilkProteinData.filter(isItemBeforeNow),
        borderWidth: THICK_LINE_BORDER_WIDTH_PX,
      };

      const approximatePlanMilkProteinDataset: ChartDataset = {
        ...DASHED_LINE_DATASET_PROPS,
        ...getLineConfigByValueType(CowChartLineValueTypes.proteinPercent),
        label: 'планируемый белок',
        data: approximateMilkProteinData.filter(isItemSameOrAfterNow),
      };

      // Today divider data
      const isLastLactation = lactationGraph.lactation.id === lastLactationId;

      const todayDividerDataset: ChartDataset<'todayBarSpecial'> = {
        type: 'todayBarSpecial',
        barThickness: 2,
        data: [
          {
            x: lactationGraph.daysInMilk,
            date: lactationGraph.latestDayInMilkHappenedAt,
          },
        ],
      };

      // Pen groups transition data
      const penGroupsTransitionData = lactationGraph.penGroups.map(
        cowPenGroup => ({
          to: cowPenGroup.movedToPenGroupAtDaysInMilk,
          from: cowPenGroup.movedFromPenGroupAtDaysInMilk,
          group: cowPenGroup.penGroup.name,
          id: cowPenGroup.penGroup.id,
        })
      );

      const groupTransitionDataset: ChartDataset<'groupSpecial'> = {
        type: 'groupSpecial',
        data: penGroupsTransitionData,
      };

      return [
        eventsGraph,

        // milk
        hasMilkWeight && milkWeightDataset,
        hasMilkFat && milkFatDataset,
        hasMilkProtein && milkProteinDataset,

        // test milk
        hasTestMilkWeight && testMilkWeightDataset,
        hasTestMilkFat && testMilkFatDataset,
        hasTestMilkProtein && testMilkProteinDataset,
        hasTestMilkSomatic && testMilkSomaticCellsDataset,

        // approximations
        hasApproximationWeight && approximateFactMilkWeightDataset,
        hasApproximationWeight && approximatePlanMilkWeightDataset,

        hasApproximationFat && approximateFactMilkFatDataset,
        hasApproximationFat && approximatePlanMilkFatDataset,

        hasApproximationProtein && approximateFactMilkProteinDataset,
        hasApproximationProtein && approximatePlanMilkProteinDataset,

        isLastLactation && todayDividerDataset,

        lactationGraphs.length === 1 && groupTransitionDataset,
      ].filter(Boolean);
    });

    return {
      datasets,
    };
  }, [lactationGraphs, eventIDs, lastLactationId, settings]);

  return (
    <div className={clsx(styles.root, className)}>
      <ReactChart
        {...{
          className: styles.chart,
          type: 'line',
          datasetIdKey: '1',
          options: chartOptions,
          data: chartData,
        }}
      />
    </div>
  );
};

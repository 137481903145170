import React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { useArkaNavigation } from '~/services/navigation';

import { TextLink } from '~/shared/components/TextLink';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { INTERPUNCT } from '~/shared/constants';
import { formatDate } from '~/shared/helpers/date';
import { joinJsxArray } from '~/shared/helpers/render';

import { formatCow, getCowPageUrl, isBullCow } from '~/entities/cows';
import { getInseminationInfo } from '~/entities/inseminations';

import { CowDefaultEventFragment } from '../gql/fragments/cowDefaultEvent.graphql';
import { CowEventFragment } from '../gql/fragments/cowEvent.graphql';
import {
  isCowDefaultEvent,
  isCowDisease,
  isCowInjection,
  isCowProtocol,
} from '../helpers';

interface CowEventAdditionalInfo {
  happenedAt?: string;
  isProtocolStop?: boolean;
  groupedEvents?: CowEventFragment[];
}

/**
 * Hook for reusing cow event additional info that depends on the type of the event.
 * Also we have special display variant for the cow card, if we pass additional event info.
 */
export const useGetCowEventAdditionalInfo = () => {
  const { t } = useTranslation(MaslovNamespaces.enums);
  const { getPathRelativeToCompany } = useArkaNavigation();

  return (
    cowEvent: CowEventFragment,
    additionalInfo?: CowEventAdditionalInfo
  ) =>
    match(cowEvent)
      .with(P.when(isCowDisease), matchedEvent => {
        if (!additionalInfo) return '';

        return (
          <>
            <div>{formatDate(matchedEvent.happenedAt)}</div>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              Начало болезни
            </Typography>
          </>
        );
      })
      .with(P.when(isCowProtocol), matchedEvent => {
        if (!additionalInfo) return '';

        const diseaseNameSuffix = matchedEvent.diseaseName
          ? ` для болезни ${matchedEvent.diseaseName}`
          : '';

        return (
          <>
            <div>{formatDate(additionalInfo.happenedAt)}</div>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              {additionalInfo.isProtocolStop
                ? `Конец протокола${diseaseNameSuffix}`
                : `Начало протокола${diseaseNameSuffix}`}
            </Typography>
          </>
        );
      })
      .with(P.when(isCowInjection), matchedEvent => {
        if (!matchedEvent.cowProtocol) return '';

        const injectionDayNumber =
          dayjs(matchedEvent.happenedAt).diff(
            dayjs(matchedEvent.cowProtocol.happenedAt),
            'days'
          ) + 1;

        return (
          <>
            {additionalInfo?.groupedEvents && (
              <div>
                {additionalInfo.groupedEvents
                  ?.filter(isCowInjection)
                  .map(e => e.injection.name)
                  .join(', ')}
              </div>
            )}
            <Typography
              variant={
                additionalInfo
                  ? TypographyVariants.descriptionLarge
                  : TypographyVariants.bodySmall
              }
              className={clsx(additionalInfo && 'text-muted')}
            >
              {injectionDayNumber}
              &nbsp;день&nbsp;из&nbsp;
              {matchedEvent.cowProtocol.protocol.expectedDaysOnProtocol}
            </Typography>
          </>
        );
      })
      .with(P.when(isCowDefaultEvent), matchedEvent => {
        const getCalvingInfo = (
          calving: CowDefaultEventFragment['calving']
        ) => {
          const getAnimalSex = (isBull: boolean) => (isBull ? 'Бык' : 'Тёлка');

          // We don't have a binding to actual cow entity in the calving results,
          // so we only use them to display dead animals
          const deadAnimalResults = calving?.results.filter(R.prop('isDead'));

          const totalAnimalsCount =
            (calving?.calfCows.length ?? 0) + (deadAnimalResults?.length ?? 0);

          return (
            <ol
              key={calving?.id}
              className={clsx(
                'list-inside',
                totalAnimalsCount > 1 && 'list-revert'
              )}
            >
              {calving?.calfCows.map(cow => (
                <li key={cow.id}>
                  {getAnimalSex(isBullCow(cow))} {INTERPUNCT}{' '}
                  <TextLink
                    to={getPathRelativeToCompany(getCowPageUrl(cow.id))}
                  >
                    {formatCow(cow, { prefix: '' })}
                  </TextLink>{' '}
                  {INTERPUNCT} Жив.
                </li>
              ))}
              {deadAnimalResults?.map(result => (
                <li key={result.id}>
                  {getAnimalSex(result.isBull)} {INTERPUNCT} Мёртв.
                </li>
              ))}
            </ol>
          );
        };

        const infoList = [
          !!matchedEvent.daysInPregnancy &&
            `Дней стельности ${matchedEvent.daysInPregnancy}`,
          !!matchedEvent.retirementReason &&
            `Причина выбытия: ${t(
              `${EnumStrings.cowRetirementReason}${matchedEvent.retirementReason}`
            )}`,
          !!matchedEvent.penGroupFrom &&
            `Из группы ${matchedEvent.penGroupFrom.name}`,
          !!matchedEvent.penGroupTo &&
            `В группу ${matchedEvent.penGroupTo.name}`,
          !!matchedEvent.optionalProtocol &&
            `Протокол ${matchedEvent.optionalProtocol.name}`,
          !!matchedEvent.bodyConditionScore &&
            `BCS ${matchedEvent.bodyConditionScore.bodyConditionScore}`,
          !!matchedEvent.heightMeasurement?.heightCentimeters &&
            `Рост ${matchedEvent.heightMeasurement.heightCentimeters} см`,
          !!matchedEvent.weighing?.weightKilograms &&
            `Вес ${matchedEvent.weighing.weightKilograms} кг`,
          !!matchedEvent.insemination &&
            getInseminationInfo(matchedEvent.insemination),
          matchedEvent.calving && getCalvingInfo(matchedEvent.calving),
        ];

        return joinJsxArray(infoList.filter(Boolean), <br />);
      })
      .otherwise(R.always(''));
};

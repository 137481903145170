import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorGroupFragmentDoc } from '../fragments/monitorGroup.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MonitorGroupsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type MonitorGroupsQuery = { __typename?: 'Query', monitorGroups: { __typename?: 'MonitorGroupConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'MonitorGroupEdge', cursor: string, node: { __typename: 'MonitorGroup', id: string, name: string } }> } };


export const MonitorGroupsDocument = gql`
    query monitorGroups($first: Int, $after: String, $search: String) {
  monitorGroups(first: $first, after: $after, search: $search) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...MonitorGroup
      }
    }
  }
}
    ${MonitorGroupFragmentDoc}`;

/**
 * __useMonitorGroupsQuery__
 *
 * To run a query within a React component, call `useMonitorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMonitorGroupsQuery(baseOptions?: Apollo.QueryHookOptions<MonitorGroupsQuery, MonitorGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitorGroupsQuery, MonitorGroupsQueryVariables>(MonitorGroupsDocument, options);
      }
export function useMonitorGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitorGroupsQuery, MonitorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitorGroupsQuery, MonitorGroupsQueryVariables>(MonitorGroupsDocument, options);
        }
export function useMonitorGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MonitorGroupsQuery, MonitorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MonitorGroupsQuery, MonitorGroupsQueryVariables>(MonitorGroupsDocument, options);
        }
export type MonitorGroupsQueryHookResult = ReturnType<typeof useMonitorGroupsQuery>;
export type MonitorGroupsLazyQueryHookResult = ReturnType<typeof useMonitorGroupsLazyQuery>;
export type MonitorGroupsSuspenseQueryHookResult = ReturnType<typeof useMonitorGroupsSuspenseQuery>;
export type MonitorGroupsQueryResult = Apollo.QueryResult<MonitorGroupsQuery, MonitorGroupsQueryVariables>;
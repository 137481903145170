import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowProtocolFragment = { __typename: 'CowProtocol', id: string, stoppedAt?: any | null, diseaseName: string, protocol: { __typename: 'Protocol', id: string, name: string, kind: Types.EventKindEnum } };

export const CowProtocolFragmentDoc = gql`
    fragment CowProtocol on CowProtocol {
  __typename
  id
  stoppedAt
  diseaseName
  protocol {
    __typename
    id
    name
    kind
  }
}
    `;